import { Box, Button, Container, Grid } from "@mui/material";
import { intersection } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { alertaCallback, exibeAlerta } from "../components/Alerta";
import { FormularioCadastroGeral } from "../components/FormularioCadastroGeral";
import { CaixaCinza, Titulo } from "../components/componentes";
import { CadastroGeral } from "../processo/processo";
import { ApiProcesso } from "../processo/processo.api";
import { useUsuarioLogado } from "../usuario-logado";

export function TelaCriaProcesso() {
    const usr = useUsuarioLogado()
    const navigate = useNavigate()

    const [orgaos, set_orgaos] = useState<string[]>([])
    const [aquisicaoContratacao, set_aquisicaoContratacao] = useState<string>()
    const [modalidade, set_modalidade] = useState<string>()
    const [tipoDespesa, set_tipoDespesa] = useState<string>()
    const [objeto, set_objeto] = useState<string>()
    const [justificativa, set_justificativa] = useState<string>()
    const [valorEstimado, set_valorEstimado] = useState<string>()
    const [baseAnalise, set_base_analise] = useState<string>()

    const gettersESetters = {
        orgaos, set_orgaos,
        aquisicaoContratacao, set_aquisicaoContratacao,
        modalidade, set_modalidade,
        tipoDespesa, set_tipoDespesa,
        objeto, set_objeto,
        justificativa, set_justificativa,
        valorEstimado, set_valorEstimado,
        baseAnalise, set_base_analise,
    };

    const orgaos_com_permissão = (usr?.permissoes || []).filter(p => p.permissao === 'equipe-planejamento').map(p => p.orgao)

    const criar = () => {
        if (!usr) return;

        if (intersection(orgaos_com_permissão, orgaos).length === 0) {
            exibeAlerta(`Inclua pelo menos um dos seguintes órgãos: ${orgaos_com_permissão.join(', ')}`, { severity: "error" })
            return
        }

        const cadastro: Partial<CadastroGeral> = {
            aquisicaoContratacao,
            justificativa,
            modalidade,
            objeto,
            orgaos,
            tipoDespesa,
            valorEstimado,
        }
        ApiProcesso
            .criar(usr.id, cadastro)
            .then(p => navigate('/compras/processo/' + p.id))
            .catch(alertaCallback("Falha ao criar o processo. Tente novamente mais tarde.", { severity: "error" }))
    }

    return <Container>
        <Titulo titulo="Criar Novo Projeto de Compra" />
        <CaixaCinza padding={1}>
            <Box padding={1}>
                {orgaos_com_permissão.length > 0 ?
                    <aside className="MuiCallout-root MuiCallout-info">
                        <p>Você tem permissão para criar processos para os seguintes órgãos demandantes:</p>
                        <ul>
                            {orgaos_com_permissão.map(o => <li key={o}>{o}</li>)}
                        </ul>
                        <p>Caso precise de permissão em outros órgãos contacte o suporte.</p>
                    </aside> :
                    <aside className="MuiCallout-root MuiCallout-info">
                        <p>
                            Você não tem permissão para criar processos em nenhum órgão.
                            Se você precisa acessar os processos de algum órgão contacte o suporte.
                        </p>
                    </aside>}
                <Grid container spacing={2}>
                    <FormularioCadastroGeral {...gettersESetters} />
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={criar}
                        >
                            Criar Processo
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </CaixaCinza>
    </Container>
}
