import { zodResolver } from '@hookform/resolvers/zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { exibeAlerta } from "../components/Alerta";
import { CaixaCinza } from "../components/componentes";
import { useUsuarioLogado } from "../usuario-logado";

const ChangePasswordSchema = z
    .object({
        oldPassword: z.string().min(1, { message: '*Informe a senha antiga.' }),
        newPassword: z
            .string()
            .min(1, { message: '*Informe a senha' })
            .min(8, { message: '*No mínimo 8 caracteres.' })
            .regex(new RegExp(/^(?=.*[A-Z])/), {
                message: '*Deve conter pelo menos uma letra maiúscula.'
            })
            .regex(new RegExp(/^(?=.*[a-z])/), {
                message: '*Deve conter pelo menos uma letra minúscula.'
            })
            .regex(new RegExp(/^(?=.*\d)/), {
                message: '*Deve conter pelo menos um número.'
            }),
        confirmPassword: z.string().min(1, { message: '*Confirme a nova senha.' })
    })
    .refine(data => data.newPassword === data.confirmPassword, {
        message: 'As senhas não conferem.',
        path: ['confirmPassword']
    });

type ChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>;

const TelaAlterarSenha = () => {
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm<ChangePasswordSchemaType>({
        resolver: zodResolver(ChangePasswordSchema)
    });

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handleShowOldClick = () => setShowOldPassword(!showOldPassword);
    const handleShowNewClick = () => setShowNewPassword(!showNewPassword);
    const handleShowConfirmClick = () =>
        setShowConfirmPassword(!showConfirmPassword);

    const usr = useUsuarioLogado()
    const cpf = usr?.cpf || '';

    const onSubmit: SubmitHandler<ChangePasswordSchemaType> = async data => {
        await new Promise(async () => {
            try {
                const usuario = await axios({
                    method: 'GET',
                    url: (`http://ordens.bombeiros.ms.gov.br/api/usuario/${cpf}`)
                })

                if (usuario) {
                    await axios({
                        method: 'POST',
                        url: `http://ordens.bombeiros.ms.gov.br/api/password-change`,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {
                            user: `${usuario.data[0].login.split('@')[0]}`,
                            oldPassword: data.oldPassword,
                            newPassword: data.newPassword
                        }
                    });
                }
                exibeAlerta('Senha alterada com sucesso!', { severity: 'success' })
            } catch (error) {
                exibeAlerta('Erro ao alterar senha', { severity: 'error' })
            }
            reset({ oldPassword: '', newPassword: '', confirmPassword: '' });
        });
    };

    return (<>
        <Container maxWidth="xs" sx={{ mt: 12 }}>
            <CaixaCinza>
                <Box sx={{ textAlign: "center", padding: 1, marginBottom: 2 }}>
                    <Typography variant="h6">
                        Alterar senha
                    </Typography>
                </Box>
                <Grid container direction="column" spacing={3} sx={{ textAlign: "center" }}>
                    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                        <Grid item paddingTop={4} paddingBottom={4}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="cpf-mascarado" variant="outlined">CPF</InputLabel>
                                <OutlinedInput
                                    label="CPF"
                                    id="cpf-mascarado"
                                    value={usr?.cpf}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item paddingBottom={4}>
                            <FormControl variant="outlined">
                                <InputLabel variant="outlined">Senha Atual</InputLabel>
                                <OutlinedInput
                                    label="Senha atual"
                                    type={showOldPassword ? "text" : "password"}
                                    {...register('oldPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleShowOldClick()}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item paddingBottom={4}>
                            <FormControl variant="outlined">
                                <InputLabel variant="outlined">Nova senha</InputLabel>
                                <OutlinedInput
                                    label="Nova senha"
                                    type={showNewPassword ? "text" : "password"}
                                    {...register('newPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleShowNewClick()}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {errors.newPassword && (
                                <Typography color="error" fontSize={12}>
                                    {errors.newPassword.message}
                                </Typography  >
                            )}


                        </Grid>
                        <Grid item paddingBottom={4}>
                            <FormControl variant="outlined">
                                <InputLabel variant="outlined">Confirme nova senha</InputLabel>
                                <OutlinedInput
                                    label="Confirme nova senha"
                                    type={showConfirmPassword ? "text" : "password"}
                                    {...register('confirmPassword')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleShowConfirmClick()}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {errors.confirmPassword && (
                                <Typography color="error" fontSize={12}>
                                    {errors.confirmPassword.message}
                                </Typography  >
                            )}
                        </Grid>
                        <Grid item>
                            <Button type="submit" variant="contained" size="large">
                                Alterar
                            </Button>
                        </Grid>
                    </form>
                </Grid>
                <br />
                {/* <Box padding={1}>
                            <p>
                                Rodapé.
                            </p>
                        </Box> */}
            </CaixaCinza>
        </Container>
    </>)
}

export default TelaAlterarSenha;
