export const CIDADES = [
    "Água Clara",
    "Alcinópolis",
    "Amambai",
    "Anastácio",
    "Anaurilândia",
    "Angélica",
    "Antônio João",
    "Aquidauana",
    "Aral Moreira",
    "Bandeirantes",
    "Bataguassu",
    "Batayporã",
    "Bela Vista",
    "Bodoquena",
    "Bonito",
    "Brasilândia",
    "Caarapó",
    "Camapuã",
    "Campo Grande",
    "Caracol",
    "Cassilândia",
    "Corguinho",
    "Coronel Sapucaia",
    "Corumbá",
    "Costa Rica",
    "Coxim",
    "Deodápolis",
    "Douradina",
    "Dourados",
    "Eldorado",
    "Figueirão",
    "Iguatemi",
    "Inocência",
    "Itaporã",
    "Itaquiraí",
    "Ivinhema",
    "Japorã",
    "Jaraguari",
    "Jardim",
    "Jateí",
    "Juti",
    "Ladário",
    "Laguna Carapã",
    "Maracaju",
    "Miranda",
    "Mundo Novo",
    "Naviraí",
    "Nioaque",
    "Nova Andradina",
    "Paranaíba",
    "Paranhos",
    "Pedro Gomes",
    "Ponta Porã",
    "Porto Murtinho",
    "Rio Brilhante",
    "Rio Negro",
    "Rochedo",
    "Selvíria",
    "Sete Quedas",
    "Sidrolândia",
    "Sonora",
    "Tacuru",
    "Taquarussu",
    "Terenos",
    "Três Lagoas",
    "Vicentina",
    "Aparecida do Taboado",
    "Chapadão do Sul",
    "Dois Irmãos do Buriti",
    "Fátima do Sul",
    "Glória de Dourados",
    "Guia Lopes da Laguna",
    "Nova Alvorada do Sul",
    "Novo Horizonte do Sul",
    "Paraíso das Águas",
    "Ribas do Rio Pardo",
    "Rio Verde de Mato Grosso",
    "Santa Rita do Pardo",
    "São Gabriel do Oeste"
]