import { Badge, Box, Grid, Tab, Tabs } from "@mui/material";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { CaixaCinza } from "../../components/componentes";
import { ApiAnalise, ItemNotificacaoAnalise } from "../../processo/analise";
import { ApiItemEtpTr, BaseItem } from "../../processo/atributo-documento";
import { Processo, Projeto } from '../../processo/processo';
import { useUsuarioLogado, UsuarioLogado } from "../../usuario-logado";
import { ExibeDocsObrigatorios } from "./ExibeDocumentos";
import { ExibeEdicaoETP } from "./ExibeETP";
import { Notificacao } from "./ExibeNotificacoes";
import { ExibeEdicaoTR } from "./ExibeTR";
import { PainelAcoes } from "./PainelAcoes";
import { ExibeCadastroGeral } from "./PecaDadosGerais";

export function ExibeProjeto(props: {
    processo: Processo,
    projeto: Projeto,
    update_projeto: () => void,
    aguardandoAnalise: boolean,
    motivoNaoPodeEditar?: string,
    analistas: number[],
}) {
    const { processo, projeto, update_projeto, motivoNaoPodeEditar, analistas, aguardandoAnalise } = props;
    const [local, set_local] = useState('cadastro')
    const [especificacao_local, set_especificacao_local] = useState('')
    const [items_analise, set_items_analise] = useState<ItemNotificacaoAnalise[]>([])
    const usr = useUsuarioLogado() as UsuarioLogado

    const exibe_etp = ![
        // Modalidades que não possuem ETP
        "DISPENSA DE LICITAÇÃO — Art. 24, II— VALOR — ATÉ R$ 17.600,00",
        "DISPENSA DE LICITAÇÃO — Art. 24, IV — EMERGENCIAL",
        "DISPENSA DE LICITAÇÃO — Art. 24, VIII — BENS OU SERVIÇOS DA ADM PÚBLICA",
    ].includes(projeto.cadastro.modalidade)

    const atualiza_items_de_analise = async () => {
        const [items, bases] = await Promise.all([
            ApiAnalise.get_items_do_projeto(projeto.id),
            ApiItemEtpTr.items_base()
        ])
        const mapa_bases = {} as Record<string, BaseItem>
        for (let base of bases) {
            const doc = base.documento === 'ETP' ? 'etp' : 'tr'
            mapa_bases[doc + base.titulo] = base
            //console.log(base.documento+base.titulo)
        }

        function compara_items(a: ItemNotificacaoAnalise, b: ItemNotificacaoAnalise) {
            const base_a = mapa_bases[a.local + a.especificacao_local]?.ordem || ''
            const base_b = mapa_bases[b.local + b.especificacao_local]?.ordem || 'z'
            //console.log(base_a, base_b)
            return base_a.localeCompare(base_b)
        }

        set_items_analise(items.sort(compara_items))
    }
    useEffect(() => { atualiza_items_de_analise().then() }, [])

    const exibe_notificacao = !!motivoNaoPodeEditar || (items_analise.length > 0)
    const items_agrupados = groupBy(items_analise, 'local')

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // Objeto com os parâmetros comuns aos componentes:
    //     ExibeCadastroGeral
    //     ExibeEdicaoETP
    //     ExibeEdicaoTR
    //     ExibeDocsObrigatorios
    //     ExibeDocsComplementares
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const parametros_exibicao = {
        processo,
        projeto,
        motivoNaoPodeEditar,
        especificacao_local,
        set_especificacao_local,
        items_analise: items_agrupados[local]
    }

    // Esses são todos os valores de 'especificacao_local' possíveis para o local atual
    let espec_locais: undefined | string[] = undefined
    if (local === 'cadastro') {
        espec_locais = ['Órgãos Demandantes', 'Aquisição ou Contratação', 'Modalidade Licitatória', 'Tipo de Despesa', 'Documento de Origem', 'Objeto', 'Justificativa', 'Equipe de Planejamento', 'Valor Estimado']
    }

    return (<Box display="flex" flexDirection="column" flexGrow={1}>
        <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
                <PainelAcoes
                    processo={processo}
                    projeto={projeto}
                    update_projeto={update_projeto}
                    emEdicao={!motivoNaoPodeEditar}
                    aguardandoAnalise={aguardandoAnalise}
                    analistas={analistas}
                    exibe_etp={exibe_etp}
                    exibe_notificacao={exibe_notificacao}
                />
            </Grid>
            <Grid item xs={exibe_notificacao ? 6 : 10}>
                <CaixaCinza>
                    <Box display="flex"
                        flexDirection="row"
                        sx={{ borderBottom: 1, borderColor: 'lightgray' }}>
                        <Tabs
                            variant="scrollable"
                            value={local}
                            onChange={(_ev, valor) => set_local(valor)}
                            scrollButtons="auto"
                        >
                            <Tab label={
                                <Badge badgeContent={items_agrupados["cadastro"]?.filter((item) => !item.resposta).length} color="warning">Cadastro Geral</Badge>
                            }
                                value="cadastro"
                            />
                            {exibe_etp && <Tab label={
                                <Badge badgeContent={items_agrupados["etp"]?.filter((item) => !item.resposta).length} color="warning">ETP</Badge>
                            }
                                value="etp" />}
                            <Tab label={
                                <Badge badgeContent={items_agrupados["tr"]?.filter((item) => !item.resposta).length} color="warning">TR</Badge>
                            }
                                value="tr" />
                            <Tab label={
                                <Badge badgeContent={items_agrupados["doc-obr"]?.filter((item) => !item.resposta).length} color="warning">Docs Obrigatórios</Badge>
                            }
                                value="doc-obr" />
                        </Tabs>
                    </Box>
                    <Box>
                        {local === 'cadastro' && <ExibeCadastroGeral {...{ ...parametros_exibicao, processo, update_projeto }} />}
                        {local === 'etp' && <ExibeEdicaoETP {...parametros_exibicao} />}
                        {local === 'tr' && <ExibeEdicaoTR {...parametros_exibicao} />}
                        {local === 'doc-obr' && <ExibeDocsObrigatorios {...parametros_exibicao} />}
                    </Box>
                </CaixaCinza>
            </Grid>
            {exibe_notificacao &&
                <Grid key={projeto.id} item xs={4}>
                    <div style={{ position: 'sticky', top: 10}}>
                        <Notificacao
                            items={items_agrupados[local] || []}
                            emEdicao={!motivoNaoPodeEditar}
                            analistas={analistas}
                            usr={usr}
                            projeto={projeto}
                            local={local}
                            especificacao_local={especificacao_local}
                            set_especificacao_local={set_especificacao_local}
                            espec_locais={espec_locais}
                            atualiza_items_de_analise={atualiza_items_de_analise}
                        />
                    </div>
                </Grid>
            }
        </Grid>
    </Box>)
}
