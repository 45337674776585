import axios from "axios"

export type BaseItemDespachoConformidade = {
    local: string,
    num_item: string,
    conteudo: string,
}

export type ItemDespachoConformidade = {
    processo: number,
    local: string,
    num_item: string,
    conteudo: string,
    valor?: 'S' | 'N' | 'NA'
}

export module ApiItemDespachoConformidade {
    export async function bases(): Promise<BaseItemDespachoConformidade[]> {
        return axios.get<BaseItemDespachoConformidade[]>('/api/items-despacho-conformidade/bases')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    /// Função para buscar todos os itens de um processo
    export async function items_do_processo(id: number): Promise<ItemDespachoConformidade[]> {
        return axios.get<ItemDespachoConformidade[]>('/api/items-despacho-conformidade/do-processo/' + id)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    export async function atualiza(item: Omit<ItemDespachoConformidade, 'conteudo'>): Promise<ItemDespachoConformidade> {
        return axios.post<ItemDespachoConformidade>('/api/items-despacho-conformidade', item)
            .then((response) => response.data)
    }
}
