import { format } from "date-fns"

export function pick(attr: string) {
    return (obj: any) => obj[attr]
}

export function normalizar(s: string) {
    return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
}

export const CORES_DISTINTAS = [
    'red',
    'blue',
    'green',
    'orange',
    'purple',
    'brown',
    'pink',
    'teal',
    'maroon',
    'cyan',
    'magenta',
    'navy',
    'peach',
    'lime',
    'olive',
    'yellow',
    'lavender',
    'mauve',
    'umber',
    'murk',
    'black',
    'gray',
    'white',
]

export function isoToDataPt(iso: string) {
    return format(new Date(iso), 'dd/MM/yyyy')
}
