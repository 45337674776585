import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { CaixaCinza, Titulo } from "../components/componentes";

export function TelaInicial() {
    return (<>
        <Container>
            <Titulo titulo="Sistema de Análise de Documentos de Planejamento" />
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Link to="/compras/processos">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone1.png" alt="Processos"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>PROCESSOS</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Listar Processos
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/compras/criar-processo">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone2.png" alt="Criar Processo"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>INICIAR PROCESSO</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Novo processo de compra
                                </p>
                            </div>
                        </div>
                    </Link>
                    <a href="https://sistemas.bombeiros.ms.gov.br/suporte/solicitacao/solicitacao-criar/263/131/true/sejusp" target="_blank">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone3.png" alt="Suporte"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>SUPORTE TÉCNICO</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Relatar um problema
                                </p>
                            </div>
                        </div>
                    </a>
                    <Link to="/compras/legislacao">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone4.png" alt="Legislação"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>LEGISLAÇÕES</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Normativas
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/compras/tutoriais#Visão Geral.mp4">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone5.png" alt="Tutoriais"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>TUTORIAIS</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Vídeos explicativos do sistema
                                </p>
                            </div>
                        </div>
                    </Link>
                    {false && <Link to="/compras/material-de-apoio">
                        <div className="item-menu-inicial">
                            <img src="/images/banners-menu/icone-material-de-apoio.png"
                                height="50px" style={{ margin: 6 }} />
                            <div>
                                <div style={{ fontSize: "16px", marginBottom: 0 }}>Material de Apoio</div>
                                <p style={{ fontSize: "15px", marginTop: 0 }}>
                                    Manuais, normas e outros materiais de apoio
                                </p>
                            </div>
                        </div>
                    </Link>}
                </Grid>
                <Grid item xs={7} flexGrow={1} display="flex">
                    <CaixaCinza sx={{ flexGrow: 1 }}>
                        <Container>
                            <Titulo titulo="Informativos" />
                            {false && <>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>20/05/2022</span>: Lançamento do sistema de análise de documentos de planejamento
                                </p>
                                <p>
                                    <span style={{ fontWeight: "bold" }}>12/05/2022</span>: Seu projeto nº 218 foi analisado
                                </p>
                            </>}
                        </Container>
                    </CaixaCinza>
                </Grid>
            </Grid>
        </Container>
    </>)
}
