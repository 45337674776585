import { Add, Delete, Person } from "@mui/icons-material";
import { Button, Container, Dialog, DialogTitle, IconButton, Link, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { chunk, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { blockUi } from "../../components/Alerta";
import { AvatarUsr } from "../../components/AvatarUsr";
import { Titulo } from "../../components/componentes";
import { ApiPermissaoUsrNaAplicacao, PermissaoUsrNaAplicacao } from "../../entidades/permissao";
import { ApiOrgao } from "../../entidades/sejusp/orgao";
import { ApiPrivilegio } from "../../entidades/sejusp/privilegio";
import { ApiUsuario, Usuario } from "../../entidades/usuario";
import { normalizar } from "../../ferramentas";
import { setUsuarioLogado, useUsuarioLogado } from "../../usuario-logado";

function BotaoAdicionaUsuario(props: { callbackAtualizarUsuarios: () => void }) {
    const { callbackAtualizarUsuarios } = props;
    const [aberto, setAberto] = useState(false);

    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [login, setLogin] = useState("");
    const [dominio, setDominio] = useState("SEJUSP");

    const callbackAdicionar = async () => {
        await ApiUsuario.cadastrar({
            cpf,
            nome,
            email,
            login,
            dominio,
        })
        callbackAtualizarUsuarios()
        setAberto(false)
    }

    return <>
        <IconButton onClick={() => setAberto(true)}>
            <Add color="primary" />
        </IconButton>
        <Dialog onClose={() => setAberto(false)} open={aberto}>
            <DialogTitle>Adicionar Usuário</DialogTitle>
            <Container>
                <Box sx={{ flexDirection: 'column', display: 'flex', '* > div': { marginBottom: 1 }, paddingBottom: 2 }}>
                    <TextField label="CPF" size="small" value={cpf} onChange={ev => setCpf(ev.target.value)} />
                    <TextField label="Nome" size="small" value={nome} onChange={ev => setNome(ev.target.value)} />
                    <TextField label="Email" size="small" value={email} onChange={ev => setEmail(ev.target.value)} />
                    <TextField label="Login" size="small" value={login} onChange={ev => setLogin(ev.target.value)} />
                    <TextField label="Domínio" size="small" value={dominio} onChange={ev => setDominio(ev.target.value)} />
                    <Button
                        variant="outlined"
                        onClick={() => blockUi(callbackAdicionar())}
                    >
                        Adicionar
                    </Button>
                </Box>
            </Container>
        </Dialog>
    </>
}

function LinhaUsuario(props: { usr: Usuario }) {
    const { usr } = props
    const [permissoes, set_permissoes] = useState<PermissaoUsrNaAplicacao[]>([])
    const [modal_aberto, set_modal_aberto] = useState(false)
    const [orgaos, set_orgaos] = useState<string[]>([])
    const [privilegios, set_privilegios] = useState<string[]>([])

    const [orgao, set_orgao] = useState<string>()
    const [privilegio, set_privilegio] = useState<string>()

    const update = () => {
        ApiPermissaoUsrNaAplicacao
            .get_do_usuario(usr.cpf)
            .then(set_permissoes)

        if (modal_aberto) {
            ApiOrgao.siglas().then(set_orgaos)
            ApiPrivilegio.nomes().then(set_privilegios)
        }
    }
    useEffect(update, [modal_aberto])

    const add_permissao = () => {
        if (privilegio && orgao) {
            blockUi(
                ApiPermissaoUsrNaAplicacao
                    .adicionar(usr.cpf, orgao, privilegio)
                    .then(set_permissoes)
            )
        }
    }

    const del_permissao = (permissao: PermissaoUsrNaAplicacao) => {
        blockUi(
            ApiPermissaoUsrNaAplicacao
                .remover(permissao)
                .then(set_permissoes)
        )
    }

    return <TableRow>
        <TableCell>
            <AvatarUsr usr={usr} />
        </TableCell>
        <TableCell>
            {usr.cpf}
        </TableCell>
        <TableCell>
            {usr.nome}
        </TableCell>
        <TableCell>
            <ul>
                {permissoes.map((p, i) => <li key={i}>{p.permissao} - {p.orgao}</li>)}
                <li><Link component="button" onClick={() => set_modal_aberto(true)}>Adicionar permissão</Link></li>
            </ul>
            <Dialog onClose={() => set_modal_aberto(false)} open={modal_aberto}>
                <DialogTitle>{usr.nome}</DialogTitle>
                <Table size="small">
                    <TableBody>
                        {permissoes.map((p, i) => <TableRow key={i}>
                            <TableCell>{p.permissao}</TableCell>
                            <TableCell>{p.orgao}</TableCell>
                            <TableCell padding="checkbox">
                                <IconButton
                                    color="error"
                                    onClick={() => del_permissao(p)}
                                >
                                    <Delete />
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                        <TableRow>
                            <TableCell>
                                <Select
                                    variant="standard"
                                    size="small"
                                    value={privilegio}
                                    onChange={ev => set_privilegio(ev.target.value)}
                                    fullWidth
                                >
                                    {privilegios.map(o => <MenuItem value={o}>{o}</MenuItem>)}
                                </Select>
                            </TableCell>
                            <TableCell>
                                <Select
                                    variant="standard"
                                    size="small"
                                    value={orgao}
                                    onChange={ev => set_orgao(ev.target.value)}
                                    fullWidth
                                >
                                    {orgaos.map(o => <MenuItem value={o}>{o}</MenuItem>)}
                                </Select>
                            </TableCell>
                            <TableCell padding="checkbox">
                                <IconButton
                                    color="primary"
                                    onClick={add_permissao}
                                >
                                    <Add />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Dialog>
        </TableCell>
        <TableCell>
            <IconButton
                title="Impersonar usuário"
                onClick={() => setUsuarioLogado(usr)}
            >
                <Person color="primary" />
            </IconButton>
        </TableCell>
    </TableRow>
}

function sortById<T extends { id: number }>(usrs: T[]): T[] {
    return sortBy(usrs, 'id')
}

export function TelaAdminUsuario() {
    const usuarioLogado = useUsuarioLogado()
    const navigate = useNavigate()
    const USRS_POR_PAGINA = 10

    const [usrs, set_usrs] = useState<Usuario[]>([])
    const [num_pagina_estado, set_num_pagina] = useState(0)
    const [filtro_nome, set_filtro_nome] = useState('')

    const paginas = chunk(usrs.filter(usr => normalizar(usr.nome).includes(filtro_nome)), USRS_POR_PAGINA)
    const num_pagina = Math.min(paginas.length - 1, num_pagina_estado)
    const pagina = paginas[num_pagina] || []

    const update = () => {
        blockUi(ApiUsuario.getAll().then(sortById).then(set_usrs))
    }
    useEffect(update, [])

    if (usuarioLogado && !usuarioLogado.permissoes.map(p => p.permissao).includes('admin')) {
        navigate('/');
    }

    return <Container>
        <Titulo
            titulo="Administração de Usuários"
            itemApos={<BotaoAdicionaUsuario callbackAtualizarUsuarios={update} />}
        />
        <Paper>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell sx={{ padding: 0 }}>
                            <TextField
                                label="Nome"
                                size="small"
                                fullWidth
                                value={filtro_nome}
                                onChange={ev => set_filtro_nome(normalizar(ev.target.value))} />
                        </TableCell>
                        <TableCell>Permissões</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pagina.map(usr => <LinhaUsuario key={usr.id} usr={usr} />)}
                </TableBody>
            </Table>

            <Box margin={1} flexGrow={1} alignSelf="center">
                <Pagination
                    count={paginas.length}
                    page={num_pagina + 1}
                    onChange={(_ev, p) => set_num_pagina(p - 1)}
                    color="primary"
                    shape="rounded"
                />
            </Box>
        </Paper>
    </Container>
}
