import { ArrowDownward, QuestionMark } from "@mui/icons-material"
import { Button, Checkbox, FormControl, Grid, Icon, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material"
import axios from "axios"
import { forwardRef, useEffect, useState } from "react"
import NumberFormat from "react-number-format"
import { ORGAOS_SOLICITANTES } from "../processo/processo"
import { exibeAlerta } from "./Alerta"

const MascaraNumero = forwardRef<HTMLElement, any>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
      />
    );
  },
)

/**
 * @description
 * Modelo de árvore de decisão para seleção de modelo de TR.
 * Critérios:
 * - Base de análise
 * - Aquisição/contratação
 * - Modalidade
 * 
 */
export type TIPO_ARVORE_DE_DECISAO_MODELO_TR = Record<string, Record<string, Record<string, string>>>;

export function FormularioCadastroGeral(props: {
  orgaos: string[],
  set_orgaos: (vs: string[]) => void,
  aquisicaoContratacao: string | undefined,
  set_aquisicaoContratacao: (v: string | undefined) => void,
  modalidade: string | undefined,
  set_modalidade: (v: string | undefined) => void,
  tipoDespesa: string | undefined,
  set_tipoDespesa: (v: string | undefined) => void,
  objeto: string | undefined,
  set_objeto: (v: string | undefined) => void,
  justificativa: string | undefined,
  set_justificativa: (v: string | undefined) => void,
  valorEstimado: string | undefined,
  set_valorEstimado: (v: string | undefined) => void,
  baseAnalise: string | undefined,
  set_base_analise: (v: string | undefined) => void,
}) {
  const {
    orgaos,
    set_orgaos,
    aquisicaoContratacao,
    set_aquisicaoContratacao,
    modalidade,
    set_modalidade,
    tipoDespesa,
    set_tipoDespesa,
    objeto,
    set_objeto,
    justificativa,
    set_justificativa,
    valorEstimado,
    set_valorEstimado,
    baseAnalise,
    set_base_analise,
  } = props;

  const [arvoreDecisao, setArvoreDecisao] = useState<TIPO_ARVORE_DE_DECISAO_MODELO_TR>({})
  useEffect(() => {
    axios.get<TIPO_ARVORE_DE_DECISAO_MODELO_TR>('/api/items-do-projeto/arvore-de-decisao-modelo-tr')
      .then(res => setArvoreDecisao(res.data))
      .catch(err => {
        console.error(err)
        exibeAlerta('Erro ao carregar minutas de TR', {
          severity: 'error',
        })
      })
  }, []);
  const opcoesBaseAnalise = Object.keys(arvoreDecisao);
  const opcoesAquisicaoContratacao = baseAnalise ? Object.keys(arvoreDecisao[baseAnalise] || {}) : [];
  const opcoesModalidade = baseAnalise && arvoreDecisao[baseAnalise]
    && aquisicaoContratacao && arvoreDecisao[baseAnalise][aquisicaoContratacao]
    ? Object.keys(arvoreDecisao[baseAnalise][aquisicaoContratacao] || {})
    : [];
  const modeloTr = baseAnalise && arvoreDecisao[baseAnalise]
    && aquisicaoContratacao && arvoreDecisao[baseAnalise][aquisicaoContratacao]
    && modalidade && arvoreDecisao[baseAnalise][aquisicaoContratacao][modalidade]

  function handle_change_orgaos_demandantes(ev: SelectChangeEvent) {
    const {
      target: { value },
    } = ev;
    const orgaos = typeof value === 'string' ? value.split(',') : value
    set_orgaos(orgaos);
  }

  return <>
    <Grid container item xs={6} spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Órgãos Demandantes</InputLabel>
          <Select
            multiple
            value={orgaos as any}
            onChange={handle_change_orgaos_demandantes}
            input={<OutlinedInput label="Órgãos Demandantes" />}
            renderValue={(selected) => (selected as any).join(', ')}
          >
            {ORGAOS_SOLICITANTES.map((orgao) => (
              <MenuItem key={orgao} value={orgao}>
                <Checkbox checked={orgaos.indexOf(orgao) > -1} />
                <ListItemText primary={orgao} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Tipo de Despesa</InputLabel>
          <Select
            label="Tipo de Despesa"
            value={tipoDespesa}
            onChange={ev => set_tipoDespesa(ev.target.value)}
          >
            <MenuItem value="Custeio">Custeio</MenuItem>
            <MenuItem value="Investimento">Investimento</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Valor Estimado</InputLabel>
          <OutlinedInput
            label="Valor Estimado"
            value={valorEstimado}
            onChange={ev => set_valorEstimado(ev.target.value)}
            inputComponent={MascaraNumero}
            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container item xs={6} spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Base da Análise</InputLabel>
          <Select
            label="Base da Análise"
            value={baseAnalise}
            onChange={ev => {
              set_base_analise(ev.target.value)
              set_aquisicaoContratacao(undefined)
              set_modalidade(undefined)
            }}
          >
            {opcoesBaseAnalise.map(ba => <MenuItem key={ba} value={ba}>{ba}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Aquisição / Contratação</InputLabel>
          <Select
            label="Aquisição / Contratação"
            value={aquisicaoContratacao}
            onChange={ev => {
              set_aquisicaoContratacao(ev.target.value)
              set_modalidade(undefined)
            }}
          >
            {opcoesAquisicaoContratacao.map(ac => <MenuItem key={ac} value={ac}>{ac}</MenuItem>)}
            {opcoesAquisicaoContratacao.length === 0 && <MenuItem disabled>Selecione a base de análise</MenuItem>}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Modalidade Licitatória</InputLabel>
          <Select
            label="Modalidade Licitatória"
            value={modalidade}
            onChange={ev => set_modalidade(ev.target.value)}
          >
            {opcoesModalidade.map(m => <MenuItem key={m} value={m}>{m}</MenuItem>)}
            {opcoesModalidade.length === 0 && <MenuItem disabled>Selecione a base de análise e aquisição/contratação</MenuItem>}
          </Select>
        </FormControl>
      </Grid>
      {modeloTr?.endsWith('.docx') && <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          LinkComponent='a'
          href={"/api/minuta/" + modeloTr}
          title={modeloTr}
          endIcon={<ArrowDownward />}
        >
          Baixar Minuta do Termo de Referência
        </Button>
      </Grid>}
    </Grid>
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <TextField fullWidth label="Objeto"
          value={objeto}
          onChange={ev => set_objeto(ev.target.value)}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      <TextField fullWidth label="Justificativa"
        value={justificativa}
        onChange={ev => set_justificativa(ev.target.value)}
        multiline
        rows={4}
      />
    </Grid>
  </>
}
