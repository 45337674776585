import axios from "axios";

export interface ItemNotificacaoAnalise {
    id: number,
    projeto: number,
    adicionado_no_projeto: number,
    local: 'etp' | 'tr' | 'cadastro',
    especificacao_local: string,
    titulo: string,
    conteudo: string,
    autor: number,
    resposta?: string,
    respondido_por?: number,
    resposta_do_item?: number, // caso esse item tenha sido copiado de uma análise anterior, esse atributo se refere ao id do item copiado
}

export module ApiAnalise {

    export async function get(id: number): Promise<ItemNotificacaoAnalise> {
        return axios.get<ItemNotificacaoAnalise>(`/api/analise/${id}`).then(r => r.data)
    }

    export async function get_items_do_projeto(projeto: number): Promise<ItemNotificacaoAnalise[]> {
        return axios.get<ItemNotificacaoAnalise[]>(`/api/analise/do-projeto/${projeto}`).then(r => r.data)
    }

    export async function get_items_do_processo(id_processo?: number | string): Promise<Record<number, ItemNotificacaoAnalise[]>> {
        if (!id_processo) return {}
        return axios
            .get<Record<number, ItemNotificacaoAnalise[]>>(`/api/analise/do-processo/${id_processo}`)
            .then((response) => response.data)
    }

    export async function novo(projeto: number, local: string, especificacao_local: string, titulo: string, conteudo: string, autor: number): Promise<ItemNotificacaoAnalise> {
        const body = { projeto, local, especificacao_local, titulo, conteudo, autor }
        return axios.post("/api/analise/novo", body).then(r => r.data)
    }

    export async function salvar(item: ItemNotificacaoAnalise): Promise<ItemNotificacaoAnalise> {
        return axios.post<ItemNotificacaoAnalise>(`/api/analise/salvar`, item).then(r => r.data)
    }

    export async function responder(itemId: number, conteudo: string, autor: number): Promise<ItemNotificacaoAnalise> {
        return axios.post(`/api/responder/${itemId}`, { conteudo, autor }).then(r => r.data)
    }

    export async function excluir(itemId: number): Promise<void> {
        return axios.delete(`/api/analise/item/${itemId}`).then(r => r.data)
    }
}
