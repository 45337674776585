import { Box, Button, Container, FormControl, Grid, InputLabel, Link, OutlinedInput, TextField, Typography } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import { IMaskInput } from 'react-imask';
import { alertaCallback } from "../components/Alerta";
import { CaixaCinza } from "../components/componentes";
import { ApiUsuario } from "../entidades/usuario";
import { setUsuarioLogado, useUsuarioLogado, UsuarioLogado } from "../usuario-logado";

export function logar(cpf: string, senha: string) {
    axios.post<string>('/api/login', { cpf, senha })
        .then((response) => {
            const token = response.data

            ApiUsuario.getByCPF(cpf).then(usr => {
                setUsuarioLogado(usr as UsuarioLogado, token)
            })
        })
        .catch(alertaCallback("Falha no login.", { severity: 'error' }))
}

const MascaraCPF = forwardRef<HTMLElement, any>(
    function MascaraCPF(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { value } })}
                overwrite
            />
        );
    },
);

function TelaCadastro(props: { fechaCadastro: () => void }) {
    const { fechaCadastro } = props
    const [cpf, setCpf] = useState("")
    const [login, setLogin] = useState("")
    const [dominio, setDominio] = useState('')
    const [senha, setSenha] = useState('')

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')

    const cadastrar = () => ApiUsuario.cadastrar({
        cpf,
        login,
        dominio,
        nome,
        email,
    })

    return <>
        <Container maxWidth="sm" className="App" sx={{ mt: 12 }}>
            <CaixaCinza sx={{ padding: 2 }}>
                <Box sx={{ textAlign: "center", padding: 1 }}>
                    <Typography variant="h6">
                        Intranet SEJUSP
                    </Typography>
                </Box>

                <Grid container direction="row" spacing={2}>
                    <Grid item md={6} container direction="column" spacing={1}>
                        <Grid item>
                            <TextField fullWidth label="Login de Rede" value={login} onChange={(ev) => setLogin(ev.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label="Domínio de Rede" placeholder="Ex.: SEJUSP, SEGOV, SEFAZ" value={dominio} onChange={(ev) => setDominio(ev.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label="Senha de Rede" value={senha} onChange={(ev) => setSenha(ev.target.value)} type="password" />
                        </Grid>
                    </Grid>
                    <Grid item md={6} container direction="column" spacing={1}>
                        <Grid item>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="cpf-mascarado" variant="outlined">CPF</InputLabel>
                                <OutlinedInput id="cpf-mascarado" value={cpf} onChange={(ev) => setCpf(ev.target.value)} inputComponent={MascaraCPF as any} label="CPF" />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label="Nome Completo" value={nome} onChange={(ev) => setNome(ev.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label="E-mail" value={email} onChange={(ev) => setEmail(ev.target.value)} />
                        </Grid>
                    </Grid>
                    <Grid item md={12} spacing={1}>
                        <p>Já possui cadastro? Clique <Link href="#" onClick={fechaCadastro}>aqui</Link>.</p>
                        <p>Você é Bombeiro Militar? Faça seu cadastro <a href="https://sistemas.bombeiros.ms.gov.br/intranet/cadastro-usuario-cbmms">aqui</a>.</p>
                    </Grid>
                </Grid>
                <Button variant="outlined" onClick={cadastrar}>
                    Cadastrar
                </Button>
            </CaixaCinza>
        </Container>
    </>
}

export function TelaLogin(props: { children: any }) {
    const [cpf, setCPF] = useState('');
    const [senha, setSenha] = useState('');
    const usrLogado = useUsuarioLogado();
    const [cadastrando, setCadastrando] = useState(false);
    const [token, set_token] = useState(localStorage.getItem('token'))
    useEffect(() => {
        if (!!token) {
            axios
                .get<UsuarioLogado>('/api/login/usuario', { headers: { token } })
                .then(resp => setUsuarioLogado(resp.data, token))
                .catch(() => {
                    localStorage.removeItem('token')
                    set_token(null)
                })
        }
    }, [])

    if (!!usrLogado) {
        return props.children;
    } else if (cadastrando) {
        return <TelaCadastro fechaCadastro={() => setCadastrando(false)} />
    } else {
        if (!!token) {
            return <></>
        } else {
            return <>
                <Container maxWidth="xs" sx={{ mt: 12 }}>
                    <CaixaCinza>
                        <Box sx={{ textAlign: "center", padding: 1, marginBottom: 2 }}>
                            <Typography variant="h6">
                                Intranet SEJUSP
                            </Typography>
                        </Box>
                        <Grid container direction="column" spacing={3} sx={{ textAlign: "center" }}>
                            <Grid item>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="cpf-mascarado" variant="outlined">CPF</InputLabel>
                                    <OutlinedInput
                                        label="CPF"
                                        id="cpf-mascarado"
                                        value={cpf}
                                        onChange={(ev) => setCPF(ev.target.value)}
                                        inputComponent={MascaraCPF as any} />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TextField label="Senha" value={senha} onChange={(ev) => setSenha(ev.target.value)} type="password" />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" size="large" onClick={() => logar(cpf, senha)}>
                                    Entrar
                                </Button>
                            </Grid>
                        </Grid>
                        <br />
                        <Box padding={1}>
                            <p>
                                Para se cadastrar, clique
                                <Link href="https://sistemas.bombeiros.ms.gov.br/suporte/solicitacao/solicitacao-criar/263/451/true/sejusp"> aqui</Link>.
                            </p>
                        </Box>
                    </CaixaCinza>
                    <div style={{
                        position: 'fixed',
                        zIndex: -1,
                        right: 0,
                        bottom: 0,
                    }}>
                        <Container maxWidth="md">
                            <Box>
                                <Box style={{ textAlign: "right" }}>
                                    <span style={{ color: "gray" }}>Desenvolvido pela</span> Diretoria de Telemática e Estatística
                                </Box>
                                <Box style={{ textAlign: "right", fontWeight: "bold" }}>
                                    Corpo de Bombeiros de Mato Grosso do Sul
                                </Box>
                            </Box>
                        </Container>
                    </div>
                </Container>
            </>
        }
    }
};
