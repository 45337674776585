import axios from "axios"

export interface Privilegio {
    nome: string
}

export module ApiPrivilegio {
    export async function getAll(): Promise<Privilegio[]> {
        return axios.get<Privilegio[]>('/api/privilegio')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    export async function nomes(): Promise<string[]> {
        return axios.get<string[]>('/api/privilegio/nomes')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }
}
