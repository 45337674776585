import { Box } from "@mui/system";
import JoditEditor from "jodit-react";
import { IJodit } from "jodit/types/types";

const BOTOES = [
    'bold',
    'italic',
    'brush',
]

export function CaixaDeTextoSimples(props: {
    conteudo: string,
    set_conteudo: (conteudo_novo: string) => void
}) {
    const { conteudo, set_conteudo } = props

    const config: IJodit['options'] = {
        askBeforePasteFromWord: false,
        processPasteFromWord: true,
        askBeforePasteHTML: false,
        processPasteHTML: true,
        toolbarAdaptive: false,
        buttons: BOTOES,
    }

    return <Box>
        <JoditEditor
            value={conteudo}
            config={config}
            onBlur={set_conteudo}
        />
    </Box>
}
