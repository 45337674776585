import axios from "axios";
import { CadastroGeral, DocumentoAnexo, Projeto } from "./processo";

export module ApiProjeto {
    export async function get(id: number): Promise<Projeto> {
        const p = await axios.get<Projeto>('/api/projeto/' + id).then((response) => response.data)

        p.documentos = await documentos(id)

        return p
    }

    export async function criar_novo(processo: number): Promise<Projeto> {
        return axios.post<Projeto>('/api/projeto/criar-novo' + processo)
            .then((response) => response.data)
    }

    export async function salvar(projeto: Projeto) {
        return axios.post<Projeto>('/api/projeto/', projeto)
            .then((response) => response.data)
    }

    export async function get_cadastro(id: number): Promise<CadastroGeral> {
        return axios.get<CadastroGeral>(`/api/projeto/${id}/cadastro`)
            .then((response) => response.data)
    }

    export async function salvar_cadastro(cadastro: CadastroGeral) {
        return axios.post<CadastroGeral>(`/api/projeto/${cadastro.projeto}/cadastro`, cadastro)
            .then((response) => response.data)
    }

    export async function documentos(projeto: number): Promise<DocumentoAnexo[]> {
        return axios.get<DocumentoAnexo[]>(`/api/projeto/${projeto}/documentos/`)
            .then(res => res.data)
    }

    export async function upload_doc(projeto: number, local: 'etp' | 'tr' | string,  nome: string, file: File): Promise<string> {
        const form = new FormData()
        form.append("file", file)
        form.append("nome", nome)
        form.append("local", local)
        return axios
            .post<string>(`/api/projeto/${projeto}/upload-documento`, form)
            .then(res => res.data)
    }

    export async function upload_doc_complementar(projeto: number, nome: string, local: 'etp' | 'tr', file: File): Promise<string> {
        const form = new FormData()
        form.append("file", file);
        form.append("nome", nome)
        return axios
            .post<string>(`/api/projeto/${projeto}/upload-documento-complementar?local=${local}`, form)
            .then(res => res.data)
    }

    export async function upload_doc_origem(projeto: number, file: File): Promise<string> {
        const form = new FormData()
        form.append(
            "file",
            file,
        )
        const data = await axios.post<string>(`/api/projeto/${projeto}/upload-documento-origem`, form)
            .then(res => res.data)
        return data
    }

    export async function atualiza_documento(
        projeto: number,
        nome_anterior: string,
        nome_novo?: string,
        arquivo?: File
    ): Promise<string> {
        const form = new FormData()
        form.append("projeto", '' + projeto)
        form.append("nome_anterior", nome_anterior)
        if (nome_novo) {
            form.append("nome_novo", nome_novo)
        }
        if (arquivo) {
            form.append("arquivo", arquivo);
        }
        return axios.post<string>(`/api/projeto/atualiza-documento`, form)
            .then(res => res.data)
    }

    export async function remover_doc_complementar(projeto: number, nome: string) {
        return axios.delete(`/api/projeto/${projeto}/remover-documento/${nome}`)
            .then(res => res.data)
    }

    export function imprimir_etp(projeto: number, local: string, data: string, assinaturas: { nome: string, cargo: string, area: string, email: string }[]) {
        const assinaturas_str = assinaturas.map(a => '&assinatura=' + JSON.stringify(a)).join('')
        window.open(`/api/projeto/${projeto}/etp.pdf?local=${local}&data=${data}${assinaturas_str}`, '_blank')
    }

    export function imprimir_etp_html(projeto: number, local: string, data: string, assinaturas: { nome: string, cargo: string, area: string, email: string }[]) {
        const assinaturas_str = assinaturas.map(a => '&assinatura=' + JSON.stringify(a)).join('')
        window.open(`/api/projeto/${projeto}/etp.html?local=${local}&data=${data}${assinaturas_str}`, '_blank')
    }

    export function imprimir_etp_docx(projeto: number, local: string, data: string) {
        window.open(`/api/projeto/${projeto}/etp.docx?local=${local}&data=${data}`, '_blank')
    }

    export function imprimir_tr(projeto: number, local: string, data: string, assinaturas: { nome: string, cargo: string, area: string, email: string }[]) {
        const assinaturas_str = assinaturas.map(a => '&assinatura=' + JSON.stringify(a)).join('')
        window.open(`/api/projeto/${projeto}/tr.pdf?local=${local}&data=${data}${assinaturas_str}`, '_blank')
    }

    export function imprimir_tr_html(projeto: number, local: string, data: string, assinaturas: { nome: string, cargo: string, area: string, email: string }[]) {
        const assinaturas_str = assinaturas.map(a => '&assinatura=' + JSON.stringify(a)).join('')
        window.open(`/api/projeto/${projeto}/tr.html?local=${local}&data=${data}${assinaturas_str}`, '_blank')
    }

    export async function anterior(projeto: number) {
        return axios.get<Projeto>(`/api/projeto/${projeto}/anterior`)
            .then(res => res.data)
    }
}
