import { useEffect, useState } from "react";
import { Titulo } from "../components/componentes";
import { Processo } from "../processo/processo";
import { ApiProcesso } from "../processo/processo.api";
import { Box, Container, Stack, TextField } from "@mui/material";
import { ProcessoHelper } from "../processo/processo.helper";
import { exibeAlerta } from "../components/Alerta";

function EditaNumProcesso(props: { processo: Processo }) {
    const processo = props.processo
    const [numProcesso, setNumProcesso] = useState(props.processo.numProcesso)
    const [numProcessoOriginal, setNumProcessoOriginal] = useState(props.processo.numProcesso)
    const [numSolicitacao, setNumSolicitacao] = useState(props.processo.numSolicitacao)
    const [numSolicitacaoOriginal, setNumSolicitacaoOriginal] = useState(props.processo.numSolicitacao)

    const helper = new ProcessoHelper(processo);
    const cadastro = helper.cadastro();

    function handleNumProcessoChange(e: any) {
        setNumProcesso(e.target.value)
    }

    function handleNumSolicitacaoChange(e: any) {
        setNumSolicitacao(e.target.value)
    }

    function handleSalvar() {
        ApiProcesso.salvarNumProcesso(processo.id, numProcesso, numSolicitacao).then(() => {
            setNumProcessoOriginal(numProcesso);
            setNumSolicitacaoOriginal(numSolicitacao);
            exibeAlerta('Número do processo e número da solicitação de compra salvos com sucesso')
        }).catch(() => {
            exibeAlerta('Erro ao salvar número do processo ou número da solicitação de compra', {
                severity: 'error'
            })
        })
    }

    return <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: '40px 2fr 1fr',
            gap: 1,
            alignItems: 'center',
            padding: 2,
            border: '1px solid lightgrey',
            backgroundColor: 'white'
        }}>
        <Box>
            {processo.id}
        </Box>
        <p>{cadastro.objeto}</p>
        <Box sx={{
            display: 'flex',
        }}>
            <Box
                sx={{ display: 'grid', gap: 1 }}
            >
                <TextField
                    label="Número do processo"
                    value={numProcesso}
                    onChange={handleNumProcessoChange}
                    size='small'
                />
                <TextField
                    label="Número da Solicitação de Compra"
                    value={numSolicitacao}
                    onChange={handleNumSolicitacaoChange}
                    size='small'
                />
            </Box>
            <button
                onClick={handleSalvar}
                disabled={numProcesso === numProcessoOriginal && numSolicitacao === numSolicitacaoOriginal}
            >Salvar</button>
        </Box>
    </Box>
}

export function TelaEditaNumProcesso() {
    const [processos, setProcessos] = useState<Processo[]>([])

    useEffect(() => {
        ApiProcesso.getAll().then(setProcessos)
    }, []);

    return <Container>
        <Titulo titulo="Processos" />
        <Stack>
            {processos.map(p => <EditaNumProcesso processo={p} />)}
        </Stack>
    </Container>
}
