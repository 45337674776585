import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { exibeAlerta } from "../../components/Alerta";
import { Titulo } from "../../components/componentes";
import { Processo, Projeto } from "../../processo/processo";
import { ApiProcesso } from "../../processo/processo.api";
import { ProcessoHelper } from "../../processo/processo.helper";
import { HistoricoEmElaboracao, HistoricoEnviadoParaAnalise, HistoricoProjetoNotificado } from "../../processo/processo.historico";
import { ApiProjeto } from "../../processo/projeto.api";
import { useUsuarioLogado } from "../../usuario-logado";
import { ExibeProjeto } from "./ExibeProjeto";

interface Envio {
    elaboracao: HistoricoEmElaboracao | HistoricoProjetoNotificado,
    envio?: HistoricoEnviadoParaAnalise,
    analise?: HistoricoProjetoNotificado
}

function isoToDataPt(iso: string) {
    return format(new Date(iso), 'dd/MM/yyyy')
}

export function TelaExibeProcesso() {
    const usr = useUsuarioLogado()
    const params = useParams<{ id: string, projeto_id?: string }>()
    const navigate = useNavigate()
    let id = +(params.id || 0);

    const [processo, set_processo] = useState<Processo>()
    const [projeto, set_projeto] = useState<Projeto>()
    //const [indice_projeto, set_indice_projeto] = useState<number>()
    const [projeto_id, set_projeto_id] = useState<number>()
    const [envios, set_envios] = useState<Envio[]>([])
    const [historico_anchor, set_historico_anchor] = useState<null | HTMLElement>(null)

    useEffect(() => {
        ApiProcesso
            .get(id)
            .then(p => {
                set_processo(p)
                const envios: Envio[] = []
                for (let h of p.historico) {
                    switch (h.tipo) {
                        case 'elaboracao':
                            envios.push({ elaboracao: h })
                            break
                        case 'enviado-para-analise':
                            envios[envios.length - 1].envio = h
                            break
                        case 'projeto-notificado':
                            envios[envios.length - 1].analise = h
                            envios.push({ elaboracao: h })
                            break
                    }
                }
                set_envios(envios)
                if (params?.projeto_id) {
                    set_projeto_id(+params.projeto_id)
                } else {
                    set_projeto_id(envios[envios.length - 1].elaboracao.projeto.id)
                    navigate(`/compras/processo/${id}/projeto/${envios[envios.length - 1].elaboracao.projeto.id}`, { replace: true })
                }
                //set_indice_projeto(envios.length - 1)
            })
            .catch((e) => {
                exibeAlerta("Processo não encontrado", { severity: "error" })
                navigate("/compras")
            })
    }, [id])
    const update_projeto = () => {
        if (projeto_id != undefined) {
            ApiProjeto.get(projeto_id).then(set_projeto)
        }/* else if (indice_projeto !== undefined && !!envios[indice_projeto]) {
            ApiProjeto.get(envios[indice_projeto].elaboracao.projeto.id).then(set_projeto)
        }*/
        /*ApiProcesso
            .get(id)
            .then(set_processo)*/
    }
    useEffect(update_projeto, [projeto_id, envios])

    if (!processo) return <></>
    if (!projeto) return <></>

    const helper = new ProcessoHelper(processo)

    /*const emEdicao = helper.estaEmEdicao()
        //&& (indice_projeto === envios.length - 1)
        && (projeto_id === envios[envios.length - 1].elaboracao.projeto.id)
        && !!usr?.permissoes.find(p => p.permissao === 'equipe-planejamento')
        && keys(processo.equipe).includes(usr.id + '')*/
    const motivoNaoPodeEditar =
        (!helper.estaEmEdicao() && 'O processo não está com a equipe de planejamento (ex.: está aguardando análise)')
        || ((projeto_id !== envios[envios.length - 1].elaboracao.projeto.id) && 'Esta não é a última versão do projeto')
        || (!usr?.permissoes.find(p => p.permissao === 'equipe-planejamento') && 'Você não tem permissão de edição de processos de planejamento')
        || (!keys(processo.equipe).includes(usr?.id + '') && 'Você não faz parte da equipe de planejamento desse processo')
        || undefined

    const aguardandoAnalise = helper.estaAguardandoAnalise()

    function EnvioToListItem(envio: Envio, i: number) {
        let status = 'Em elaboração / edição'
        const datas = [<li key={"elaboracao" + i}>Elaboração inciada em <b>{isoToDataPt(envio.elaboracao.data)}</b></li>]

        if (!!envio.envio) {
            status = 'Enviado para análise'
            datas.push(<li key={"envio" + i}>Enviado para análise em <b>{isoToDataPt(envio.envio.data)}</b></li>)
        }
        if (!!envio.analise) {
            status = 'Analisado'
            datas.push(<li key={"analise" + i}>Analisado em <b>{isoToDataPt(envio.analise.data)}</b></li>)
        }

        return <MenuItem key={i}
            onClick={() => {
                set_projeto_id(envios[i].elaboracao.projeto.id)
                navigate(`/compras/processo/${id}/projeto/${envios[i].elaboracao.projeto.id}`)
                //set_indice_projeto(i)
                set_historico_anchor(null)
            }}
        >
            <ListItemText secondary={
                <ul>
                    {datas}
                </ul>
            }>{i + 1} - {status}</ListItemText>
        </MenuItem>
    }

    const indice_projeto = envios.findIndex(e => e.elaboracao.projeto.id === projeto_id)

    return <>
        <Titulo
            titulo={`Processo nº${projeto.processo} / Apresentação nº`}
            comBotaoVoltar
            itemApos={<Button variant="outlined" size="small"
                onClick={ev => set_historico_anchor(ev.currentTarget)}>
                0{(indice_projeto || 0) + 1}
            </Button>}
        />
        <Box padding={1} flexGrow={1} display="flex">
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Menu
                    open={!!historico_anchor}
                    anchorEl={historico_anchor}
                    onClose={() => set_historico_anchor(null)}>
                    {envios.map(EnvioToListItem)}
                </Menu>

                <ExibeProjeto key={projeto.id}
                    {...{
                        processo,
                        projeto,
                        motivoNaoPodeEditar,
                        aguardandoAnalise,
                    }}
                    analistas={helper?.analistas_atuais()}
                    update_projeto={update_projeto}
                />
            </Box>
        </Box>
    </>
}