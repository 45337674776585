import {
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  List as ListIcon,
  Print,
  Send,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getDate, getMonth, getYear } from "date-fns";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { alertaCallback, blockUi, exibeAlerta } from "../../components/Alerta";
import { AvatarUsr, AvatarUsrId } from "../../components/AvatarUsr";
import { CaixaCinza } from "../../components/componentes";
import { CIDADES } from "../../dados/cidades";
import { MESES } from "../../dados/meses";
import { ApiUsuario, Usuario } from "../../entidades/usuario";
import { isoToDataPt } from "../../ferramentas";
import { Processo, Projeto } from "../../processo/processo";
import { ApiProcesso } from "../../processo/processo.api";
import { ProcessoHelper } from "../../processo/processo.helper";
import { ApiProjeto } from "../../processo/projeto.api";
import { useUsuarioLogado } from "../../usuario-logado";

type Assinatura = {
  nome: string;
  cargo: string;
  area: string;
  email: string;
};

function ModalSelecionaAnalista(props: {
  aberto: boolean;
  fechar: () => void;
  callback: (analista: number) => void;
}) {
  const { aberto, fechar, callback } = props;
  const [usuarios, set_usuarios] = useState<Usuario[]>([]);
  const [loading, set_loading] = useState(false);

  useEffect(() => {
    if (aberto && usuarios.length === 0) {
      set_loading(true);
      ApiUsuario.getComPermissao("analista-planejamento")
        .then((usrs) => {
          set_usuarios(usrs.map((usr) => usr));
        })
        .catch(exibeAlerta)
        .finally(() => set_loading(false));
    }
  }, [aberto]);

  return (
    <Dialog open={aberto} onClose={fechar}>
      <DialogTitle>Selecione o analista</DialogTitle>
      {loading && <CircularProgress color="success" />}
      <List>
        {usuarios.map((usr) => (
          <ListItemButton
            key={usr.id}
            onClick={() => {
              callback(usr.id);
              fechar();
            }}
          >
            <ListItemIcon>
              <AvatarUsr usr={usr} />
            </ListItemIcon>
            <ListItemText primary={usr.nome} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}

function ConfirmarImpressao(props: {
  callback: (local: string, data: string, assinaturas: Assinatura[]) => void;
  aberto: boolean;
  fechar: () => void;
  equipe: Record<number, { cargo: string; area: string }>;
}) {
  const { callback, aberto, fechar, equipe } = props;
  const [local, set_local] = useState("Campo Grande");

  const [dia, set_dia] = useState(getDate(new Date()));
  const [mes, set_mes] = useState(MESES[getMonth(new Date())]);
  const [ano, set_ano] = useState(getYear(new Date()));
  const [assinaturas, set_assinaturas] = useState([] as Assinatura[]);
  const [assinaturas_membros, set_assinaturas_membros] = useState(
    [] as (Assinatura & { ativo: boolean })[]
  );
  useEffect(() => {
    if (aberto) {
      ApiUsuario.getList(keys(equipe)).then((membros) => {
        set_assinaturas_membros(
          membros.map((usr) => ({
            nome: usr.nome,
            cargo: equipe[usr.id].cargo,
            area: equipe[usr.id].area,
            email: usr.email,
            ativo: true,
          }))
        );
      });
    }
  }, [aberto, equipe]);
  const toggle_assinatura_membro = (idx: number) => {
    const nova_assinatura = [...assinaturas_membros];
    nova_assinatura[idx].ativo = !nova_assinatura[idx].ativo;
    set_assinaturas_membros(nova_assinatura);
  };

  const campos = ["nome", "cargo", "area", "email"] as const;
  function handle_assinatura(
    idx: number,
    campo: (typeof campos)[number],
    valor: string
  ) {
    const nova_assinatura = [...assinaturas];
    nova_assinatura[idx][campo] = valor;
    set_assinaturas(nova_assinatura);
  }
  const handle_remove_assinatura = (idx: number) =>
    set_assinaturas(assinaturas.filter((_, i) => i !== idx));

  const imprimir = () => {
    const todas_assinaturas = [
      ...assinaturas_membros.filter((a) => a.ativo),
      ...assinaturas,
    ];
    callback(local, `${dia} de ${mes} de ${ano}`, todas_assinaturas);
    fechar();
  };

  return (
    <Dialog open={aberto} onClose={fechar}>
      <DialogTitle>Confirme o local e a data da impressão</DialogTitle>
      <Box margin={1}>
        <FormControl fullWidth>
          <InputLabel>Local</InputLabel>
          <Select
            value={local}
            label="Local"
            onChange={(ev) => set_local(ev.target.value)}
          >
            {CIDADES.map((cidade) => (
              <MenuItem key={cidade} value={cidade}>
                {cidade}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box margin={1} display="flex">
        <TextField
          label="Dia"
          sx={{ width: "45px" }}
          variant="outlined"
          onChange={(ev) => set_dia(+ev.target.value)}
          value={dia}
        />
        <FormControl sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1 }}>
          <InputLabel>Mês</InputLabel>
          <Select
            label="Mês"
            value={mes}
            onChange={(ev) => set_mes(ev.target.value)}
          >
            {MESES.map((mes) => (
              <MenuItem key={mes} value={mes}>
                {mes}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Ano"
          sx={{ width: "65px" }}
          variant="outlined"
          value={ano}
          onChange={(ev) => set_ano(+ev.target.value)}
        />
      </Box>
      {assinaturas_membros.map((assinatura, idx) => (
        <Box key={idx} margin={1} display="flex">
          <TextField
            label="Nome"
            variant="outlined"
            value={assinatura.nome}
            disabled
          />
          <TextField
            label="Cargo"
            variant="outlined"
            value={assinatura.cargo || " "}
            disabled
          />
          <TextField
            label="Área"
            variant="outlined"
            value={assinatura.area || " "}
            disabled
          />
          <TextField
            label="Email"
            variant="outlined"
            value={assinatura.email}
            disabled
          />
          {assinatura.ativo && (
            <Button
              onClick={() => toggle_assinatura_membro(idx)}
              variant="outlined"
            >
              <CheckBox color="success" />
            </Button>
          )}
          {!assinatura.ativo && (
            <Button
              onClick={() => toggle_assinatura_membro(idx)}
              variant="outlined"
            >
              <CheckBoxOutlineBlank color="error" />
            </Button>
          )}
        </Box>
      ))}
      {assinaturas.map((assinatura, idx) => (
        <Box key={idx} margin={1} display="flex">
          {campos.map((campo) => (
            <TextField
              label={campo}
              //sx={{ width: '65px' }}
              variant="outlined"
              value={assinatura[campo]}
              onChange={(ev) => handle_assinatura(idx, campo, ev.target.value)}
            />
          ))}
          <Button
            onClick={() => handle_remove_assinatura(idx)}
            variant="outlined"
          >
            <Delete color="primary" />
          </Button>
        </Box>
      ))}
      <Box margin={1} display="flex">
        <Button
          onClick={() =>
            set_assinaturas([
              ...assinaturas,
              { nome: "", cargo: "", area: "", email: "" },
            ])
          }
        >
          Adicionar assinatura
        </Button>
      </Box>
      <Box display="flex" flexDirection="row-reverse" margin={1}>
        <Button onClick={imprimir} variant="outlined">
          Imprimir
        </Button>
        <Button onClick={() => fechar()} variant="outlined" color="secondary">
          Cancelar
        </Button>
      </Box>
    </Dialog>
  );
}

export function PainelAcoes(props: {
  processo: Processo;
  projeto: Projeto;
  update_projeto: () => void;
  emEdicao: boolean;
  analistas: number[];
  aguardandoAnalise: boolean;
  exibe_notificacao: boolean;
  exibe_etp: boolean;
}) {
  const {
    processo,
    projeto,
    update_projeto,
    emEdicao,
    analistas,
    aguardandoAnalise,
    exibe_notificacao,
    exibe_etp,
  } = props;
  const processo_helper = new ProcessoHelper(processo);
  const navigate = useNavigate();
  const usr = useUsuarioLogado();
  const [confirma_impressao_etp, set_confirma_impressao_etp] = useState(false);
  const [confirma_impressao_tr, set_confirma_impressao_tr] = useState(false);
  const [modal_selecao_analista, set_modal_selecao_analista] = useState(false);
  if (!usr) return <></>;
  const usr_eh_analista = !!usr.permissoes.find(
    (p) => p.permissao === "analista-planejamento"
  );
  const usr_eh_gestor_orgao = !!usr.permissoes.find(
    (p) => p.permissao === "gestor"
  );
  const usr_esta_na_equipe = keys(processo.equipe).includes(usr.id + "");

  const usr_eh_chefe_analise = usr.permissoes.find(
    (p) => p.permissao === "chefe-de-analise"
  );

  return (
    <>
      <CaixaCinza sx={{ marginBottom: 2 }}>
        <List disablePadding>
          <ListItem>
            <ListItemText
              primary={processo_helper.ultimoHistorico().descricao}
              secondary={isoToDataPt(processo_helper.ultimoHistorico().data)}
            />
            {processo_helper.ultimoHistorico().descricao ===
              "Análise assumida" && (
              <AvatarUsrId usr={processo_helper.ultimoHistorico().autor} />
            )}
          </ListItem>
        </List>
      </CaixaCinza>
      <CaixaCinza>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() =>
                navigate(`/compras/historico/processo/${processo.id}`)
              }
            >
              <ListItemText primary="Histórico" />
              <ListItemIcon sx={{ minWidth: 0 }}>
                <ListIcon color="primary" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {exibe_etp && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => set_confirma_impressao_etp(true)}
                >
                  <ListItemText primary="Imprimir ETP" />
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Print color="primary" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ConfirmarImpressao
                //callback={(local: string, data: string) => ApiProjeto.imprimir_etp_docx(projeto, local, data)}
                callback={(
                  local: string,
                  data: string,
                  assinaturas: Assinatura[]
                ) =>
                  ApiProjeto.imprimir_etp(projeto.id, local, data, assinaturas)
                }
                aberto={confirma_impressao_etp}
                fechar={() => set_confirma_impressao_etp(false)}
                equipe={processo.equipe}
              />
            </>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={() => set_confirma_impressao_tr(true)}>
              <ListItemText primary="Imprimir TR" />
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Print color="primary" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ConfirmarImpressao
            callback={(
              local: string,
              data: string,
              assinaturas: Assinatura[]
            ) => ApiProjeto.imprimir_tr(projeto.id, local, data, assinaturas)}
            aberto={confirma_impressao_tr}
            fechar={() => set_confirma_impressao_tr(false)}
            equipe={processo.equipe}
          />
          {exibe_notificacao && !processo_helper.estaAprovado() && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => navigate(`/compras/notificacao/${projeto.id}`)}
              >
                <ListItemText primary="Notificação" />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Print color="primary" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
          {
            //~~~~~~~~~~~~~~~~~ Renderiza o botão de submeter o projeto para análise ~~~~~~~~~~~~~~~~~~~~~~~
            emEdicao && //              O projeto está em edição
              usr_esta_na_equipe && ( // e o usuário que está visualizando faz parte da equipe
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      if (!projeto.cadastro.docOrigem) {
                        exibeAlerta(
                          "Envie o documento de origem autorizado pelo ordenador de despesa"
                        );
                        return;
                      }

                      window.confirm("Enviar o processo para análise?") &&
                        blockUi(
                          ApiProcesso.enviarParaAnalise(processo.id, usr.id)
                            .then(update_projeto)
                            .then(() => navigate("/compras/processos"))
                            .then(
                              alertaCallback("Enviado para análise com sucesso")
                            )
                            .catch(
                              alertaCallback(
                                "Falha ao enviar para análise. Tente novamente mais tarde",
                                { severity: "error" }
                              )
                            )
                        );
                    }}
                  >
                    <ListItemText primary="Enviar para análise" />
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <Send color="primary" />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )
          }
          {
            //~~~~~~~~~~~~~~~~~ Renderiza o botão de assumir análise ~~~~~~~~~~~~~~~~~~~~~~~
            // !analista //            O projeto não tem analista definido
            !analistas.includes(usr.id) && // O analista atual não é o usuário logado
              usr_eh_analista && //   e usuário que está visualizando o projeto é analista
              aguardandoAnalise && ( // e o projeto está aguardando análise
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() =>
                      window.confirm("Assumir a análise do processo?") &&
                      blockUi(
                        ApiProcesso.atribuirAnalise(processo.id, usr.id)
                          .then(update_projeto)
                          .then(alertaCallback("Análise assumida com sucesso"))
                          .catch(
                            alertaCallback(
                              "Falha ao assumir análise. Tente novamente mais tarde",
                              { severity: "error" }
                            )
                          )
                      )
                    }
                  >
                    <ListItemText primary="Assumir análise" />
                  </ListItemButton>
                </ListItem>
              )
          }
          {
            //~~~~~~~~~~~~~~~~~ Renderiza o botão de atribuir análise ~~~~~~~~~~~~~~~~~~~~~~~
            // O sistema deve exibir o botão de atribuir análise se o processo está aguardando análise e:
            // 1. O usuário logado é o chefe de análise
            // 2. Ou usuário logado é o analista atual (para que ele possa encaminhar o processo)
            aguardandoAnalise &&
              (!!usr_eh_chefe_analise || analistas.includes(usr.id)) && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        // Exibe modal de seleção de analista
                        set_modal_selecao_analista(true);
                        /*window.confirm("Assumir a análise do processo?") && blockUi(
                                    ApiProcesso
                                        .atribuirAnalise(processo.id, usr.id)
                                        .then(update_projeto)
                                        .then(alertaCallback("Análise assumida com sucesso"))
                                        .catch(alertaCallback("Falha ao assumir análise. Tente novamente mais tarde", { severity: "error" }))
                                )*/
                      }}
                    >
                      <ListItemText primary="Atribuir análise" />
                    </ListItemButton>
                  </ListItem>
                  <ModalSelecionaAnalista
                    aberto={modal_selecao_analista}
                    fechar={() => set_modal_selecao_analista(false)}
                    callback={(analista) => {
                      ApiProcesso.atribuirAnalise(processo.id, analista)
                        .then(update_projeto)
                        .then(alertaCallback("Análise atribuida com sucesso"))
                        .catch(
                          alertaCallback(
                            "Falha ao assumir análise. Tente novamente mais tarde",
                            { severity: "error" }
                          )
                        );
                    }}
                  />
                </>
              )
          }
          {
            //~~~~~~~~~~~~~~~~~ Renderiza o botão de enviar ~~~~~~~~~~~~~~~~~~~~~~~
            analistas.includes(usr.id) && ( // O usuário logado é o analista atual
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    window.confirm("Enviar (notificar) análise?") &&
                    blockUi(
                      ApiProcesso.notificar(processo.id, usr.id)
                        .then(update_projeto)
                        .then(() => navigate("/compras/processos"))
                        .then(alertaCallback("Projeto notificado com sucesso"))
                        .catch(
                          alertaCallback(
                            "Falha ao enviar análise. Tente novamente mais tarde",
                            { severity: "error" }
                          )
                        )
                    )
                  }
                >
                  <ListItemText primary="Enviar análise" />
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Send color="primary" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )
          }
          {
            //~~~~~~~~~~~~~~~~~ Renderiza o botão de aprovar ~~~~~~~~~~~~~~~~~~~~~~~
            analistas.includes(usr.id) && ( // O usuário logado é o analista atual
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/compras/processo/${processo.id}/despacho-de-conformidade`
                    );
                  }}
                >
                  <ListItemText primary="Aprovar processo" />
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Check color="primary" />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            )
          }
          {processo_helper.estaAprovado() && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  const a = document.createElement("a");
                  a.href = `/api/processo/${processo.id}/despacho-de-conformidade.docx`;
                  a.download = `despacho-de-conformidade-${processo.id}.docx`;
                  a.click();
                }}
              >
                <ListItemText primary="Imprimir Despacho de Conformidade" />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Print color="primary" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
          {(usr_eh_analista ||
            (emEdicao && usr_esta_na_equipe) ||
            usr_eh_gestor_orgao) && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  // Confirma a exclusão do processo
                  if (
                    window.confirm(
                      "Tem certeza que deseja cancelar o processo?"
                    )
                  ) {
                    // Faz chamada para API
                    blockUi(
                      ApiProcesso.cancelar(processo.id)
                        // Depois redireciona para a página de processos
                        .then(() => navigate("/compras/processos"))
                        .then(alertaCallback("Processo cancelado com sucesso"))
                        .catch(
                          alertaCallback(
                            "Falha ao cancelar processo. Tente novamente mais tarde",
                            { severity: "error" }
                          )
                        )
                    );
                  }
                }}
              >
                <ListItemText primary="Cancelar (remover) processo" />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Delete color="primary" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </CaixaCinza>
    </>
  );
}
