import axios from "axios"

export interface Orgao {
    sigla: string
    nome?: string
}

export module ApiOrgao {
    export async function getAll(): Promise<Orgao[]> {
        return axios.get<Orgao[]>('/api/sejusp/orgao')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    export async function siglas(): Promise<string[]> {
        return axios.get<string[]>('/api/sejusp/orgao/siglas')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }
}
