import { FileDownload, FileUpload, Remove } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { keys } from "lodash";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { AdicionaUsuarioButton } from '../../components/AdicionaUsuarioButton';
import { alertaCallback, blockUi, exibeAlerta } from "../../components/Alerta";
import { AvatarUsr } from '../../components/AvatarUsr';
import { FormularioCadastroGeral } from "../../components/FormularioCadastroGeral";
import { Titulo } from "../../components/componentes";
import { ApiUsuario, Usuario } from '../../entidades/usuario';
import { CadastroGeral, Processo, Projeto } from "../../processo/processo";
import { ApiProcesso } from '../../processo/processo.api';
import { ApiProjeto } from "../../processo/projeto.api";

export function ExibeCadastroGeral(props: { processo: Processo, projeto: Projeto, update_projeto: () => void, motivoNaoPodeEditar?: string }) {
    const { processo, projeto, update_projeto, motivoNaoPodeEditar } = props;
    const emEdicao = !motivoNaoPodeEditar

    const [orgaos, set_orgaos] = useState<string[]>(projeto.cadastro.orgaos)
    const [aquisicaoContratacao, set_aquisicaoContratacao] = useState<string | undefined>(projeto.cadastro.aquisicaoContratacao)
    const [docOrigem, set_docOrigem] = useState<string | undefined>(projeto.cadastro.docOrigem)
    const [nome_doc_origem, set_nome_doc_origem] = useState<string | undefined>(projeto.cadastro.nome_doc_origem)
    const [modalidade, set_modalidade] = useState<string | undefined>(projeto.cadastro.modalidade)
    const [tipoDespesa, set_tipoDespesa] = useState<string | undefined>(projeto.cadastro.tipoDespesa)
    const [objeto, set_objeto] = useState<string | undefined>(projeto.cadastro.objeto)
    const [justificativa, set_justificativa] = useState<string | undefined>(projeto.cadastro.justificativa)
    const [valorEstimado, set_valorEstimado] = useState<string | undefined>(projeto.cadastro.valorEstimado)
    const [baseAnalise, set_base_analise] = useState<string | undefined>(projeto.cadastro.baseAnalise)
    const [equipe, set_equipe] = useState<Usuario[]>([])

    const gettersESetters = {
        orgaos, set_orgaos,
        aquisicaoContratacao, set_aquisicaoContratacao,
        modalidade, set_modalidade,
        tipoDespesa, set_tipoDespesa,
        objeto, set_objeto,
        justificativa, set_justificativa,
        valorEstimado, set_valorEstimado,
        baseAnalise, set_base_analise,
    };

    function set_doc_origem(id: string) {
        set_docOrigem(id)
    }

    useEffect(() => {
        ApiUsuario.getList(keys(processo.equipe)).then(set_equipe)
    }, [processo.equipe])

    const salvar = () => {
        if (!aquisicaoContratacao
            || !modalidade
            || !tipoDespesa
            || !objeto
            || !justificativa
            || !valorEstimado
            || !baseAnalise
        ) {
            exibeAlerta("Preencha todos os campos", { severity: "error" })
            return
        }
        const cadastro: CadastroGeral = {
            projeto: projeto.id,
            orgaos,
            aquisicaoContratacao,
            docOrigem,
            nome_doc_origem,
            modalidade,
            tipoDespesa,
            objeto,
            justificativa,
            valorEstimado,
            baseAnalise,
        }
        return blockUi(ApiProjeto
            .salvar_cadastro(cadastro)
            .then(update_projeto)
        )
    }

    const adicionar_colaborador = (usr: Usuario) => {
        ApiProcesso
            .adicionar_colaborador(processo.id, usr.id)
            .then(set_equipe)
    }

    const downloadDocOrigem: MouseEventHandler = (ev) => {
        if (!!docOrigem && docOrigem !== "")
            window.open(`/api/projeto/${projeto.id}/doc-origem`, "Documento de Origem")
    }

    const uploadDocOrigem = (ev: ChangeEvent<HTMLInputElement>) => {
        const arq = ev.target.files?.[0]
        if (!arq) return

        async function faz_upload(arq: File) {
            const idDocOrigem = await ApiProjeto.upload_doc_origem(projeto.id, arq)
            await ApiProjeto.salvar_cadastro({
                projeto: projeto.id,
                orgaos,
                aquisicaoContratacao,
                docOrigem: idDocOrigem,
                nome_doc_origem,
                modalidade,
                tipoDespesa,
                objeto,
                justificativa,
                valorEstimado,
                baseAnalise
            } as any)
            set_doc_origem(idDocOrigem)
            set_nome_doc_origem(arq.name)
            update_projeto()
            alertaCallback("Documento enviado com sucesso")
        }

        const promessa_upload = faz_upload(arq)
            .catch(alertaCallback("Falha ao enviar o arquivo. Tente novamente mais tarde.", { severity: "error" }))
        blockUi(promessa_upload)
    }

    function LinhaEquipePlanejamento(props: { usr: Usuario, cargo: string, area: string }) {
        const { usr } = props
        const [cargo, set_cargo] = useState(props.cargo || '')
        const [area, set_area] = useState(props.area || '')

        useEffect(() => set_cargo(props.cargo), [props.cargo])
        useEffect(() => set_area(props.area), [props.area])

        return <TableRow>
            <TableCell><AvatarUsr usr={usr} /></TableCell>
            <TableCell>{usr.nome}</TableCell>
            <TableCell>
                <TextField
                    value={cargo}
                    onChange={(ev) => set_cargo(ev.target.value)}
                    onBlur={() => ApiProcesso.update_equipe(processo.id, usr.id, { cargo }).then(update_projeto)}
                    variant="standard"
                    disabled={!emEdicao}
                    fullWidth />
            </TableCell>
            <TableCell>
                <TextField
                    value={area}
                    onChange={(ev) => set_area(ev.target.value)}
                    onBlur={() => ApiProcesso.update_equipe(processo.id, usr.id, { area }).then(update_projeto)}
                    variant="standard"
                    disabled={!emEdicao}
                    fullWidth />
            </TableCell>
            <TableCell><a href={`mailto:${usr.email}`}>{usr.email}</a></TableCell>
            <TableCell>
                <IconButton
                    disabled={!emEdicao}
                    onClick={() => ApiProcesso.remover_colaborador(processo.id, usr.id).then(set_equipe)}>
                    <Remove color="primary" />
                </IconButton>
            </TableCell>
        </TableRow>
    }

    function EquipeDePlanejamento() {
        return <Box sx={{ display: 'flex', flexGrow: 1, border: "1px solid lightgray", borderRadius: '5px' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>Equipe de Planejamento</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="1px" />
                        <TableCell>Nome</TableCell>
                        <TableCell>Cargo</TableCell>
                        <TableCell>Área</TableCell>
                        <TableCell>e-mail</TableCell>
                        <TableCell width="1px" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {equipe.map(usr => <LinhaEquipePlanejamento
                        key={usr.id}
                        usr={usr}
                        cargo={processo.equipe[usr.id]?.cargo}
                        area={processo.equipe[usr.id]?.area}
                    />)}
                </TableBody>
                <TableRow>
                    <TableCell>
                        <AdicionaUsuarioButton
                            disabled={!emEdicao}
                            title="Adicionar membro à Equipe de Planejamento"
                            callback={adicionar_colaborador} />
                    </TableCell>
                    <TableCell colSpan={5} />
                </TableRow>
            </Table>
        </Box>
    }

    return (<>
        <Box paddingBottom={1} paddingLeft={1} paddingRight={1}>
            <Titulo titulo="Dados Gerais" />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="secondary"
                        disableRipple
                        fullWidth variant="outlined"
                        component="div"
                        endIcon={
                            <>
                                <IconButton component="label" onClick={downloadDocOrigem} disabled={!docOrigem}>
                                    <FileDownload color={docOrigem ? "primary" : "secondary"} />
                                </IconButton>
                                <IconButton component="label" disabled={!emEdicao}>
                                    <FileUpload color={emEdicao ? "primary" : "secondary"} />
                                    <input type="file" hidden
                                        accept="application/pdf"
                                        onChange={uploadDocOrigem}
                                    />
                                </IconButton>
                            </>
                        }
                    >
                        Documento de Origem Autorizado pelo Ordenador de Despesas
                    </Button>
                </Grid>
                <FormularioCadastroGeral {...gettersESetters} />
                <Grid container item xs={12}>
                    <EquipeDePlanejamento />
                </Grid>
                <Grid item xs={12} display="flex" alignSelf="center" justifyContent="center">
                    {emEdicao && <Button
                        variant="outlined"
                        title="Salvar"
                        onClick={salvar}>
                        Salvar
                    </Button>}
                </Grid>
            </Grid>
        </Box >
    </>)
}
