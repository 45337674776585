import axios from "axios";
import { exibeAlerta } from "../components/Alerta";

export type UsrId = number;

export interface Usuario {
    id: UsrId;
    pessoa_id: number;
    cpf: string;
    nome: string;
    foto: string;
    email: string;
}

export module ApiUsuario {

    export async function getByCPF(cpf: string): Promise<Usuario | undefined> {
        return axios.get<Usuario>('/api/usuario/cpf/' + cpf)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return undefined
            })
    }

    export async function get(id: number): Promise<Usuario | undefined> {
        return axios.get<Usuario>('/api/usuario/' + id)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return undefined
            })
    }

    interface DTOUsuarioSejusp {
        cpf: string,
        login: string,
        dominio: string,
        //senha: string,
        nome: string,
        email: string,
    }

    export async function cadastrar(usr: DTOUsuarioSejusp) {
        return axios.post<DTOUsuarioSejusp>('/api/usuario/cadastrar', usr)
            //.then(() => logar(usr.cpf, usr.senha))
            .catch((error) => {
                console.log(error)
                exibeAlerta(error.msg || 'Erro ao criar usuário. Contacte o suporte.', { severity: "error" })
                return undefined
            })
    }

    export async function getList(ids: (number | string)[]): Promise<Usuario[]> {
        if (!ids || ids.length === 0) {
            return []
        }
        return axios.get<Usuario[]>('/api/usuario/lista/' + ids.join(','))
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    export async function getAll(): Promise<Usuario[]> {
        return axios.get<Usuario[]>('/api/usuario')
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

    export async function getComPermissao(permissao: string): Promise<Usuario[]> {
        return axios.get<Usuario[]>(`/api/usuario/com-permissao/${permissao}`)
            .then((response) => response.data)
            .catch((error) => {
                console.log(error)
                return []
            })
    }

}
