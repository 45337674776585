import { Container, Grid, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Titulo } from "../components/componentes";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";

export function TelaVideosTutoriais() {
    const [nomes, setNomes] = useState<string[]>([])
    const navigate = useNavigate()
    const location = useLocation()
    // O nome do vídeo atual é o que está depois do # na URL (ex: #video1.mp4)
    // Para remover %20 por exemplo de um nome de arquivo, use decodeURIComponent
    const nomeVideoAtual = decodeURIComponent(location.hash.substring(1))

    useEffect(() => {
        fetch('/api/videos').then((resp) => resp.json()).then((nomes) => {
            setNomes(nomes)
        })
    }, [])

    function eliminaExtensao(nome: string) {
        return nome.replace(/\.[^.]*$/, '')
    }

    return (
        <Container sx={{ mt: 1 }}>
            <Titulo titulo="Vídeos Tutoriais" comBotaoVoltar />
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <Paper>
                        <List>
                            {nomes.map((nomeVideo) => <ListItemButton
                                key={nomeVideo}
                                onClick={() => { navigate({ hash: nomeVideo }) }}
                                selected={nomeVideo === nomeVideoAtual}>
                                <ListItemText primary={eliminaExtensao(nomeVideo)} />
                            </ListItemButton>)}
                        </List>
                    </Paper>
                </Grid>
                {!!nomeVideoAtual && <Grid item xs={12} md={8}>
                    <Paper key={nomeVideoAtual} sx={{ padding: 1 }}>
                        <b>{eliminaExtensao(nomeVideoAtual)}</b>
                        <Player
                            playsInline
                            src={`/api/videos/${nomeVideoAtual}`}
                        />
                    </Paper>
                </Grid>}
            </Grid>
            <Box display='flex' flexDirection='row'>
                <Box flexGrow={1}>
                </Box>
            </Box>
        </Container>
    )
}
