import axios from "axios";

export interface PermissaoUsrNaAplicacao {
    usr: string;
    orgao: string;
    permissao: string;
}

export module ApiPermissaoUsrNaAplicacao {

    export async function get_do_usuario(cpf: string): Promise<PermissaoUsrNaAplicacao[]> {
        return axios.get<PermissaoUsrNaAplicacao[]>("/api/permissao/do-usuario/" + cpf).then(r => r.data)
    }

    export async function get_analistas(): Promise<PermissaoUsrNaAplicacao[]> {
        return axios.get<PermissaoUsrNaAplicacao[]>("/api/permissao/analistas").then(r => r.data)
    }

    export async function adicionar(cpf: string, orgao: string, permissao: string): Promise<PermissaoUsrNaAplicacao[]> {
        return axios.post<PermissaoUsrNaAplicacao[]>("/api/permissao", {
            usr: cpf,
            orgao,
            permissao
        }).then(r => r.data)
    }

    export async function remover(p: PermissaoUsrNaAplicacao): Promise<PermissaoUsrNaAplicacao[]> {
        return axios.post<PermissaoUsrNaAplicacao[]>("/api/permissao/remover", p).then(r => r.data)
    }
}
