import L from "leaflet"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import 'leaflet/dist/leaflet.css'
import { useEffect, useState } from "react"
//import carLogo from '../../assets/car-icon.png'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export function MapaOperacional() {
    const position = new L.LatLng(
        -20.4649,
        -54.6218,
    )
    /*const position = [
        -20.4649,
        -54.6218,
    ]*/

    const [ws, set_ws] = useState<WebSocket>()
    useEffect(() => {
        const ws = new WebSocket("ws://localhost:3210/CBMMS/teste")
        ws.onmessage = (msg) => console.log(msg)
        ws.onopen = ev => {
            console.log(ev)
            try {
                ws.send('XEF6aTFV6ciR6fTWpqmcU4r3C8JECSCE')
            } catch (e) {
                console.error(e)
            }
        }
        set_ws(ws)

        return () => ws?.close()
    }, [])

    /*const carIcon = new L.Icon({
        iconUrl: carLogo,
        iconRetinaUrl: carLogo,
        iconSize: [60, 55],
        shadowSize: [50, 64],
        iconAnchor: [22, 94],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
    })*/

    return (
        <div style={{ height: "800px", width: "100%", backgroundColor: "red" }}>
            <MapContainer center={position} zoom={8} scrollWheelZoom={false} style={{ height: "100%" }}>
                <TileLayer
                    //attribution={attribution}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}
