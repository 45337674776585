import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { useDebugValue, useState } from "react";
import { singletonHook } from "react-singleton-hook";

export var exibeAlerta: (msg: string, opcoes?: AlertProps) => void = () => { }
export var fechaAlerta: () => void = () => { }
export const alertaCallback = (msg: string, opcoes?: AlertProps) => ((e: any) => {
    exibeAlerta(e?.response?.data?.msg || msg, opcoes)
})

export var blockUi: <T>(p: Promise<T>) => Promise<T>

const useAlerta = singletonHook<[boolean, SnackbarProps['children']]>([false, undefined], () => {
    const [alerta, setAlerta] = useState<SnackbarProps['children']>(undefined)
    const [aberto, setAberto] = useState(false)

    fechaAlerta = () => {
        setAlerta(undefined)
        setAberto(false)
    }

    exibeAlerta = (msg, opcoes) => {
        const ops: AlertProps = {
            severity: "success",
            ...opcoes
        }
        const a = <Alert
            {...ops}
        >
            {msg}
        </Alert>
        setAlerta(a)
        setAberto(true)
    }
    return [aberto, alerta]
});

const useBloqueado = singletonHook(false, () => {
    const [bloqueado, setBloqueado] = useState(false);

    blockUi = async function <T>(p: Promise<T>) {
        setBloqueado(true)
        const v = await p
        setBloqueado(false)
        return v
    }

    return bloqueado;
})

export function Alerta() {
    const [aberto, alerta] = useAlerta()
    const bloqueado = useBloqueado()
    useDebugValue(alerta)
    return <>
        <Snackbar
            open={aberto}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={fechaAlerta}
        >
            {alerta}
        </Snackbar>
        <div className="block-ui" style={{ display: bloqueado ? "flex" : "none" }}>
            <img src="/images/spinner.gif" height="50px" width="50px" alt="carregando" />
        </div>
    </>
}
