import { Typography, Button, Paper } from "@mui/material"
import { Box } from "@mui/system"
import { useNavigate } from "react-router-dom"

const COR_DE_FUNDO = "#eaedf0"
//const COR_DE_FUNDO = "smoke"

export function CaixaCinza(props: React.ComponentProps<typeof Box>) {
    return <Paper
        elevation={3}
        sx={props.sx}
    >
        {props.children}
    </Paper>
    /*return <Box
        sx={{
            backgroundColor: COR_DE_FUNDO,
            //border: "1px gray solid",
            //border: "1px green solid",
            //borderRadius: "3px",
            ...props.sx,
        }}>
        {props.children}
    </Box>*/
}

export function Titulo(props: { titulo: string, comBotaoVoltar?: boolean, itemApos?: React.ReactElement | false }) {
    const navigate = useNavigate()
    return <Box display="flex" flexDirection="row">
        {props.comBotaoVoltar && <Button onClick={() => navigate(-1)}>Voltar</Button>}
        <Box flexDirection="row" flexGrow={1} sx={{ textAlign: "center", padding: 1 }}>
            <Typography variant="h6">{props.titulo}{props.itemApos}</Typography>
        </Box>
    </Box>
}
