import { Print, Send } from "@mui/icons-material";
import { Button, Container, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { alertaCallback, blockUi } from "../components/Alerta";
import { AvatarUsrId } from "../components/AvatarUsr";
import { CaixaCinza, Titulo } from "../components/componentes";
import { ApiAnalise, ItemNotificacaoAnalise } from "../processo/analise";
import { Processo } from "../processo/processo";
import { ApiProcesso } from "../processo/processo.api";
import { ProcessoHelper } from "../processo/processo.helper";
import { useUsuarioLogado } from "../usuario-logado";

function LinhaItemNotificacao(props: { item: ItemNotificacaoAnalise }) {
    const { item } = props
    return <TableRow>
        <TableCell>{item.local.toLocaleUpperCase()} / {item.especificacao_local}</TableCell>
        <TableCell>{item.titulo}</TableCell>
        <TableCell>
            <AvatarUsrId usr={item.autor} />
            <span dangerouslySetInnerHTML={{ __html: item.conteudo }}>
            </span>
        </TableCell>
        <TableCell>
            {!!item.respondido_por && <AvatarUsrId usr={item.respondido_por} />}
            {item.resposta}
        </TableCell>
    </TableRow>
}

const MSG_NOTIFICACAO_VAZIA = <TableRow>
    <TableCell colSpan={5}>
        Nenhum item inserido
    </TableCell>
</TableRow>

export function TelaNotificacaoAnalise() {
    // id da notificação
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const usr = useUsuarioLogado()

    const [processo, set_processo] = useState<Processo>()
    useEffect(() => { id && ApiProcesso.get_do_projeto(+id).then(set_processo) }, [id])

    // Inicializa os items da análise
    const [items_analise, set_items_analise] = useState<ItemNotificacaoAnalise[]>([])
    const atualiza_items_de_analise = () => {
        if (id) {
            ApiAnalise
                .get_items_do_projeto(+id)
                .then(set_items_analise)
        }
    }
    useEffect(atualiza_items_de_analise, [id])

    const usr_esta_na_equipe = keys(processo?.equipe || []).includes(usr?.id + '')
    const helper = processo && new ProcessoHelper(processo)
    const emEdicao = helper && helper.estaEmEdicao() && helper.projetos()[helper.projetos().length - 1].id === +(id || 0);

    const botaoImpressao = <IconButton>
        <Print color="primary" />
    </IconButton>

    return <>
        <Container>
            <Titulo
                titulo={`Projeto nº${id}`}
                comBotaoVoltar
                // itemApos={botaoImpressao}
            />
            <CaixaCinza>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Local</TableCell>
                            <TableCell>Título</TableCell>
                            <TableCell>Conteúdo</TableCell>
                            <TableCell>Resposta</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items_analise.length === 0 && MSG_NOTIFICACAO_VAZIA}
                        {items_analise.map(item => <LinhaItemNotificacao item={item} />)}
                    </TableBody>
                </Table>
                {   //~~~~~~~~~~~~~~~~~ Renderiza o botão de submeter o projeto para análise ~~~~~~~~~~~~~~~~~~~~~~~
                    emEdicao //              O projeto está em edição
                    && usr_esta_na_equipe // e o usuário que está visualizando faz parte da equipe
                    && <Button
                        sx={{ margin: 1 }}
                        endIcon={<Send color="primary" />}
                        variant="outlined"
                        onClick={() => window.confirm("Enviar o processo para análise?") && blockUi(
                            ApiProcesso
                                .enviarParaAnalise(processo?.id || 0, usr?.id || 0)
                                .then(() => navigate("/compras/processos"))
                                .then(alertaCallback("Enviado para análise com sucesso"))
                                .catch(alertaCallback("Falha ao enviar para análise. Tente novamente mais tarde", { severity: "error" }))
                        )}>
                        Enviar para análise
                    </Button>
                }
            </CaixaCinza>
        </Container>
    </>
}
