import { Button, Container, Grid, Paper, Tab, Tabs } from "@mui/material";
import JoditEditor from "jodit-react";
import { groupBy, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { Titulo } from "../../components/componentes";
import { ApiItemEtpTr, BaseItem } from "../../processo/atributo-documento";

function CaixaEdicao(props: { item: BaseItem, aberto: boolean }) {
    const { item, aberto } = props
    const [ajuda, set_ajuda] = useState(item.ajuda)

    const salvar = () => {
        ApiItemEtpTr.salvar_base_item({ ...item, ajuda })
    }

    return <div hidden={aberto} style={{ flexGrow: 1 }}>
        <JoditEditor
            value={item.ajuda}
            onBlur={set_ajuda}
        />
        <p>
            {item.ordem} - {item.titulo}
        </p>
        <Button
            variant="outlined"
            onClick={salvar}
        //disabled={ajuda === item.ajuda}
        >
            Salvar
        </Button>
    </div>
}

function EditaModeloTR(props: { bases: BaseItem[] }) {
    const { bases } = props
    const [item_aberto, set_item_aberto] = useState(bases[0]?.titulo)

    return <>
        <Grid item xs={3}>
            <Tabs value={item_aberto} onChange={(ev, v) => set_item_aberto(v)} orientation="vertical">
                {bases.map(item => <Tab label={`${item.ordem} ${item.titulo}`} value={item.titulo} />)}
            </Tabs>
        </Grid>
        <Grid item xs={6}>
            <p>
                <a target="_blank" href={"/api/minuta/" + bases[0]?.documento}>
                    {bases[0]?.documento}
                </a>
            </p>
            {
                bases.map(i => <CaixaEdicao item={i} aberto={i.titulo !== item_aberto} />)
            }
        </Grid>
    </>
}

export function TelaEditaModelo() {
    const [bases, set_bases] = useState<BaseItem[]>([])
    const [modelo, set_modelo] = useState('')
    const bases_agrupadas_por_modelo = groupBy(sortBy(bases, 'ordem'), 'documento')

    const modelos = Object.keys(bases_agrupadas_por_modelo).filter(m => m.endsWith('.docx'))

    useEffect(() => {
        ApiItemEtpTr.items_base().then(set_bases)
    }, [])

    return <>
        <Titulo titulo="Edição de Modelos de TR" />
        <Container maxWidth='xl'>
            <Paper>
                <Grid container>
                    <Grid item xs={3}>
                        <Tabs
                            value={modelo}
                            onChange={(ev, v) => set_modelo(v)}
                            variant="scrollable"
                            orientation="vertical"
                            title={modelo}
                        >
                            {modelos.map(m => <Tab label={m} value={m} />)}
                        </Tabs>
                    </Grid>
                    <EditaModeloTR bases={bases_agrupadas_por_modelo[modelo] || []} />
                </Grid>
            </Paper>
        </Container>
    </>
}
