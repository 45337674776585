import { Badge, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { groupBy, sortBy, values } from "lodash";
import { useEffect, useState } from "react";
import { ItemNotificacaoAnalise } from "../../processo/analise";
import { ApiItemEtpTr, BaseItem } from "../../processo/atributo-documento";
import { Processo, Projeto } from "../../processo/processo";
import { ProcessoHelper } from "../../processo/processo.helper";
import { ExibeDocsComplementares } from "./ExibeDocumentos";
import { BotaoOrientacoes, CaixaDeTexto } from "./ExibeETP";

export function ExibeEdicaoTR(props: {
    processo: Processo,
    projeto: Projeto,
    motivoNaoPodeEditar?: string,
    especificacao_local: string,
    set_especificacao_local: (l: string) => void,
    items_analise: ItemNotificacaoAnalise[]
}) {
    const { projeto, motivoNaoPodeEditar, especificacao_local, set_especificacao_local, items_analise } = props
    const helper = new ProcessoHelper(props.processo)
    const num_projeto_do_processo: number = helper.projetos().findIndex(p => p.id === projeto.id) || 0

    const abas = sortBy(values(projeto.tr), 'ordem').map(i => i.nome)

    const [bases_tr, set_bases_tr] = useState<Record<string, BaseItem>>({})
    console.log(bases_tr)

    const carrega_itens_base = () => {
        ApiItemEtpTr
            .items_base_tr_do_cadastro(projeto.cadastro)
            .then(items_base => {
                const mapa_itens: Record<string, BaseItem> = {}
                for (let item of items_base) {
                    mapa_itens[item.titulo] = item
                }
                set_bases_tr(mapa_itens)
            })
    }
    useEffect(carrega_itens_base, [])

    useEffect(() => {
        if (!especificacao_local || !abas.includes(especificacao_local)) {
            set_especificacao_local(abas[0])
        }
    }, [])

    const items_agrupados = groupBy(items_analise, 'especificacao_local')
    function nao_respondido(item: ItemNotificacaoAnalise): boolean {
        return !item.resposta
    }

    function items_notificacao(atributo: string) {
        return items_agrupados?.[atributo] || []
    }

    // O item deve ser editável se estiver em edição e se houver items referentes a ele na análise
    function motivoNaoEditavel(atributo: string): string | undefined {
        if (!!motivoNaoPodeEditar) return motivoNaoPodeEditar
        // Na elaboração inicial o texto deve sempre ser editável
        if (num_projeto_do_processo === 0) return undefined
        // O texto só pode ser editado se houver items referentes a ele na análise
        // if (items_notificacao(atributo).length === 0) return 'O texto não pode ser editado porque não há itens referentes a ele na análise'

        return undefined
    }

    // Verifica a numeração da última aba
    // Necessário para a aba de documentos complementares
    const baseUltimaAba = bases_tr[abas[abas.length - 1]]
    const ordemUltimaAba = baseUltimaAba?.ordem || '0'
    const ordemUltimaAbaInt = parseInt(ordemUltimaAba.split('.')[0])

    return (
        <Grid container>
            <Grid item xs={3}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={(abas.includes(especificacao_local) && especificacao_local) || abas[0]}
                    onChange={(_event: React.SyntheticEvent, newValue) => set_especificacao_local(newValue)}
                    sx={{ borderRight: 1, borderColor: 'divider', flexGrow: 1 }}
                >
                    {abas.map(atributo => <Tab className="abaEtpTr" key={atributo} value={atributo} label={
                        <Badge style={{ width: "100%" }} badgeContent={items_agrupados?.[atributo]?.filter(nao_respondido).length} color="warning">{bases_tr[atributo]?.ordem}. {atributo}</Badge>} />)}
                    <Tab className="abaEtpTr" key="docs-compl" value="docs-compl" label={
                        <Badge style={{ width: "100%" }} badgeContent={items_agrupados?.["docs-compl"]?.filter(nao_respondido).length} color="warning">{ordemUltimaAbaInt + 1}. Documentos Complementares</Badge>}
                    />
                </Tabs>
            </Grid>
            <Grid item padding={1} xs={9}>
                {abas.map(atributo =>
                    <div key={atributo} hidden={atributo !== especificacao_local}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                {atributo}
                            </Typography>
                            <BotaoOrientacoes orientacoes={bases_tr[atributo]?.ajuda} titulo={atributo} />
                        </Box>
                        <CaixaDeTexto
                            projeto={projeto.id}
                            tipo="tr"
                            nome={atributo}
                            motivoNaoPodeEditar={motivoNaoEditavel(atributo)}
                            textoOrientacao={bases_tr[atributo]?.ajuda || atributo} />
                    </div>)}
                <div key="docs-compl" hidden={"docs-compl" !== especificacao_local}>
                    <Typography variant="h6">Documentos Complementares</Typography>
                    <ExibeDocsComplementares
                        local="tr"
                        projeto={projeto}
                        items_analise={[] /* TODO */}
                    />
                </div>
            </Grid>
        </Grid>
    )
}
