import { Container, Paper, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Titulo } from "../components/componentes";
import { ApiPermissaoUsrNaAplicacao, PermissaoUsrNaAplicacao } from "../entidades/permissao";
import { useUsuarioLogado } from "../usuario-logado";

const CSS_LIST_ITEM_BUTTON: React.CSSProperties = {
    border: "solid lightgray 1px",
    borderLeft: "solid #0060a8 5px",
    marginBottom: 1,
    backgroundColor: "white"
}
const PROD = !window.location.hostname.startsWith('hom')

const URL_PAINEIS: Record<string, string> = {
    'Geral': 'https://app.powerbi.com/view?r=eyJrIjoiYzM3MjZlNGUtOTVlOC00NDRkLTliY2MtOWMxNWNhYjE4M2MxIiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'CGP': 'https://app.powerbi.com/view?r=eyJrIjoiZmRjOWY0Y2QtNTg1Ni00Yzk4LWFmZDYtZGJkY2EwNmMwMDY4IiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'PCMS': 'https://app.powerbi.com/view?r=eyJrIjoiOGY0NDRjMTYtODZlYy00OGExLWFmODAtYTc5YTMzMDBiNmE5IiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'PMMS': 'https://app.powerbi.com/view?r=eyJrIjoiZjUwYjMxZjYtNDA4My00ZmFlLWFjZTAtOTRhYjc3NjBiMGVjIiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'SEJUSP': 'https://app.powerbi.com/view?r=eyJrIjoiOWM4OTdkOWYtNjU0OC00M2RkLWFiZjctNTFkM2UzMTMzYjQ5IiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'CIOPS': 'https://app.powerbi.com/view?r=eyJrIjoiYTQxYTI2MmMtMjViNi00Mjk5LWJmNmYtMTRmNDAxNjJmZTMxIiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
    'CBMMS': 'https://app.powerbi.com/view?r=eyJrIjoiNzE1ODEzOWEtZTUzMi00MmIyLTk5MzctMGI3YTE4ZGEzZGZjIiwidCI6ImU1YzEyOTFkLTFjMzYtNGNjYy04M2M0LTg2OTliY2Q0ZGFhMiJ9',
}

export function TelaPaineis(props: {}) {
    const usr = useUsuarioLogado()
    const [permissoes, set_permissoes] = useState([] as PermissaoUsrNaAplicacao[])
    const orgaos: string[] = [];
    const [aba, setAba] = useState<string>();

    // Se não houver usrLogado na verdade significa que o sistema ainda não carregou as infos do usr. Por isso ainda não podemos decidir se ele tem ou não permissão de gestor
    const usrTemPermissaoGestor = !usr
        || usr.permissoes.map(p => p.permissao).includes('gestor')
        || usr.permissoes.map(p => p.permissao).includes('gestor-geral')

    useEffect(() => {
        usr?.cpf && ApiPermissaoUsrNaAplicacao
            .get_do_usuario(usr.cpf)
            .then(set_permissoes)
    }, [usr])

    if (permissoes.find(p => p.permissao === 'gestor-geral') !== undefined) {
        orgaos.push('Geral');
    }
    const orgaosUnicos = _(permissoes)
        .filter(p => p.permissao === 'gestor')
        .map(p => p.orgao)
        .filter(o => URL_PAINEIS[o] !== undefined)
        .uniq()
        .value();
    orgaos.push(...orgaosUnicos);

    const painel = (aba === undefined) ? <div style={{ padding: 10 }}>Selecione um painel</div>
        : URL_PAINEIS[aba] === undefined ? <div style={{ padding: 10 }}>Painel não disponível</div>
            : <iframe width="100%" height="100%" src={URL_PAINEIS[aba]} />

    return <>
        <Titulo titulo="Paineis" />
        <Container>
            {usrTemPermissaoGestor && <Paper sx={{ height: '750px' }}>
                <Tabs
                    value={aba}
                    onChange={(_ev, value) => setAba(value)}>
                    {orgaos.map(o => <Tab key={o} label={o} value={o} />)}
                </Tabs>
                {painel}
            </Paper>}
            {!usrTemPermissaoGestor && <Paper>
                <p>Você não tem privilégio de gestor em nenhum órgão.</p>
            </Paper>}
        </Container>
    </>
}
