import { Divider, Table, TableCell, TableRow } from "@mui/material";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { isoToDataPt } from "../ferramentas";
import { CadastroGeral, Processo } from "../processo/processo";
import { AvatarUsrsIds } from "./AvatarUsr";
import styles from "./ListaProcessosAprovados.module.scss";

type ProcessoCancelado = Processo & {
  dataInicio: string;
  cadastro: CadastroGeral;
};

function useCancelados() {
  const [cancelados, setCancelados] = useState<ProcessoCancelado[]>([]);

  useEffect(() => {
    fetch("/api/processo/cancelados")
      .then((response) => response.json())
      .then(setCancelados);
  }, []);
  console.log(cancelados);
  return cancelados;
}

function LinhaTabelaCancelado(props: { processo: ProcessoCancelado }) {
  const { processo } = props;
  const { cadastro } = processo;

  return (
    <TableRow>
      <TableCell className={styles["alinha-direita"]}>{processo.id}</TableCell>
      <TableCell className={styles["alinha-centro"]}>
        {isoToDataPt(processo.dataInicio)}
        <Divider />
        {isoToDataPt(processo.dataCancelado || "")}
      </TableCell>
      <TableCell>{cadastro.orgaos.join(", ")}</TableCell>
      <TableCell>{cadastro.objeto}</TableCell>
      <TableCell>
        <AvatarUsrsIds ids={keys(processo.equipe).map(Number)} />
      </TableCell>
    </TableRow>
  );
}

export function ListaProcessosCancelados() {
  const cancelados = useCancelados();

  return (
    <Table>
      <TableRow>
        <TableCell className={styles["header"]}>#</TableCell>
        <TableCell className={styles["header"]}>
          Início
          <Divider />
          Cancelamento
        </TableCell>
        <TableCell className={styles["header"]}>Órgãos</TableCell>
        <TableCell className={styles["header"]}>Objeto</TableCell>
        <TableCell className={styles["header"]}>
          Equipe de Planejamento
        </TableCell>
      </TableRow>
      {(cancelados || []).map((processo) => LinhaTabelaCancelado({ processo }))}
    </Table>
  );
}
