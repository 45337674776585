import { Delete, Edit, ExpandMore, FileDownload, FileUpload, Save } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import axios from "axios"
import { groupBy, sortBy, toPairs } from "lodash"
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react"
import { alertaCallback, blockUi, exibeAlerta } from "../../components/Alerta"
import { TIPO_ARVORE_DE_DECISAO_MODELO_TR } from "../../components/FormularioCadastroGeral"
import { ItemNotificacaoAnalise } from "../../processo/analise"
import { DocumentoAnexo, Projeto } from "../../processo/processo"
import { ApiProjeto } from "../../processo/projeto.api"

const DOCS_OBRIGATORIOS_POR_MINUTA: Record<string, string[]> = {
    "LICITAÇÃO — PREGÃO ELETRÔNICO": [
        "Orçamento"
    ],
    "ADESÃO A ATA DE REGISTRO DE PREÇOS": [
        "Edital e anexos da ARP",
        "Aviso de publicação do Edital da ARP",
        "Ata de realização do certame",
        "Publicação do Resultado do pregão da ARP",
        "Termo de homologação",
        "ARP assinada",
        "Publicação do Extrato da ARP",
        "Termo de Convênio ou Plano de Trabalho",
    ],
    "DISPENSA DE LICITAÇÃO — Art. 24, II— VALOR — ATÉ R$ 17.600,00": [
        "Orçamento"
    ],
    "DISPENSA DE LICITAÇÃO — Art. 24, IV — EMERGENCIAL": [
        "Justificativa da emergência",
        "Orçamento"
    ],
    "DISPENSA DE LICITAÇÃO — Art. 24, VIII — BENS OU SERVIÇOS DA ADM PÚBLICA": [
        "Orçamento"
    ],
    "DISPENSA DE LICITAÇÃO — Art. 24, X — LOCAÇÃO DE IMÓVEL": [
        "Proposta de oferta do imóvel",
        "Especificação técnica do imóvel",
        "Book fotográfico do imóvel",
        "Certidão de Matrícula e Registro / Averbação do imóvel",
        "Último carnê de IPTU",
        "Conta de energia elétrica (UC e endereço)",
        "Conta de água (matrícula e endereço)",
        "Declaração que conste os dados bancários para depósito",
        "Ato Constitutivo, Estatuto ou Contrato Social",
        "Cópias dos documentos do(s) representante(s) legal(is)",
        "Certidão Negativa de Déb. Relativos aos Trib. Federais e à Dívida Ativa na União junto à Fazenda Federal",
        "Certificado de Registro Cadastral (CERCA)",
        "Certidão Tributária junto Fazenda Estadual",
        "Certidão de Regularidade junto à Fazenda Municipal",
        "Certidão Negativa de Débitos Trabalhistas junto à Justiça do Trabalho",
        "Certificado de regularidade do FGTS, junto à Caixa Econômica Fed.",
        "Certidão Negativa Cível da Justiça Federal do representante legal",
        "Certidão Negativa Cível da Justiça Estadual do representante legal",
        "Consulta Consolidada de Pessoa Jurídica do TCU",
        "Ofício de consulta à SAD para verificar se existe edificação pertencente ao patrimônio do Estado que atenda a demanda",
        "Ofício resposta da SAD quanto a indisponibilidade de imóvel que atenda a demanda",
    ],
    "INEXIGIBILIDADE DE LICITAÇÃO — ART. 25": [
        "Orçamento",
        "Atestado ou Declaração de Exclusividade",
        "Ato Constitutivo, Estatuto ou Contrato Social e suas alterações",
        "Cópias dos documentos do(s) representante(s) legal(is)",
        "Certidão Negativa de Déb. Relativos aos Trib. Federais e à Dívida Ativa na União junto à Fazenda Federal",
        "Certificado de Registro Cadastral (CERCA)",
        "Certidão Tributária junto Fazenda Estadual",
        "Certidão de Regularidade junto à Fazenda Municipal",
        "Certidão Negativa de Débitos Trabalhistas junto à Justiça do Trabalho",
        "Certificado de regularidade do FGTS, junto à Caixa Econômica Fed",
        "Certidão Negativa Cível da Justiça Federal do representante legal",
        "Certidão Negativa Cível da Justiça Estadual do representante legal",
        "Consulta Consolidada de Pessoa Jurídica do TCU",
    ],
    "RECEBIMENTO DE MATERIAIS E SERVIÇOS": [
        "Nota Fiscal atestada",
        "Cópia da Nota de Empenho;",
        "Certidão Negativa de Débitos. Relativos aos Tributos Federais e à Dívida Ativa na União, junto à Fazenda Federal;",
        "Certidão Tributária, junto Fazenda Estadual",
        "Certidão de Regularidade, junto à Fazenda Municipal",
        "Certidão Negativa de Débitos Trabalhistas, junto à Justiça do Trabalho",
        "Certificado de Regularidade do FGTS, junto à Caixa Econômica Federal",
    ],
    "termo_de_referencia_contratacao_direta_compras.docx": ["Orçamento"],
    "termo_referencia_compras_pregão.docx": ["Orçamento"],
    "termo-de-referencia-compras-tic.docx": ["Orçamento"],
    "termo_de_referencia_contratacao_direta_servicos.docx": ["Orçamento"],
    "termo_de_referencia_contratacao_direta_servicos_mao_de_obra.docx": ["Orçamento"],
    "termo_de_referencia_contratacao_direta_servicos_comuns_de_engenharia.docx": ["Orçamento"],
    "termo-de-referencia-engenharia_pregão.docx": ["Orçamento"],
    "termo_de_referencia_servico-com-mão de obra_pregão.docx": ["Orçamento"],
    "termo_referencia_servicos_sem_mão de obra_pregão.docx": ["Orçamento"],
    "termo-de-referencia-servicos-tic.docx": ["Orçamento"],
    "Minuta-Padrao-Termo-de-Referencia-serviço-dispensa-inex.docx": ["Orçamento"],
    "Minuta- bens-dispensa-inex para adaptação.docx": ["Orçamento"],
    "termo_de_referencia_contratacao_direta_servicos (locação de imóvel).docx": [
        "Proposta de oferta do imóvel",
        "Especificação técnica do imóvel",
        "Book fotográfico do imóvel",
        "Certidão de Matrícula e Registro / Averbação do imóvel",
        "Último carnê de IPTU",
        "Conta de energia elétrica (UC e endereço)",
        "Conta de água (matrícula e endereço)",
        "Declaração que conste os dados bancários para depósito",
        "Ato Constitutivo, Estatuto ou Contrato Social",
        "Cópias dos documentos do(s) representante(s) legal(is)",
        "Certidão Negativa de Déb. Relativos aos Trib. Federais e à Dívida Ativa na União junto à Fazenda Federal",
        "Certificado de Registro Cadastral (CERCA)",
        "Certidão Tributária junto Fazenda Estadual",
        "Certidão de Regularidade junto à Fazenda Municipal",
        "Certidão Negativa de Débitos Trabalhistas junto à Justiça do Trabalho",
        "Certificado de regularidade do FGTS, junto à Caixa Econômica Fed.",
        "Certidão Negativa Cível da Justiça Federal do representante legal",
        "Certidão Negativa Cível da Justiça Estadual do representante legal",
        "Consulta Consolidada de Pessoa Jurídica do TCU",
        "Ofício de consulta à SAD para verificar se existe edificação pertencente ao patrimônio do Estado que atenda a demanda",
        "Ofício resposta da SAD quanto a indisponibilidade de imóvel que atenda a demanda",
    ],
}

function ExibeLinhaDocumento(props: { doc: DocumentoAnexo, projeto: Projeto, recarregar: () => void }) {
    const { doc, projeto, recarregar } = props
    const [editando, set_editando] = useState(false)
    const [novo_nome, set_novo_nome] = useState(doc.nome)

    function atualizar_nome_documento() {
        const promessa_upload = ApiProjeto
            .atualiza_documento(projeto.id, doc.nome, novo_nome)
            .then(alertaCallback("Documento renomeado com sucesso"))
            .catch(alertaCallback("Falha ao renomear arquivo. Tente novamente mais tarde.", { severity: "error" }))
            .then(recarregar)
            .then(() => set_editando(false))
        blockUi(promessa_upload)
    }

    function atualizar_conteudo_documento(ev: ChangeEvent<HTMLInputElement>) {
        const arq = ev.target.files?.[0]
        if (!arq) return
        const promessa_upload = ApiProjeto
            .atualiza_documento(projeto.id, doc.nome, undefined, arq)
            .then(alertaCallback("Documento atualizado com sucesso"))
            .catch(alertaCallback("Falha ao enviar o arquivo. Tente novamente mais tarde.", { severity: "error" }))
            .then(recarregar)
        blockUi(promessa_upload)
    }

    const remover_upload: (nome: string) => MouseEventHandler = nome => _ev => {
        const promessa_upload = ApiProjeto
            .remover_doc_complementar(projeto.id, nome)
            .then(alertaCallback("Documento removido com sucesso"))
            .catch(alertaCallback("Falha ao remover o arquivo. Tente novamente mais tarde.", { severity: "error" }))
            .then(recarregar)
        blockUi(promessa_upload)
    }

    return <TextField
        fullWidth
        size="small"
        disabled={!editando}
        value={novo_nome}
        onChange={ev => set_novo_nome(ev.target.value)}
        InputProps={{
            startAdornment: <InputAdornment position="start">
                {editando ?
                    <IconButton color="primary"
                        onClick={atualizar_nome_documento}>
                        <Save />
                    </IconButton>
                    :
                    <IconButton color="primary"
                        onClick={() => set_editando(true)}>
                        <Edit />
                    </IconButton>}
            </InputAdornment>,
            endAdornment: <InputAdornment position="end">
                <IconButton
                    color="primary"
                    title="remover"
                    onClick={remover_upload(doc.nome)}
                >
                    <Delete />
                </IconButton>
                <a href={`/api/arquivos/${projeto.id}/${doc.nome}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconButton
                        color="primary"
                        title="baixar"
                    >
                        <FileDownload />
                    </IconButton>
                </a>
                <IconButton
                    color="primary"
                    title="substituir"
                    component="label"
                >
                    <FileUpload />
                    <input type="file" hidden
                        accept="application/pdf"
                        onChange={atualizar_conteudo_documento}
                    />
                </IconButton>
            </InputAdornment>
        }}
    />
}

export function ExibeDocsObrigatorios(props: { projeto: Projeto }) {
    const { projeto } = props

    const [docs, set_docs] = useState<Record<string, DocumentoAnexo[]>>({})
    const [arvoreDecisao, setArvoreDecisao] = useState<TIPO_ARVORE_DE_DECISAO_MODELO_TR>({})
    useEffect(() => {
        axios.get<TIPO_ARVORE_DE_DECISAO_MODELO_TR>('/api/items-do-projeto/arvore-de-decisao-modelo-tr')
            .then(res => setArvoreDecisao(res.data))
            .catch(err => {
                console.error(err)
                exibeAlerta('Erro ao carregar relação de documentos obrigatórios', {
                    severity: 'error',
                })
            })
    }, []);
    const { baseAnalise, aquisicaoContratacao, modalidade } = projeto.cadastro;
    const modeloTr = baseAnalise && arvoreDecisao[baseAnalise]
        && aquisicaoContratacao && arvoreDecisao[baseAnalise][aquisicaoContratacao]
        && modalidade && arvoreDecisao[baseAnalise][aquisicaoContratacao][modalidade]

    function recarregar() {
        ApiProjeto
            .documentos(projeto.id)
            .then(docs => {
                const mapa_docs = groupBy(docs.filter(d => d.obrigatorio), 'local')
                for (let key of DOCS_OBRIGATORIOS_POR_MINUTA[modeloTr] || []) {
                    mapa_docs[key] = mapa_docs[key] || []
                }
                set_docs(mapa_docs)
            })
    }

    useEffect(recarregar, [projeto.id, modeloTr])

    function envia_doc(local: string) {
        return (ev: ChangeEvent<HTMLInputElement>) => {
            const arq = ev.target.files?.[0]
            if (!arq) return
            const promessa_upload = ApiProjeto
                .upload_doc(projeto.id, local, arq.name, arq)
                .then(alertaCallback("Documento enviado com sucesso"))
                .catch(alertaCallback("Falha ao enviar o arquivo. Tente novamente mais tarde.", { severity: "error" }))
                .then(recarregar)
            blockUi(promessa_upload)
        }
    }

    if (DOCS_OBRIGATORIOS_POR_MINUTA[modeloTr]?.length === 0) {
        return <div>
            Esta modalidade não possui nenhum documento obrigatório
        </div>
    }

    return <>{
        sortBy(toPairs(docs), 0).map(([tipo_doc, docs]) => <Accordion key={tipo_doc} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMore />}>
                <Typography>
                    {tipo_doc}
                    &nbsp;
                    <span style={{ color: docs.length === 0 ? "red" : "green" }}>
                        ({docs.length})
                    </span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {docs.map(doc => <ExibeLinhaDocumento key={doc.nome} {...{ projeto, recarregar, doc, local: tipo_doc }} />)}
                <Button variant="outlined" component="label">
                    <FileUpload />
                    Enviar
                    <input type="file" hidden
                        accept="application/pdf"
                        onChange={envia_doc(tipo_doc)}
                    />
                </Button>
            </AccordionDetails>
        </Accordion>)
    }</>
}

export function ExibeDocsComplementares(props: { projeto: Projeto, local: 'etp' | 'tr', items_analise: ItemNotificacaoAnalise[] }) {
    const { projeto, local } = props
    const [docs, set_docs] = useState<DocumentoAnexo[]>([])

    const atualiza = () => {
        ApiProjeto
            .documentos(projeto.id)
            .then(ds => ds.filter(d => !d.obrigatorio && d.local === local))
            .then(set_docs)
    }

    useEffect(atualiza, [projeto, local])

    const novo_upload = (ev: ChangeEvent<HTMLInputElement>) => {
        const arquivo = ev.target.files?.[0]
        if (!arquivo) return;
        const promessa_upload = ApiProjeto
            .upload_doc_complementar(projeto.id, arquivo?.name, local, arquivo)
            .then(alertaCallback("Documento enviado com sucesso"))
            .catch(alertaCallback("Falha ao enviar o arquivo. Tente novamente mais tarde.", { severity: "error" }))
            .then(atualiza)
        blockUi(promessa_upload)
    }

    return <>
        {docs.map(doc => <ExibeLinhaDocumento key={doc.nome} recarregar={atualiza} {...{ doc, local, projeto }} />)}

        <Box margin={1}>
            <Button
                variant="outlined"
                startIcon={<FileUpload />}
                component="label">
                Enviar
                <input type="file" hidden
                    accept="application/pdf"
                    onChange={novo_upload}
                />
            </Button>
        </Box>
    </>
}
