import { Usuario } from "../entidades/usuario";
import { Historico } from "./processo.historico";

export type Timestamp = string;

export class Processo {
    id: number = 0;
    historico: Historico[] = [];
    equipe: Record<number, { cargo: string, area: string }> = {};
    dataCancelado?: Timestamp;

    // Adicionados pela BM6 (Tc Geisa)
    numProcesso?: string;
    numSolicitacao?: string;
}

export interface ItemEtpTR {
    projeto: number
    tipo: "etp" | "tr"
    nome: string
    conteudo: string
    visivel: boolean
    ordem: string
}

export interface Projeto {
    id: number
    processo: number
    cadastro: CadastroGeral
    etp: { [item: string]: ItemEtpTR }
    tr: { [item: string]: ItemEtpTR }
    documentos: DocumentoAnexo[]
}

export const ORGAOS_SOLICITANTES = [
    "CABIO",
    "CABS",
    "CBMMS",
    "CEP",
    "CEPCOM",
    "CGCMCP",
    "CGIR",
    "CGP",
    "CGPA",
    "CIOPS",
    "CTI",
    "DOF",
    "GGIFRON",
    "PCMS",
    "PMMS",
    "SAS",
    "SEJUSP",
    "SISP",
    "SPPAPSP",
];

export interface CadastroGeral {
    projeto: number
    orgaos: string[];
    aquisicaoContratacao: string;
    docOrigem?: string;
    nome_doc_origem?: string;
    modalidade: string;
    tipoDespesa: string;
    objeto: string;
    justificativa: string;
    valorEstimado: string;
    baseAnalise: string;
}

export interface DocumentoAnexo {
    projeto: number;
    obrigatorio: boolean;
    local: 'etp' | 'tr' | string;
    nome: string;
    arquivo: string;
}

export type EquipePlanejamento = Record<number, { usr: Usuario, cargo: string, area: string }>;
