import { Box, Button, Container, Grid, MenuItem, Paper, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableRow, Tabs } from "@mui/material";
import axios from "axios";
import _, { keys } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { alertaCallback, blockUi } from "../components/Alerta";
import { ApiItemDespachoConformidade, ItemDespachoConformidade } from "../entidades/item-despacho-conformidade";
import { ApiProcesso } from "../processo/processo.api";
import { ProcessoHelper } from "../processo/processo.helper";
import { useUsuarioLogado } from "../usuario-logado";

function ExibeItemDespachoConformidade(props: { item: ItemDespachoConformidade }) {
    const { item } = props

    const [valor, set_valor] = useState(item.valor)
    const [atualizando, set_atualizando] = useState(false)

    let color = '#686868'
    if (valor === 'S') {
        color = 'green'
    } else if (valor === 'N') {
        color = 'red'
    }

    const handle_update = (ev: SelectChangeEvent<"S" | "N" | "NA" | undefined>) => {
        const item_atualizado = {
            processo: item.processo,
            local: item.local,
            num_item: item.num_item,
            valor: ev.target.value as "S" | "N" | "NA" | undefined
        } as Omit<ItemDespachoConformidade, 'conteudo'>

        set_atualizando(true)
        ApiItemDespachoConformidade
            .atualiza(item_atualizado)
            .then(() => set_valor(ev.target.value as any))
            .finally(() => set_atualizando(false))
    }

    return <TableRow>
        <TableCell>
            {item.num_item}
        </TableCell>
        <TableCell>
            {item.conteudo}
        </TableCell>
        <TableCell sx={{
            backgroundColor: (!valor) && 'yellow' || undefined,
        }}>
            <Select
                size="small"
                sx={{ color }}
                value={valor}
                onChange={handle_update}
                disabled={atualizando}
            >
                <MenuItem>&nbsp;</MenuItem>
                <MenuItem value="S">Sim</MenuItem>
                <MenuItem value="N">Não</MenuItem>
                <MenuItem value="NA">N.A.</MenuItem>
            </Select>
        </TableCell>
    </TableRow>
}

function ConteudoETP(props: { projeto?: number }) {
    const { projeto } = props
    const [exibindo, set_exibindo] = useState<'etp' | 'tr'>()
    const [etp, set_etp] = useState<string>()
    const [tr, set_tr] = useState<string>()

    useEffect(() => {
        if (!projeto) return
        axios.get<string>(`/api/projeto/${projeto}/etp.html`)
            .then(resp => {
                set_etp(resp.data)
                if (!exibindo) {
                    set_exibindo('etp')
                }
            })
        axios.get<string>(`/api/projeto/${projeto}/tr.html`)
            .then(resp => {
                set_tr(resp.data)
                if (!exibindo) {
                    set_exibindo('tr')
                }
            })
    }, [projeto])

    return <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={exibindo} onChange={(_ev, v) => set_exibindo(v)}>
                {etp && <Tab value='etp' label="ETP" />}
                {tr && <Tab value='tr' label="TR" />}
            </Tabs>
        </Box>
        {exibindo === 'etp' && <div
            style={{ padding: 35, paddingTop: '0px', overflowY: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: etp || '' }}
        />}
        {exibindo === 'tr' && <div
            style={{ padding: 35, paddingTop: '0px', overflowY: 'scroll' }}
            dangerouslySetInnerHTML={{ __html: tr || '' }}
        />}
    </>
}

export function TelaDespachoDeConformidade() {
    const usr = useUsuarioLogado()
    const params = useParams<{ processo_id: string }>()
    const processo_id = +(params.processo_id || 0)
    const [processo_helper, set_processo_helper] = useState<ProcessoHelper>()

    const navigate = useNavigate()

    const [items, set_items] = useState<ItemDespachoConformidade[]>([])

    useEffect(() => {
        ApiItemDespachoConformidade.items_do_processo(processo_id).then(items => {
            set_items(items)
        })
        ApiProcesso.get(processo_id).then((p) => set_processo_helper(new ProcessoHelper(p)))
    }, [])

    const items_agrupados = _(items)
        .sortBy('num_item')
        .groupBy('local')
        .value()

    if (!usr) return <></>

    return <>
        <Container sx={{ mt: 1 }} maxWidth="xl">
            <h3>Despacho de Conformidade</h3>
            <Grid container spacing={1} direction="row">
                <Grid item xs={6}>
                    <Paper sx={{ maxHeight: "800px", display: 'flex', flexDirection: 'column' }}>
                        <ConteudoETP projeto={processo_helper?.ultimoProjeto().id} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{ overflowY: 'scroll', maxHeight: "800px" }}>
                        <Table size="small">
                            {keys(items_agrupados).map(key => <TableBody key={key}>
                                <TableRow sx={{ backgroundColor: 'whitesmoke' }}>
                                    <TableCell></TableCell>
                                    <TableCell><b>{key}</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                {items_agrupados[key].map((item, i) => (
                                    <ExibeItemDespachoConformidade key={i} item={item} />
                                ))}
                            </TableBody>)}
                        </Table>

                        <Box display='flex' flexDirection='row-reverse'>
                            <Button
                                sx={{ m: 1 }}
                                variant='outlined'
                                onClick={() => {
                                    blockUi(
                                        ApiProcesso
                                            .aprovar(processo_id, usr.id)
                                            .then(() => navigate("/compras/processos"))
                                            .then(alertaCallback("Projeto aprovado"))
                                            .catch(alertaCallback("Falha ao aprovar o projeto. Tente novamente mais tarde", { severity: "error" }))
                                    )
                                }}>
                                Aprovar Processo
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    </>
}
