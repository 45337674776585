import { ArrowDownward as ArrowDownwardIcon, ArrowUpward, CheckBox, CheckBoxOutlineBlank, FilterAlt as FilterIcon, List as ListIcon } from "@mui/icons-material";
import { AvatarGroup, Box, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, ListItemIcon, Menu, MenuItem, OutlinedInput, Pagination, SvgIcon, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { chunk, intersection, keys, uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { AvatarUsrsIds, avatarUsr } from "../components/AvatarUsr";
import { ListaProcessosAprovados } from "../components/ListaProcessosAprovados";
import { ListaProcessosCancelados } from "../components/ListaProcessosCancelados";
import { CaixaCinza, Titulo } from "../components/componentes";
import { ApiUsuario, Usuario } from "../entidades/usuario";
import { ORGAOS_SOLICITANTES, Processo } from "../processo/processo";
import { ApiProcesso } from "../processo/processo.api";
import { ProcessoHelper } from "../processo/processo.helper";
import { useUsuarioLogado } from "../usuario-logado";

const COLUNAS_PROCESSO = [
    '#',
    'Num Processo',
    'Início',
    'Órgão(s)',
    'Objeto',
    'Equipe',
    'Situação',
] as const;

function CelulaTabelaProcessos(props: {
    coluna: typeof COLUNAS_PROCESSO[number],
    processoH: ProcessoHelper,
    rts: Usuario[]
}) {
    const { coluna, processoH, rts } = props;

    const calculaStrTempoProcesso = () => {
        const { analise, elaboracao } = processoH.tempoProcesso()
        return `tempo em análise: ${analise}\ntempo em elaboração: ${elaboracao}`
    }

    const cadastro = processoH.cadastro();
    const objeto = cadastro?.objeto || '';

    switch (coluna) {
        case '#':
            return <TableCell className="textAlignRight">
                <Link to={`/compras/processo/${processoH.processo.id}`}>
                    <Typography>
                        {processoH.processo.id}
                    </Typography>
                </Link>
            </TableCell>
        case 'Num Processo':
            return <TableCell className="textAlignRight">
                {processoH.processo.numProcesso}
                <br />
                {processoH.processo.numSolicitacao}
            </TableCell>
        case 'Início':
            return <TableCell className="textAlignCenter">
                <Typography>
                    {format(parseISO(processoH.dataInicio()), 'dd/MM/yyyy')}
                    <br />
                    <Box mt={1}>
                        (<span style={{ color: 'red' }}>{processoH.dias_total()}</span> dias)
                    </Box>
                </Typography>
            </TableCell>
        case 'Órgão(s)':
            return <TableCell>
                <Typography>
                    {(cadastro?.orgaos || []).join(", ")}
                </Typography>
                <Box display='flex' flexGrow={1} mt={1}>
                    <Box flexGrow={1}>R$</Box>
                    <Box>{cadastro?.valorEstimado || <span style={{ color: 'gray' }}>0,00</span>}</Box>
                </Box>
            </TableCell>
        case 'Objeto':
            return <TableCell title={objeto}>
                {objeto.length > 150 ? objeto.substr(0, 150) + '...' : objeto}
            </TableCell>
        case 'Equipe':
            return <TableCell>
                <AvatarGroup title={rts.map(r => r.nome).join('\u000A')} max={3}>
                    {rts.map(avatarUsr)}
                </AvatarGroup>
                <AvatarUsrsIds ids={processoH.analistas()} />
            </TableCell>
        case 'Situação':
            return <TableCell>
                <Box flexGrow={1}>
                    {processoH.textoSituacao() + ' há'}&nbsp;
                    <span style={{ color: processoH.diasUltimaAtualizacao() >= 15 ? "red" : "green", fontWeight: "bold" }}>{`${processoH.diasUltimaAtualizacao()}`.padStart(2, '0')}</span> dias
                </Box>
                <Box flexDirection="row" display="flex">
                    {processoH.equipe_atual() === "planejamento" &&
                        <IconButton
                            size="small" sx={{ color: 'green' }}
                            title="Está com a equipe de planejamento"
                        >
                            P
                        </IconButton>
                    }
                    {processoH.equipe_atual() === "análise" &&
                        <IconButton
                            size="small" sx={{ color: 'blue' }}
                            title="Está com a equipe de análise"
                        >
                            A
                        </IconButton>
                    }
                    <Box
                        alignItems="center"
                        display="flex"
                        textAlign="justify"
                        title={calculaStrTempoProcesso()}
                        justifyContent="center">
                        {processoH.num_apresentacoes()}
                    </Box>
                    <Link to={`/compras/historico/processo/${processoH.processo.id}`} title="Histórico">
                        <IconButton size="small">
                            <ListIcon color="primary" />
                        </IconButton>
                    </Link>
                </Box>
            </TableCell >
    }
}

const comparaDataInicio = ProcessoHelper.comparaDataInicio
const comparaValorEstimado = ProcessoHelper.comparaValorEstimado
const comparaDataAtualizacao = ProcessoHelper.comparaDataAtualizacao
const comparaDataInicioReverso = (p1: Processo, p2: Processo) => comparaDataInicio(p2, p1)
const comparaValorEstimadoReverso = (p1: Processo, p2: Processo) => comparaValorEstimado(p2, p1)
const comparaDataAtualizacaoReverso = (p1: Processo, p2: Processo) => comparaDataAtualizacao(p2, p1)

function ExibeLinhaProcesso(props: { processo: Processo, colunas: typeof COLUNAS_PROCESSO[number][] }) {
    const { processo, colunas } = props;
    const processoHelper = new ProcessoHelper(processo);
    const [equipe, setEquipe] = useState<Usuario[]>([]);
    useEffect(() => {
        ApiUsuario.getList(keys(processo.equipe)).then(setEquipe)
    }, [processo.equipe])

    return (
        <TableRow key={processo.id}>
            {colunas.map(col => <CelulaTabelaProcessos
                key={col}
                coluna={col}
                processoH={processoHelper}
                rts={equipe}
            />)}
        </TableRow>
    )
}

const IconExcelSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="24" height="24"
        viewBox="0 0 24 24">
        <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z">
        </path>
    </svg>
)

function PainelMediaDeTempo(props: { processos: Processo[] }) {
    const { processos } = props
    const dados = useMemo(() => {
        const tempos = processos.map(p => new ProcessoHelper(p)).map(h => h.tempoProcesso())
        // Média dos tempos de análise e elaboração
        let analise = 0
        let elaboracao = 0
        for (const t of tempos) {
            analise += t.analise
            elaboracao += t.elaboracao
        }
        analise = analise / tempos.length
        elaboracao = elaboracao / tempos.length
        return {
            labels: [
                `Elaboração`,
                `Análise`,
            ],
            datasets: [{
                data: [
                    elaboracao,
                    analise,
                ],
                borderWidth: 1,
                backgroundColor: [
                    'green',
                    'blue',
                ],
            }],
        }
    }, [processos]);

    return <CaixaCinza>
        <Doughnut data={dados} />
        <div style={{ textAlign: 'center' }}>
            Média de tempo dos processos (dias)
        </div>
    </CaixaCinza>
}

export function ListaProcessos(props: {
    titulo: string,
    processos?: Processo[],
    colunas: typeof COLUNAS_PROCESSO[number][]
}) {
    const { colunas } = props
    const usr = useUsuarioLogado()
    const orgaos_do_rt = usr?.permissoes.filter(p => p.permissao === 'equipe-planejamento').map(p => p.orgao) || []
    const [filtroProjetos, setFiltroProjetos] = useState<'todos' | 'usr-logado' | 'aprovados' | 'cancelados'>('todos')
    const [pag, set_pag] = useState(1)
    const [func_ordenacao, set_func_ordenacao] = useState(() => ProcessoHelper.comparaDataAtualizacao)
    const [filtro_objeto, set_filtro_objeto] = useState('')
    const [ancora_menu_orgaos, set_ancora_menu_orgaos] = useState<null | HTMLElement>(null);
    const [filtro_orgaos, set_filtro_orgaos] = useState([...ORGAOS_SOLICITANTES])
    const [filtro_id, set_filtro_id] = useState('')
    const [filtro_data_inicio, set_filtro_data_inicio] = useState('')
    const [filtro_equipe, set_filtro_equipe] = useState('')
    const [filtro_analista, set_filtro_analista] = useState('')
    const [filtro_situacao, set_filtro_situacao] = useState('')
    const [filtro_apresentacoes, set_filtro_apresentacoes] = useState('')
    const [filtro_num_processo, set_filtro_num_processo] = useState('')
    const [filtro_num_solicitacao, set_filtro_num_solicitacao] = useState('')

    const [usrs_equipe, set_usrs_equipe] = useState<Usuario[]>([])
    const [usrs_analistas, set_usrs_analistas] = useState<Usuario[]>([])

    const handle_toggle_filtro_orgao = (orgao: string) => {
        const i = filtro_orgaos.findIndex(o => o === orgao)
        if (i === -1) {
            set_filtro_orgaos([...filtro_orgaos, orgao])
        } else {
            set_filtro_orgaos(filtro_orgaos.filter(o => o !== orgao))
        }
    }

    useEffect(() => {
        if (!props.processos) return;

        // ids unicos dos membros da equipe
        const ids_equipe = uniq(props.processos.map(p => keys(p.equipe)).flat())

        ApiUsuario.getList(ids_equipe).then(set_usrs_equipe)

        // ids unicos dos analistas
        const ids_analistas = uniq(props.processos.map(p => (new ProcessoHelper(p)).analistas()).flat())
        ApiUsuario.getList(ids_analistas).then(set_usrs_analistas)
    }, [props.processos])

    let processos = props.processos || [];

    if (!usr) {
        processos = []
    } else {
        if (filtroProjetos === 'usr-logado') {
            processos = processos.filter(p => {
                const helper = new ProcessoHelper(p)

                return keys(p.equipe).includes(usr.id + '') || helper.analistas().includes(usr.id)
            })
        }

        if (filtroProjetos === 'aprovados') {
            processos = processos.filter(p => (new ProcessoHelper(p)).estaAprovado())
            // Na tela de aprovados devem ser exibidos processos de todos os órgãos
        } else {
            processos = processos.filter(p => !(new ProcessoHelper(p)).estaAprovado())

            // Só filtra por orgão se o usuário não for analista
            if (!usr.permissoes.map(p => p.permissao).includes("analista-planejamento")) {
                // Usuário é RT
                processos = processos.filter(p => {
                    const orgaos_do_processo = (new ProcessoHelper(p)).ultimoProjeto()?.cadastro?.orgaos || []
                    return intersection(orgaos_do_processo, orgaos_do_rt).length > 0
                })
            } else {
                // Usuário é analista
                // Se o processo ainda for um rascunho, não deve ser exibido para os analistas
                // a menos que ele também esteja no time de planejamento
                processos = processos.filter(p => {
                    const helper = new ProcessoHelper(p)
                    const rascunho = helper.rascunho()
                    return keys(p.equipe).includes(usr.id + '') || !rascunho
                })
            }
        }

        // Filtro data início format(parseISO(processoH.dataInicio()), 'dd/MM/yyyy')
        processos = processos.filter(p => format(parseISO((new ProcessoHelper(p)).dataInicio()), 'dd/MM/yyyy').includes(filtro_data_inicio))
        // Filtro orgãos
        processos = processos.filter(p => intersection((new ProcessoHelper(p)).cadastro().orgaos, filtro_orgaos).length > 0)
        // Filtro objeto
        processos = processos.filter(p => (new ProcessoHelper(p)).cadastro().objeto.toLocaleLowerCase().includes(filtro_objeto.toLocaleLowerCase()))
        // Filtro id
        if (filtro_id && filtro_id.length > 0) {
            const ids_filtro = filtro_id.split(',').map(id => +id.trim())
            processos = processos.filter(p => ids_filtro.includes(p.id))
        }
        // Filtro equipe
        processos = processos.filter(p => {
            const ids_equipe = keys(p.equipe)
            const nomes_equipe = usrs_equipe.filter(u => ids_equipe.includes(u.id + '')).map(u => u.nome)
            return nomes_equipe.join(',').toLocaleLowerCase().includes(filtro_equipe.toLocaleLowerCase())
        })
        // Filtro analista
        processos = processos.filter(p => {
            const analistas = (new ProcessoHelper(p)).analistas()
            const nomes_analistas = usrs_analistas.filter(u => analistas.includes(u.id)).map(u => u.nome)
            return nomes_analistas.join(',').toLocaleLowerCase().includes(filtro_analista.toLocaleLowerCase())
        })
        // Filtro situação
        processos = processos.filter(p => {
            const helper = new ProcessoHelper(p)
            const situacao = helper.textoSituacao() + helper.diasUltimaAtualizacao()
            return situacao.toLocaleLowerCase().includes(filtro_situacao.toLocaleLowerCase())
        })
        // Filtro apresentações
        processos = processos.filter(p => {
            const helper = new ProcessoHelper(p)
            const apresentacoes = helper.num_apresentacoes()
            return apresentacoes.toString().includes(filtro_apresentacoes)
        })
        // Filtro num processo
        processos = processos.filter(p => {
            const num_processo = p.numProcesso
            return !num_processo || num_processo.includes(filtro_num_processo)
        })
        // Filtro num solicitação
        processos = processos.filter(p => {
            const num_solicitacao = p.numSolicitacao
            return !num_solicitacao || num_solicitacao.includes(filtro_num_solicitacao)
        })

        processos.sort(func_ordenacao)
    }

    const items_por_pag = 20
    const paginas = chunk(processos, items_por_pag)

    return <Grid container spacing={2} p={3}>
        <Grid item xs={0} xl={1} />
        <Grid item xs={12} xl={9}>
            <CaixaCinza>
                <Tabs value={filtroProjetos} onChange={(_ev, v) => setFiltroProjetos(v)}
                    sx={{ borderBottom: "1px lightgray solid" }}>
                    <Tab value="todos" label={`Todos os processos`} />
                    <Tab value="usr-logado" label={"Apenas os meus processos"} />
                    <Tab value="aprovados" label={"Aprovados"} />
                    <Tab value="cancelados" label={"Cancelados"} />
                    <div style={{ alignItems: 'center', display: 'flex', flexGrow: 1, flexDirection: 'row-reverse', paddingRight: 15 }}>
                        <span>({processos.length})</span>
                    </div>
                </Tabs>
                {false && <ListaProcessosAprovados />}
                {filtroProjetos === 'cancelados' && <ListaProcessosCancelados />}
                {filtroProjetos !== 'cancelados' && <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ pr: 0 }}>
                                    <TextField
                                        size="small"
                                        label="#"
                                        sx={{ minWidth: '40px', maxWidth: '100px' }}
                                        value={filtro_id}
                                        onChange={ev => set_filtro_id(ev.target.value)}
                                    />
                                </TableCell>
                                <TableCell sx={{ pr: 0 }}>
                                    <TextField
                                        size="small"
                                        label="Processo"
                                        value={filtro_num_processo}
                                        onChange={ev => set_filtro_num_processo(ev.target.value)}
                                    />
                                    <TextField
                                        margin="dense"
                                        size="small"
                                        label="Nº Solicitação de Compra"
                                        value={filtro_num_solicitacao}
                                        onChange={ev => set_filtro_num_solicitacao(ev.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl size="small">
                                        <InputLabel htmlFor="outlined-adornment-amount">Início</InputLabel>
                                        <OutlinedInput
                                            value={filtro_data_inicio}
                                            onChange={ev => set_filtro_data_inicio(ev.target.value)}
                                            endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => set_func_ordenacao(() => comparaDataInicio)}
                                                    disabled={func_ordenacao === comparaDataInicio}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => set_func_ordenacao(() => comparaDataInicioReverso)}
                                                    disabled={func_ordenacao === comparaDataInicioReverso}
                                                    color="primary"
                                                    size="small"
                                                >
                                                    <ArrowUpward />
                                                </IconButton>
                                            </InputAdornment>}
                                            label="Início"
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                        Órgão(s)
                                        <IconButton color="primary" size="small"
                                            onClick={ev => set_ancora_menu_orgaos(ev.currentTarget)}>
                                            <FilterIcon />
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={ancora_menu_orgaos}
                                            open={!!ancora_menu_orgaos}
                                            onClose={() => set_ancora_menu_orgaos(null)}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => set_filtro_orgaos([...ORGAOS_SOLICITANTES])}>
                                                Marcar todos
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => set_filtro_orgaos([])}>
                                                Desmarcar todos
                                            </MenuItem>
                                            <Divider />
                                            {ORGAOS_SOLICITANTES.map(orgao => <MenuItem key={orgao}
                                                onClick={() => handle_toggle_filtro_orgao(orgao)}>
                                                <ListItemIcon>
                                                    {!filtro_orgaos.includes(orgao) && <CheckBoxOutlineBlank
                                                        fontSize="small"
                                                    />}
                                                    {filtro_orgaos.includes(orgao) && <CheckBox
                                                        fontSize="small"
                                                        onClick={() => handle_toggle_filtro_orgao(orgao)}
                                                        color="primary"
                                                    />}
                                                </ListItemIcon>
                                                {orgao}
                                            </MenuItem>)}
                                        </Menu>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                        Valor
                                        <IconButton
                                            onClick={() => set_func_ordenacao(() => comparaValorEstimado)}
                                            disabled={func_ordenacao === comparaValorEstimado}
                                            color="primary"
                                            size="small"
                                        >
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => set_func_ordenacao(() => comparaValorEstimadoReverso)}
                                            disabled={func_ordenacao === comparaValorEstimadoReverso}
                                            color="primary"
                                            size="small"
                                        >
                                            <ArrowUpward />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        label="Objeto"
                                        value={filtro_objeto}
                                        onChange={ev => set_filtro_objeto(ev.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        label="Equipe de Planejamento"
                                        value={filtro_equipe}
                                        onChange={ev => set_filtro_equipe(ev.target.value)}
                                    />
                                    <TextField
                                        margin="dense"
                                        size="small"
                                        label="Analista"
                                        value={filtro_analista}
                                        onChange={ev => set_filtro_analista(ev.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl size="small">
                                            <InputLabel htmlFor="outlined-adornment-amount">Situação</InputLabel>
                                            <OutlinedInput
                                                size="small"
                                                label="Situação"
                                                value={filtro_situacao}
                                                onChange={ev => set_filtro_situacao(ev.target.value)}
                                                endAdornment={<InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => set_func_ordenacao(() => comparaDataAtualizacao)}
                                                        disabled={func_ordenacao === comparaDataAtualizacao}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => set_func_ordenacao(() => comparaDataAtualizacaoReverso)}
                                                        disabled={func_ordenacao === comparaDataAtualizacaoReverso}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        <ArrowUpward />
                                                    </IconButton>
                                                </InputAdornment>}
                                            />
                                        </FormControl>
                                    </Box>
                                    <TextField
                                        margin="dense"
                                        size="small"
                                        label="Apresentações"
                                        value={filtro_apresentacoes}
                                        onChange={ev => set_filtro_apresentacoes(ev.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ backgroundColor: "white" }}>
                            {!props.processos &&
                                <TableRow>
                                    <TableCell
                                        colSpan={colunas.length}
                                        padding="none"
                                    >
                                        <LinearProgress />
                                    </TableCell>
                                </TableRow>
                            }
                            {(paginas[Math.min(pag - 1, paginas.length)] || []).map(p => <ExibeLinhaProcesso key={p.id} processo={p} colunas={colunas} />)}
                        </TableBody>
                    </Table>
                    <Box display="flex" flexGrow={1} justifyContent="center">
                        <Pagination
                            count={paginas.length}
                            variant="outlined"
                            color="primary"
                            sx={{ margin: 1 }}
                            page={pag}
                            onChange={(_ev, p) => set_pag(p)}
                        />
                    </Box>
                </>}
            </CaixaCinza>
        </Grid>
        <Grid item xs={2}>
            {<PainelMediaDeTempo processos={processos} />}
        </Grid>
    </Grid >
}

export function TelaListaProcessos() {
    const [processos, setProcessos] = useState<Processo[]>()
    useEffect(() => { ApiProcesso.getAll().then(setProcessos) }, [])
    const [colunas, set_colunas] = useState<typeof COLUNAS_PROCESSO[number][]>([...COLUNAS_PROCESSO]);

    return (<>
        <Titulo
            titulo="Lista de Processos"
            itemApos={
                <IconButton title="Baixar relatório" href="/api/relatorios/relatorio.xlsx">
                    <SvgIcon color="success">
                        {IconExcelSVG}
                    </SvgIcon>
                </IconButton>
            }
        />
        <ListaProcessos
            titulo="Processos"
            processos={processos}
            colunas={COLUNAS_PROCESSO.filter(col => colunas.includes(col))} />
    </>)
}
