import { Badge, Container, Grid, Link as LinkMaterial, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ArcElement, Chart as ChartJS, Legend, Tooltip, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import { format } from "date-fns"
import { keys } from "lodash"
import { useEffect, useState } from "react"
import { Doughnut, Bar } from 'react-chartjs-2'
import { Link, useParams } from "react-router-dom"
import { AvatarUsrId, AvatarUsrsIds } from "../components/AvatarUsr"
import { CaixaCinza, Titulo } from "../components/componentes"
import { ApiAnalise, ItemNotificacaoAnalise } from "../processo/analise"
import { Processo } from "../processo/processo"
import { ApiProcesso } from "../processo/processo.api"
import { ProcessoHelper } from "../processo/processo.helper"
import { Historico, HistoricoAnaliseAtribuidaParaUsuario, HistoricoProjetoNotificado } from "../processo/processo.historico"

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Title,
    Tooltip,
    Legend);

function isoToDataPt(iso: string, opts?: { mostrar_hora?: boolean }) {
    const { mostrar_hora } = opts || {
        mostrar_hora: true
    }
    return format(new Date(iso), 'dd/MM/yyyy' + (mostrar_hora ? ' - HH:mm' : ''))
}

export function TelaHistoricoProcesso() {
    const { id } = useParams<{ id: string }>()
    const [processo, set_processo_state] = useState<Processo>()
    const [hist, set_hist] = useState<Historico[]>([])
    const elaboracoes = hist.map(h => (h as any).projeto?.id).filter(id => id) as number[]
    const processoHelper = processo && new ProcessoHelper(processo)
    const [items_notificacao_por_projeto, set_items_notificacao_por_projeto] = useState<Record<number, ItemNotificacaoAnalise[]>>({})

    function set_processo(p: Processo) {
        set_hist(p.historico)
        set_processo_state(p)
    }

    useEffect(() => {
        ApiProcesso
            .get(+(id || 0))
            .then(set_processo)

        ApiAnalise
            .get_items_do_processo(id)
            .then(set_items_notificacao_por_projeto)
    }, [id])

    function LinhaHistorico(props: { h: Historico, indice: number }) {
        const { h, indice } = props
        let dist = 0;
        if (h.tipo === 'enviado-para-analise') {
            let i = indice
            while (i > 0 && hist[i].tipo !== 'projeto-notificado') {
                i--
            }
            const ultimaDataRelevante = hist[i].data
            const d1 = new Date(h.data)
            const d2 = new Date(ultimaDataRelevante)
            dist = Math.ceil((d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24))
        }
        if (h.tipo === 'projeto-notificado') {
            let i = indice
            while (i > 0 && hist[i].tipo !== 'enviado-para-analise') {
                i--
            }
            const ultimaDataRelevante = hist[i].data
            const d1 = new Date(h.data)
            const d2 = new Date(ultimaDataRelevante)
            dist = Math.ceil((d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24))
        }
        return <>
            <TableRow>
                <TableCell style={{ width: '0.1%', whiteSpace: 'nowrap' }}>{isoToDataPt(h.data)}</TableCell>
                <TableCell style={{ width: '0.1%', whiteSpace: 'nowrap' }}>
                    <AvatarUsrId usr={h.autor} />
                </TableCell>
                <TableCell>{h.descricao}</TableCell>
                <TableCell>
                    {h.tipo === 'enviado-para-analise' && <LinkMaterial component={Link} to={`/compras/processo/${processo?.id}/projeto/${elaboracoes[h.numApresentacao - 1]}`}>
                        {h.numApresentacao}ª Apresentação
                    </LinkMaterial>}
                    {h.tipo === 'projeto-notificado' && <LinkMaterial component={Link} to={"/compras/notificacao/" + h.projeto.id}>
                        <Badge badgeContent={items_notificacao_por_projeto[h.projeto.id]?.length} color="primary">Notificação</Badge>
                    </LinkMaterial>}
                </TableCell>
            </TableRow>
            {h.tipo === 'enviado-para-analise' && <tr style={{ backgroundColor: 'whitesmoke' }}>
                <td /><td colSpan={3}>O projeto ficou em <b style={{ color: 'green' }}>ELABORAÇÃO</b> por <b style={{ color: 'green' }}>{dist}</b> dias</td>
            </tr>}
            {h.tipo === 'projeto-notificado' && <tr style={{ backgroundColor: 'whitesmoke' }}>
                <td /><td colSpan={3}>O projeto ficou em <b style={{ color: 'blue' }}>ANÁLISE</b> por <b style={{ color: 'blue' }}>{dist}</b> dias</td>
            </tr>}
        </>
    }
    const tempo = processoHelper?.tempoProcesso()
    const dados_tempo_processo = {
        labels: [
            `${tempo?.elaboracao} dias em elaboração`,
            `${tempo?.analise} dias em análise`,
        ],
        datasets: [{
            label: 'dias: ',
            data: [
                tempo?.elaboracao,
                tempo?.analise,
            ],
            borderWidth: 1,
            backgroundColor: [
                'green',
                'blue',
            ],
        }],
    }

    const dados_items_por_apresentacao = {
        labels: (hist || []).filter(h => h.tipo === 'projeto-notificado').map(h => isoToDataPt(h.data, { mostrar_hora: false })),
        datasets: [{
            label: 'Items de não Conformidade por Notificação',
            data: (hist || []).filter(h => h.tipo === 'projeto-notificado').map(h => items_notificacao_por_projeto[(h as HistoricoProjetoNotificado)?.projeto?.id]?.length || 0),
            backgroundColor: 'blue',
        }]
    }

    const analises_atribuidas = (processo?.historico || []).filter(h => h.tipo === 'analise-atribuida') as HistoricoAnaliseAtribuidaParaUsuario[]
    const analistas = analises_atribuidas.map(h => h.analista)

    return <>
        <Container>
            <Grid container spacing={2} maxWidth="lg">
                <Grid item xs={12}>
                    <Titulo titulo={`Processo nº${id}`} comBotaoVoltar />
                </Grid>
                <Grid item xs={12}>
                    <CaixaCinza>
                        <Container sx={{ padding: 1 }}>
                            <Grid container spacing={1} maxWidth="lg">
                                <Grid item xs={4}>
                                    <p>
                                        <b>Objeto:</b> {processoHelper?.cadastro()?.objeto || ''}
                                    </p>
                                    <p>
                                        <b>Valor estimado:</b> R$ {processoHelper?.cadastro()?.valorEstimado || ''}
                                    </p>
                                    <p>
                                        <b>Órgaos demandantes:</b> {processoHelper?.cadastro()?.orgaos.join(', ') || ''}
                                    </p>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <Bar data={dados_items_por_apresentacao} />
                                </Grid>
                            </Grid>
                        </Container>
                    </CaixaCinza>
                </Grid>
                <Grid item xs={10}>
                    <CaixaCinza>
                        <Table size="small">
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                {hist.map((h, i) => <LinhaHistorico key={h.id} h={h} indice={i} />)}
                                {processoHelper?.estaEmEdicao() && <tr style={{ backgroundColor: 'lightgray', marginTop: '1px solid' }}>
                                    <td /><td colSpan={3}>O projeto está em <b style={{ color: 'red' }}>ELABORAÇÃO</b> há <b style={{ color: 'red' }}>{processoHelper?.diasUltimaAtualizacao()}</b> dias</td>
                                </tr>}
                                {(processoHelper?.estaEmAnalise() || processoHelper?.estaAguardandoAnalise()) && <tr style={{ backgroundColor: 'lightgray', marginTop: '1px solid' }}>
                                    <td /><td colSpan={3}>O projeto está em <b style={{ color: 'red' }}>ANÁLISE</b> há <b style={{ color: 'red' }}>{processoHelper?.diasUltimaAtualizacao()}</b> dias</td>
                                </tr>}
                            </TableBody>
                        </Table>
                    </CaixaCinza>
                </Grid>
                <Grid item xs={2}>
                    <CaixaCinza>
                        <Doughnut data={dados_tempo_processo} />
                        <div style={{ textAlign: 'center' }}>
                            Tempo do Processo
                        </div>
                    </CaixaCinza>
                    <br />
                    <CaixaCinza>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <AvatarUsrsIds ids={keys(processo?.equipe || {}).map(id => +id)} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            Equipe de Planejamento
                        </div>
                    </CaixaCinza>
                    <br />
                    <CaixaCinza>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <AvatarUsrsIds ids={analistas} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            Analistas
                        </div>
                    </CaixaCinza>
                </Grid>
            </Grid>
        </Container>
    </>
}
