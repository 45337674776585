import axios from "axios";
import { Usuario } from "../entidades/usuario";
import { ItemNotificacaoAnalise } from "./analise";
import { CadastroGeral, EquipePlanejamento, Processo } from "./processo";

export module ApiProcesso {

    export async function get(id: number): Promise<Processo> {
        return axios.get<Processo>('/api/processo/' + id)
            .then((response) => response.data)
        /*.catch((error) => {
            console.log(error)
            return undefined
        })*/
    }

    export async function getAll(): Promise<Processo[]> {
        return axios.get<Processo[]>('/api/processo/')
            .then((response) => response.data)
        /*.catch((error) => {
            console.log(error)
            return []
        })*/
    }

    export async function criar(autor: number, cadastro: Partial<CadastroGeral>): Promise<Processo> {
        return axios.post<Processo>('/api/processo/', { autor, cadastro })
            .then((response) => response.data)
        /*.catch((error) => {
            console.log(error)
            return undefined
        })*/
    }

    export async function get_do_projeto(id_projeto: number): Promise<Processo> {
        return axios
            .get<Processo>(`/api/processo/do-projeto/${id_projeto}`)
            .then((response) => response.data)
    }

    // Retorna a lista atualizada da equipe do processo
    export async function adicionar_colaborador(processo: number, usr: number) {
        return axios.post<Usuario[]>('/api/processo/adicionar-colaborador', { processo, usr }).then(r => r.data)
    }

    // Retorna a lista atualizada da equipe do processo
    export async function remover_colaborador(processo: number, usr: number) {
        return axios.post<Usuario[]>('/api/processo/remover-colaborador', { processo, usr }).then(r => r.data)
    }

    export async function enviarParaAnalise(processo: number, autor: number) {
        return axios.post<Processo>('/api/processo/enviar-para-analise', { processo, autor }).then(r => r.data)
    }

    export async function atribuirAnalise(processo: number, analista: number, autor?: number) {
        autor = autor || analista
        return axios.post<Processo>('/api/processo/atribuir-analise', { processo, analista, autor }).then(r => r.data)
    }

    export async function notificar(processo: number, autor: number) {
        return axios.post<Processo>('/api/processo/notificar', { processo, autor }).then(r => r.data)
    }

    export async function aprovar(processo: number, autor: number) {
        return axios.post<Processo>('/api/processo/aprovar', { processo, autor }).then(r => r.data)
    }

    export async function equipe_do_processo(processo: number): Promise<EquipePlanejamento> {
        return axios.get(`/api/processo/${processo}/equipe`).then(r => r.data)
    }

    export async function update_equipe(processo: number, usr: number, body: {cargo?: string, area?: string}): Promise<EquipePlanejamento> {
        return axios.post(`/api/processo/${processo}/equipe/${usr}`, body).then(r => r.data)
    }

    export async function cancelar(processo: number): Promise<ItemNotificacaoAnalise[]> {
        return axios.post(`/api/processo/${processo}/cancelar`).then(r => r.data)
    }

    export async function salvarNumProcesso(processo: number, numProcesso: string = '', numSolicitacao: string = ''): Promise<Processo> {
        return axios.post(`/api/processo/${processo}/salvar-num-processo`, {numProcesso, numSolicitacao}).then(r => r.data)
    }
}
