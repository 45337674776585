import { ThemeProvider } from '@emotion/react';
import { createTheme, StyledEngineProvider } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Alerta } from './components/Alerta';
import { AppHeader } from './components/AppHeader';
import './index.scss';
import { TelaAdminUsuario } from './sistemas/intranet/AdminUsuario';
import { MapaOperacional } from './sistemas/MapaOperacional';
import { TelaEditaModelo } from './telas/admin/TelaEditaModeloTR';
import TelaAlterarSenha from './telas/TelaAlterarSenha';
import { TelaAnalise } from './telas/TelaAnalise';
import { TelaCriaProcesso } from './telas/TelaCriaProcesso';
import { TelaDespachoDeConformidade } from './telas/TelaDespachoDeConformidade';
import { TelaExibeProcesso } from './telas/TelaExibeProcesso/TelaExibeProcesso';
import { TelaHistoricoProcesso } from './telas/TelaHistoricoProcesso';
import { TelaInicial } from './telas/TelaInicial';
import { TelaLegislacao } from './telas/TelaLegislacao';
import { TelaListaProcessos } from './telas/TelaListaProcessos';
import { TelaLogin } from './telas/TelaLogin';
import { TelaMaterialDeApoio } from './telas/TelaMaterialDeApoio';
import { TelaNotificacaoAnalise } from './telas/TelaNotificacaoAnalise';
import { TelaPaineis } from './telas/TelaPaineis';
import { TelaVideosTutoriais } from './telas/TelaVideosTutoriais';
import { useUsuarioLogado } from './usuario-logado';
import { TelaEditaNumProcesso } from './telas/TelaEditaNumProcesso';

const temaRT = createTheme({
    palette: {
        primary: {
            main: blue[800]
        },
        secondary: {
            main: grey[900]
        }
    },
})

const temaAnalista = createTheme({
    palette: {
        primary: {
            main: blue[800]
        },
        secondary: {
            //main: grey[800],
            main: blue[900]
        }
    },
})

function App() {
    const usr = useUsuarioLogado()
    const analista = !!usr?.permissoes.find(p => p.permissao === "analista")
    const tema = analista ? temaAnalista : temaRT

    return <BrowserRouter>
        <ThemeProvider theme={tema}>
            <StyledEngineProvider injectFirst>
                <Alerta />
                <AppHeader />
                <div style={{ flexGrow: 1 }}>
                    <TelaLogin>
                        <Routes>
                            <Route path="/" element={<TelaInicial />} />
                            <Route path="/compras" element={<TelaInicial />} />
                            <Route path="/compras/bi" element={<TelaPaineis />} />
                            <Route path="/compras/legislacao" element={<TelaLegislacao />} />
                            <Route path="/compras/material-de-apoio" element={<TelaMaterialDeApoio />} />
                            <Route path="/compras/processos" element={<TelaListaProcessos />} />
                            <Route path="/compras/processo/:id" element={<TelaExibeProcesso />} />
                            <Route path="/compras/processo/:id/projeto/:projeto_id" element={<TelaExibeProcesso />} />
                            <Route path="/compras/analise/:idProjeto" element={<TelaAnalise />} />
                            <Route path="/compras/processo/:processo_id/despacho-de-conformidade" element={<TelaDespachoDeConformidade />} />
                            <Route path="/compras/historico/processo/:id" element={<TelaHistoricoProcesso />} />
                            <Route path="/compras/notificacao/:id" element={<TelaNotificacaoAnalise />} />
                            <Route path="/compras/criar-processo" element={<TelaCriaProcesso />} />
                            <Route path="/compras/tutoriais" element={<TelaVideosTutoriais />} />
                            <Route path="/compras/edita-item-tr-base" element={<TelaEditaModelo />} />
                            <Route path="/mapa-operacional" element={<MapaOperacional />} />
                            <Route path="/intranet/admin-usuarios" element={<TelaAdminUsuario />} />
                            <Route path="/alterar-senha" element={<TelaAlterarSenha />} />
                            <Route path="/compras/administracao/num-solicitacao" element={<TelaEditaNumProcesso />} />
                        </Routes>
                    </TelaLogin>
                </div>
                {/*<AppFooter />*/}
            </StyledEngineProvider>
        </ThemeProvider>
    </BrowserRouter>
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
