import AppsIcon from '@mui/icons-material/Apps';
import { AppBar, Avatar, Container, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Toolbar, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUsuario, Usuario } from '../entidades/usuario';
import { setUsuarioLogado, useUsuarioLogado, UsuarioLogado } from "../usuario-logado";

function ShowUsr(props: { usr?: UsuarioLogado | null }) {
    const { usr } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [listaUsrs, setListaUsrs] = useState<Usuario[]>([]);
    const navigate = useNavigate()

    useEffect(() => {
        ApiUsuario.getList([
            1,
            2,
            120, // Tracz
            297,
            457,
            4467,
            7158, // Tc Kleber
            12822,
            466491158,
            2262071101,
            15751547810, // Dra Glória Suzuki
        ]).then(setListaUsrs)
    }, [])

    const permissoes = _(usr?.permissoes)
        .map(p => p.permissao)
        .uniq()
        .filter(p => p === 'equipe-planejamento' || p === 'analista-planejamento')
        .map(p => p === 'equipe-planejamento' ? 'responsável técnico' : p)
        .map(p => p === 'analista-planejamento' ? 'analista' : p)
        .join(', ')

    const usrTemPermissaoAdmin = usr?.permissoes.some(p => p.permissao === 'admin')

    const orgaos_com_permissao_equipe_planejamento = (usr?.permissoes.filter(p => p.permissao === 'equipe-planejamento').map(p => p.orgao))

    if (usr) {
        return (<>
            <Typography>
                {usr.nome} <span style={{ fontSize: 'small', color: 'gray' }}>({permissoes})</span>
            </Typography>
            <IconButton color="inherit" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            }}>
                <Avatar
                    alt={usr.nome}
                    src={usr.foto && "https://sistemas.bombeiros.ms.gov.br/ws-alfresco/arquivo/" + usr.foto} />
            </IconButton>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Responsável técnico dos órgãos:"
                            secondary={orgaos_com_permissao_equipe_planejamento?.join(', ')}
                        />
                    </ListItem>
                    <Divider />
                    <ListItemButton onClick={() => navigate("/")}>
                        Intranet
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/compras")}>
                        Compras
                    </ListItemButton>
                    {process.env.NODE_ENV === 'development' && <>
                        <Divider />
                        <ListItem>
                            <b>Impersonar usuário:</b>
                        </ListItem>
                        {listaUsrs.map(u => <ListItemButton key={u.id} onClick={() => setUsuarioLogado(u)}>
                            {u.nome} {u.id}
                        </ListItemButton>)}
                    </>}
                    <Divider />
                    {usrTemPermissaoAdmin && <>
                        <ListItemButton onClick={() => navigate("/intranet/admin-usuarios")}>
                            Administrar Usuários
                        </ListItemButton>
                    </>}
                    <ListItemButton onClick={() => navigate("/alterar-senha")}>
                        Alterar senha
                    </ListItemButton>
                    <ListItemButton onClick={() => setUsuarioLogado(undefined)}>
                        Sair
                    </ListItemButton>
                </List>
            </Popover>
        </>)
    } else if (usr === undefined) {
        return <span></span>
    } else {
        return <span>Logar</span>
    }
}

export function AppHeader() {
    const usrLogado = useUsuarioLogado()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const usrTemPermissaoGestor = usrLogado?.permissoes.some(p => p.permissao.startsWith('gestor'));

    return (<>
        <AppBar
            position="relative"
            color='secondary'
            sx={{
                borderTop: '2px solid #71d208',
                borderBottom: '2px solid #615e5c',
                '& .MuiToolbar-root': {
                    paddingLeft: '0px',
                },
            }}
        >
            <Toolbar sx={{
                height: "48px"
            }}>
                <div style={{ height: '100%', position: 'relative' }}>
                    <img
                        height="100%"
                        src="/logo-sistema.png"
                        alt="Banner SEJUSP" />
                    {usrLogado && <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ color: 'inherit', position: 'absolute', right: 0, top: 0 }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <AppsIcon />
                    </IconButton>}
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <List sx={{
                            backgroundColor: grey[900],
                        }}>
                            <ListItemButton
                                component={Link}
                                to="/compras"
                                style={{ color: 'lightgrey' }}
                                onClick={() => setAnchorEl(null)}
                            >
                                Home
                            </ListItemButton>
                            <ListItemButton
                                component={Link}
                                to="/compras/processos"
                                style={{ color: 'lightgrey' }}
                                onClick={() => setAnchorEl(null)}
                            >
                                Processos
                            </ListItemButton>
                            {usrTemPermissaoGestor &&
                                <ListItemButton
                                    component={Link}
                                    to="/compras/bi"
                                    style={{ color: 'lightgrey' }}
                                    onClick={() => setAnchorEl(null)}
                                >
                                    Business Intelligence
                                </ListItemButton>}
                            {
                                usrLogado?.permissoes.find(p => p.permissao === 'equipe-planejamento') &&
                                <ListItemButton
                                    component={Link}
                                    to="/compras/criar-processo"
                                    style={{ color: 'lightgrey' }}
                                    onClick={() => setAnchorEl(null)}
                                >
                                    Novo Processo
                                </ListItemButton>
                            }
                            <ListItemButton
                                component={Link}
                                to="/compras/legislacao"
                                style={{ color: 'lightgrey' }}
                                onClick={() => setAnchorEl(null)}
                            >
                                Legislação
                            </ListItemButton>
                            <ListItemButton
                                component={Link}
                                to="/compras/tutoriais#Visão Geral.mp4"
                                style={{ color: 'lightgrey' }}
                                onClick={() => setAnchorEl(null)}
                            >
                                Vídeos tutoriais
                            </ListItemButton>
                            <ListItemButton
                                component="a"
                                href="https://sistemas.bombeiros.ms.gov.br/suporte/solicitacao/solicitacao-criar/263/131/true/sejusp"
                                target="_blank"
                                style={{ color: 'lightgrey' }}
                                onClick={() => setAnchorEl(null)}
                            >
                                Suporte
                            </ListItemButton>
                        </List>
                    </Popover>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Box ml={1} flexDirection="column">
                        <Box><span style={{ color: 'red' }}>PROC</span>EDA - Planejamento de Compras</Box>
                        <Box sx={{ fontSize: '10px', color: 'lightgreen' }}>Processo Eletrônico de Análise de Documentos</Box>
                    </Box>
                </Box>
                <ShowUsr usr={usrLogado} />
            </Toolbar>
        </AppBar >
    </>)
}

export function AppFooter() {
    return (<>
        <AppBar
            sx={{
                top: 'auto',
                bottom: 0,
                justifyContent: 'end',
                position: 'relative',
                marginTop: 1
            }}
            color='secondary'
        >
            <Container maxWidth="md">
                <Box>
                    <Box style={{ textAlign: "right" }}>
                        <span style={{ color: "gray" }}>Desenvolvido pela</span> Diretoria de Telemática e Estatística
                    </Box>
                    <Box style={{ textAlign: "right", fontWeight: "bold" }}>
                        Corpo de Bombeiros de Mato Grosso do Sul
                    </Box>
                </Box>
            </Container>
        </AppBar >
    </>)
}
