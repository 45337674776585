import { Container, Paper } from "@mui/material"
import { Titulo } from "../components/componentes"

export function TelaMaterialDeApoio() {
    return <>
        <Titulo titulo="Material de Apoio" />
        <Container>
            <Paper>
                <p>Materiais de apoio</p>
            </Paper>
        </Container>
    </>
}
