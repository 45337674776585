import { Add, Check, Delete, Edit, ExpandMore, PriorityHigh, Save } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, MenuItem, Paper, PaperProps, Select, TextField, Typography } from "@mui/material"
import { useState } from "react"
import Draggable from "react-draggable"
import { alertaCallback, exibeAlerta } from "../../components/Alerta"
import { AvatarUsrId } from "../../components/AvatarUsr"
import { CaixaDeTextoSimples } from "../../components/CaixaDeTextoSimples"
import { Titulo } from "../../components/componentes"
import { ApiAnalise, ItemNotificacaoAnalise } from "../../processo/analise"
import { Projeto } from "../../processo/processo"
import { useUsuarioLogado, UsuarioLogado } from "../../usuario-logado"
import { useWindowSize } from "usehooks-ts"

export function Notificacao(props: {
    items: ItemNotificacaoAnalise[],
    emEdicao: boolean,
    analistas: number[],
    usr: UsuarioLogado,
    projeto: Projeto,
    local: string,
    especificacao_local: string,
    set_especificacao_local: (l: string) => void,
    espec_locais?: string[],
    atualiza_items_de_analise: () => Promise<void>
}) {
    const {
        emEdicao,
        analistas,
        usr,
        projeto,
        local,
        especificacao_local,
        set_especificacao_local,
        espec_locais,
        atualiza_items_de_analise,
    } = props;
    const { height } = useWindowSize();

    const items = props.items

    return <>
        <Accordion>
            <Titulo titulo={"Notificação"} itemApos={analistas.includes(usr?.id) ? <AdicionarNovoItemDeNotificacao
                projeto={projeto.id}
                local={local}
                especificacao_local={especificacao_local}
                set_especificacao_local={set_especificacao_local}
                espec_locais={espec_locais}
                atualiza_items_de_analise={atualiza_items_de_analise}
            /> : undefined} />
        </Accordion>
        <div style={{ overflowY: 'scroll', maxHeight: height - 58 }}>
            {items.length === 0 && <Accordion>
                <AccordionSummary>
                    <Typography>
                        Nenhum item de notificação inserido.
                    </Typography>
                </AccordionSummary>
            </Accordion>}
            {items.map(item => <ExibeItem
                key={item.id}
                item={item}
                especificacao_local={especificacao_local}
                set_especificacao_local={set_especificacao_local}
                emEdicao={emEdicao}
                analistas={analistas}
                update_item={atualiza_items_de_analise}
                usr={usr}
            />)}
        </div>
    </>
}

function ExibeItem(props: { item: ItemNotificacaoAnalise, emEdicao: boolean, analistas: number[], usr: UsuarioLogado, update_item: () => Promise<void>, especificacao_local: string, set_especificacao_local: (l: string) => void }) {
    const { item, emEdicao, analistas, usr, update_item, especificacao_local, set_especificacao_local } = props
    const [aberto, set_aberto] = useState(false)
    const respondido = item.resposta && item.resposta.length > 0
    const [respondendo, set_respondendo] = useState(false)

    const [titulo, set_titulo] = useState<string>()
    const [conteudo, set_conteudo] = useState<string>()
    const [resposta, set_resposta] = useState(item.resposta || '')

    const respondido_por = usr.id

    function excluir_item() {
        if (window.confirm(`Tem certeza que deseja excluir o item de notificação "${item.titulo}"?`)) {
            ApiAnalise.excluir(item.id).then(update_item)
        }
    }

    return <Accordion expanded={aberto} onChange={() => {
        set_aberto(!aberto)
        if (!aberto) {
            // Quando abrir, navega para o item referenciado na exibição
            set_especificacao_local(item.especificacao_local)
        }
    }
    }>
        <AccordionSummary
            sx={{ backgroundColor: (especificacao_local !== item.especificacao_local) ? 'whitesmoke' : null }}
            expandIcon={<ExpandMore />}
        >
            <Box>
                <AvatarUsrId usr={item.autor} />
            </Box>
            <Typography sx={{ flexGrow: 1, ml: 1 }}>
                {titulo || item.titulo}
            </Typography>
            <Box sx={{ fontSize: 9, verticalAlign: "middle", display: "contents", color: (especificacao_local === item.especificacao_local) ? 'red' : 'black' }}>
                {item.local.toUpperCase()}/{item.especificacao_local}
            </Box>
            {respondido && <Check color="success" />}
            {!respondido && <PriorityHigh color="warning" />}
            {!emEdicao && analistas.includes(usr.id) &&
                <IconButton
                    onClick={() => excluir_item()}>
                    <Delete color="primary" />
                </IconButton>
            }
        </AccordionSummary>
        <AccordionDetails>
            {/******************************
             * Conteúdo
            *******************************/}
            {conteudo === undefined &&
                <Typography sx={{ color: 'text.secondary' }}>
                    <Box dangerouslySetInnerHTML={{ __html: item.conteudo }} />
                    {analistas.includes(usr?.id) && <IconButton
                        color="primary" size="small"
                        onClick={() => {
                            set_conteudo(item.conteudo || '')
                            set_titulo(item.titulo || '')
                        }}
                    >
                        <Edit />
                    </IconButton>}
                </Typography>
            } {conteudo !== undefined &&
                <>
                    <TextField
                        sx={{ marginTop: 1 }}
                        label="Título"
                        value={titulo}
                        onChange={ev => set_titulo(ev.target.value)}
                        fullWidth
                    />
                    <CaixaDeTextoSimples
                        conteudo={item.conteudo}
                        set_conteudo={set_conteudo}
                    />
                    <IconButton
                        color="primary"
                        onClick={() => ApiAnalise
                            .salvar({ ...item, titulo: titulo || item.titulo, conteudo })
                            .then(update_item)
                            .then(() => set_conteudo(undefined))
                            .then(() => set_titulo(undefined))
                            .catch(alertaCallback("Falha ao salvar o item da notificação. Favor tentar novamente mais tarde.", { severity: "error" }))}
                    >
                        <Save />
                    </IconButton>
                </>
            }

            {/******************************
             * Resposta
            *******************************/}
            { // Só deve exibir se o item não acabou de ser adicionado
                (!!item.resposta || emEdicao) &&
                <>
                    <TextField
                        sx={{ marginTop: 1 }}
                        label="Resposta"
                        value={resposta}
                        onChange={ev => set_resposta(ev.target.value)}
                        multiline
                        rows={4}
                        fullWidth
                        disabled={!respondendo || !emEdicao}
                        InputProps={{
                            endAdornment: (!respondendo && emEdicao && (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="primary"
                                        onClick={() => set_respondendo(true)}
                                    >
                                        <Edit />
                                    </IconButton>
                                </InputAdornment>
                            )) || (
                                    respondendo &&
                                    <IconButton
                                        color="primary"
                                        onClick={() => ApiAnalise
                                            .salvar({ ...item, resposta, respondido_por })
                                            .then(update_item)
                                            .then(() => set_respondendo(false))
                                            .catch(alertaCallback("Falha ao salvar o item da notificação. Favor tentar novamente mais tarde.", { severity: "error" }))}
                                    >
                                        <Save />
                                    </IconButton>
                                ),
                        }}
                    />
                    {!emEdicao && analistas.includes(usr.id) &&
                        <Button
                            onClick={() => excluir_item()}
                            variant="outlined">
                            Aceitar correção (apagar item)
                        </Button>
                    }
                </>
            }
        </AccordionDetails>
    </Accordion>
}


function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function AdicionarNovoItemDeNotificacao(props: {
    projeto: number,
    local: string,
    especificacao_local: string,
    set_especificacao_local: (local: string) => void,
    espec_locais?: string[],
    atualiza_items_de_analise: () => void,
}) {
    const { projeto, local, especificacao_local, set_especificacao_local, espec_locais } = props
    const [modal_aberto, set_modal_aberto] = useState(false)
    const [titulo, set_titulo] = useState('')
    const [conteudo, set_conteudo] = useState('')
    const usr = useUsuarioLogado()

    if (!usr?.permissoes.find(p => p.permissao === 'analista-planejamento')) {
        return <></>
    }

    const autor = usr?.id || 0

    const fecha_modal = () => set_modal_aberto(false)

    return <>
        <IconButton
            color="primary"
            title="Adicionar Item de Análise"
            onClick={() => set_modal_aberto(true)}>
            <Add />
        </IconButton>
        <Dialog
            open={modal_aberto}
            onClose={fecha_modal}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle
                style={{ cursor: 'move' }} id="draggable-dialog-title"
            >
                Adicionar Novo Item</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <b>Local: </b> {local}
                    <FormControl disabled={!espec_locais} size="small">
                        <Select
                            value={especificacao_local}
                            onChange={ev => set_especificacao_local(ev.target.value)}
                        >
                            {(espec_locais || [especificacao_local]).map(espec_local => <MenuItem key={espec_local} value={espec_local}>{espec_local}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContentText>
                <TextField
                    label="Título"
                    value={titulo}
                    onChange={ev => set_titulo(ev.target.value)}
                    autoFocus
                    margin="normal"
                    fullWidth
                />
                <CaixaDeTextoSimples
                    conteudo={conteudo}
                    set_conteudo={set_conteudo}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={fecha_modal}>Cancel</Button>
                <Button variant="outlined" disabled={!especificacao_local} onClick={() => {
                    if (!!especificacao_local)
                        ApiAnalise
                            .novo(projeto, local, especificacao_local, titulo, conteudo, autor)
                            .then(() => exibeAlerta("Item adicionado com sucesso"))
                            .then(props.atualiza_items_de_analise)
                            .then(() => {
                                set_titulo('')
                                set_conteudo('')
                            })
                            .then(fecha_modal)
                            .catch(() => exibeAlerta("Falha ao adicionar item", { severity: "error" }))
                }}>Adicionar Item</Button>
            </DialogActions>
        </Dialog>
    </>
}