import { Container, List, ListItemButton, ListItemText, Paper, SvgIcon } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import { Titulo } from "../components/componentes"

interface EntidadeLegislacao {
    nome: string
    descricao_curta?: string
    preambulo: string
    link: string
    tipo_arquivo?: string
}

module ApiLegislacao {
    export async function get(): Promise<EntidadeLegislacao[]> {
        return axios.get<EntidadeLegislacao[]>(`/api/legislacao`).then(r => r.data)
    }
}

const ICONE_PDF = <svg version="1.1" viewBox="0 0 75.32 92.604" xmlns="http://www.w3.org/2000/svg">
    <title>PDF file icon</title>
    <g transform="translate(36.076 -93.732)">
        <g transform="matrix(1.4843 0 0 1.4843 17.472 -90.244)">
            <g>
                <path d="m-29.633 123.95c-3.552 0-6.4434 2.8935-6.4434 6.4453v49.498c0 3.5518 2.8914 6.4453 6.4434 6.4453h37.85c3.552 0 6.4434-2.8934 6.4434-6.4453v-40.701s.10135-1.1918-.41602-2.3516c-.48497-1.0871-1.2754-1.8438-1.2754-1.8438a1.0584 1.0584 0 00-.0059-.008l-9.3906-9.2109a1.0584 1.0584 0 00-.015625-.0156s-.80174-.76344-1.9902-1.2734c-1.3994-.6005-2.8418-.53711-2.8418-.53711l.021484-.002z" color="#000000" color-rendering="auto" dominant-baseline="auto" fill="#ff2116" image-rendering="auto" shape-rendering="auto" solid-color="#000000" />
                <path d="m-29.633 126.06h28.379a1.0584 1.0584 0 00.021484 0s1.1348.011 1.9648.36719c.7989.34282 1.3654.86176 1.3691.86524 1.25e-5 1e-5.00391.004.00391.004l9.3672 9.1894s.56435.59582.83789 1.209c.22078.49491.23438 1.4004.23438 1.4004a1.0584 1.0584 0 00-.002.0449v40.746c0 2.4159-1.9103 4.3281-4.3262 4.3281h-37.85c-2.4159 0-4.3262-1.9121-4.3262-4.3281v-49.498c0-2.416 1.9103-4.3281 4.3262-4.3281z" color="#000000" color-rendering="auto" dominant-baseline="auto" fill="#f5f5f5" image-rendering="auto" shape-rendering="auto" solid-color="#000000" />
                <path d="m-23.408 161.09c-1.4567-1.4567.11934-3.4584 4.3965-5.584l2.6912-1.3374 1.0484-2.294c.57665-1.2617 1.4373-3.3204 1.9125-4.5748l.8641-2.2808-.59546-1.6879c-.73217-2.0755-.99326-5.1944-.52872-6.3159.62923-1.5191 2.6903-1.3632 3.5063.26515.63727 1.2718.57212 3.5749-.18329 6.4795l-.6193 2.3812.5455.92604c.30003.50932 1.1764 1.7187 1.9475 2.6874l1.4492 1.8027 1.8034-.23533c5.729-.74758 7.6912.523 7.6912 2.3448 0 2.2992-4.4985 2.489-8.2761-.16423-.84997-.59698-1.4337-1.19-1.4337-1.19s-2.3665.48178-3.5317.79583c-1.2027.32417-1.8027.52719-3.5645 1.1219 0 0-.61814.89767-1.0209 1.5503-1.4986 2.4279-3.2483 4.44-4.4979 5.1723-1.3991.81993-2.8658.87582-3.6043.13733zm2.286-.81668c.81883-.50607 2.4762-2.4662 3.6234-4.2855l.46449-.73658-2.115 1.0634c-3.2666 1.6424-4.7609 3.1903-3.9839 4.1266.43653.52598.95874.48237 2.0109-.16792zm21.218-5.9558c.80089-.56097.68463-1.6914-.22082-2.1472-.70466-.35471-1.2726-.42759-3.1032-.40057-1.1249.0767-2.9338.3034-3.2403.37237 0 0 .99372.68678 1.4349.93922.58731.33544 2.0145.95811 3.0565 1.2771 1.0278.31461 1.6224.28144 2.0729-.0409zm-8.5315-3.5459c-.4847-.50952-1.3089-1.573-1.8315-2.3632-.68353-.89643-1.0263-1.5289-1.0263-1.5289s-.4996 1.6069-.90948 2.5739l-1.2788 3.1608-.37075.71695s1.971-.64627 2.9739-.90822c1.0622-.27744 3.2179-.70134 3.2179-.70134zm-2.7494-11.026c.12363-1.0375.1761-2.0735-.15724-2.5959-.9246-1.0108-2.0406-.16787-1.8515 2.2352.0636.8084.26443 2.1903.53292 3.0421l.48817 1.5486.34358-1.1664c.18897-.64151.47882-2.0202.64411-3.0636z" fill="#ff2116" stroke-width=".26458" />
                <g fill="#2c2c2c">
                    <path d="m-20.93 167.84h2.365q1.1335 0 1.8402.2169.7067.20991 1.1895.9446.4828.72769.4828 1.7562 0 .94459-.39183 1.6233-.39183.67871-1.0565.97958-.65772.30087-2.0291.30087h-.81865v3.7294h-1.5813zm1.5813 1.2245v3.3306h.78366q1.0496 0 1.4484-.39184.40583-.39183.40583-1.2734 0-.65772-.26589-1.0636-.26588-.41282-.58775-.50378-.31487-.098-1.0006-.098z" />
                    <path d="m-13.842 167.84h2.1481q1.5603 0 2.4909.55276.9376.55276 1.4134 1.6443.48279 1.0915.48279 2.421 0 1.3994-.43382 2.4979-.42681 1.0915-1.3154 1.7632-.88162.67172-2.5189.67172h-2.267zm1.5813 1.2664v7.018h.65772q1.3784 0 2.0011-.9516.62273-.95858.62273-2.5539 0-3.5125-2.6239-3.5125z" />
                    <path d="m-5.7889 167.84h5.3037v1.2664h-3.7224v2.8548h2.9807v1.2665h-2.9807v4.1632h-1.5813z" />
                </g>
            </g>
        </g>
    </g>
</svg>

function ExibeLegislacao(props: { item_legislacao: EntidadeLegislacao }) {
    const { nome,
        descricao_curta,
        preambulo,
        link,
        tipo_arquivo,
    } = props.item_legislacao
    const primary = [nome, !!descricao_curta && <span style={{ fontSize: 10 }}>&nbsp;({descricao_curta})</span>, tipo_arquivo === 'PDF' && <SvgIcon>{ICONE_PDF}</SvgIcon>]
    return <ListItemButton component={(props) => <a target="_blank" href={link} {...props} />}>
        <ListItemText primary={primary} secondary={preambulo} />
    </ListItemButton>
}

export function TelaLegislacao() {
    const [items, set_items] = useState<EntidadeLegislacao[]>([])
    useEffect(() => {
        ApiLegislacao.get().then(set_items)
    }, [])

    return <>
        <Titulo titulo="Legislação" comBotaoVoltar />
        <Container>
            <Paper>
                <List>
                    {items.map(item => <ExibeLegislacao item_legislacao={item} />)}
                </List>
            </Paper>
        </Container>
    </>
}
