import AddIcon from '@mui/icons-material/Add';
import { Dialog, DialogTitle, IconButton, ListItem, ListItemText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ApiUsuario, Usuario } from "../entidades/usuario";
import { normalizar } from '../ferramentas';
import { AvatarUsr } from "./AvatarUsr";

export function AdicionaUsuarioButton(props: {
    callback: (usr: Usuario) => void,
    button?: JSX.Element,
    disabled: boolean,
    title?: string
}) {
    const [aberto, setAberto] = useState(false);
    const [usrs, setUsrs] = useState<Usuario[]>([]);
    const [filtro, setFiltro] = useState("");

    useEffect(() => { ApiUsuario.getAll().then(setUsrs) }, []);
    const { callback } = props;
    const button = React.cloneElement(
        props.button || <IconButton><AddIcon color="primary" /></IconButton>,
        { onClick: () => setAberto(true), title: props.title, disabled: props.disabled }
    )
    const handleSelecionaUsuario = (usr: Usuario) => {
        callback(usr);
        setAberto(false);
    }

    const filtro_normalizado = normalizar(filtro)
    const usrsFiltrados = usrs
        .filter(usr => 
            normalizar(usr.nome)
            .includes(filtro_normalizado)
        )
        .slice(0,10)

    return <>
        {button}
        <Dialog onClose={() => setAberto(false)} open={aberto}>
            <DialogTitle>Adicionar colaboradores</DialogTitle>
            <TextField
                value={filtro}
                size='small'
                label="Nome"
                onChange={(ev) => setFiltro(ev.target.value)}
                />
            {
                usrsFiltrados.map(usr => (
                    <ListItem button onClick={() => handleSelecionaUsuario(usr)} key={usr.id}>
                        <AvatarUsr usr={usr} />
                        <ListItemText primary={usr.nome} />
                    </ListItem>
                ))
            }
        </Dialog>
    </>
}
