import { differenceInCalendarDays, parseISO } from "date-fns";
import _ from "lodash";
import { CadastroGeral, Processo, Projeto } from "./processo";
import { Historico, HistoricoAnaliseAtribuidaParaUsuario, HistoricoEmElaboracao, HistoricoEnviadoParaAnalise, HistoricoProjetoNotificado } from "./processo.historico";

export function textoSituacaoHistorico(h: Historico): string {
    switch (h.tipo) {
        case 'inicio-processo':
            return 'Processo aberto';
        case 'elaboracao':
            return 'Em edição';
        case 'enviado-para-analise':
            return `Aguardando análise`;
        case 'analise-atribuida':
            return `Em análise`;
        case 'projeto-notificado':
            return `Projeto devolvido para adequações`;
        case 'projeto-aprovado':
            return `Projeto aprovado`;
    }
}

function valor_estimado(cadastro: CadastroGeral): number {
    const valor_str = (cadastro && cadastro.valorEstimado) || '0'
    const valor = (+(valor_str.replace('.', '').replace(',', ''))) / 100
    return valor
}

export class ProcessoHelper {
    constructor(public processo: Processo) { }

    public static comparaDataInicio(p1: Processo, p2: Processo) {
        const h1 = new ProcessoHelper(p1)
        const h2 = new ProcessoHelper(p2)

        const d1 = h1.dataInicio()
        const d2 = h2.dataInicio()

        return d1.localeCompare(d2)
    }
    public static comparaDataAtualizacao(p1: Processo, p2: Processo) {
        const d1 = ProcessoHelper.ultimoHistorico(p1).data
        const d2 = ProcessoHelper.ultimoHistorico(p2).data
        return d1.localeCompare(d2)
    }
    public static comparaValorEstimado(p1: Processo, p2: Processo) {
        const h1 = new ProcessoHelper(p1)
        const h2 = new ProcessoHelper(p2)

        const v1 = valor_estimado(h1.cadastro())
        const v2 = valor_estimado(h2.cadastro())

        return v2 - v1
    }

    rascunho(): boolean {
        return this.num_apresentacoes() === 0;
    }

    cadastro() {
        return this.ultimoProjeto()?.cadastro;
    }

    valorEstimado() {
        return valor_estimado(this.cadastro())
    }

    static ultimoHistorico(p: Processo) {
        const hs = p.historico;
        const len = hs.length;
        return hs[len - 1];
    }

    equipe_atual(): 'planejamento' | 'análise' | undefined {
        switch (this.ultimoHistorico().tipo) {
            case 'inicio-processo':
            case 'elaboracao':
            case 'projeto-notificado':
                return 'planejamento'
            case 'enviado-para-analise':
            case 'analise-atribuida':
                return 'análise'
            case 'projeto-aprovado':
                return undefined
        }
    }

    ultimoHistorico() {
        const hs = this.processo.historico;
        const len = hs.length;
        return hs[len - 1];
    }
    dataInicio() {
        return this.processo.historico[0]?.data;
    }
    dias_total() {
        return differenceInCalendarDays(new Date(), parseISO(this.processo.historico[0]?.data));
    }
    dataUltimaAtualizacao() {
        return this.ultimoHistorico()?.data;
    }
    diasUltimaAtualizacao() {
        const ultimaAtualizacao = this.dataUltimaAtualizacao();
        const data = parseISO(ultimaAtualizacao);
        return differenceInCalendarDays(new Date(), data)
    }

    diasEmAnalise() {
        const hs = this.processo.historico;
        let i = hs.length - 1
        while (i >= 0) {
            if (hs[i].tipo === 'enviado-para-analise') {
                const dataFoiParaAnalise = hs[i].data
                const data = parseISO(dataFoiParaAnalise);
                return differenceInCalendarDays(new Date(), data)
            }
            i--
        }
        return 0
    }

    diasEmElaboracao() {
        const hs = this.processo.historico;
        let i = hs.length - 1
        while (i >= 0) {
            if (hs[i].tipo === 'inicio-processo' || hs[i].tipo === 'projeto-notificado') {
                const dataInicioElaboracao = hs[i].data
                const data = parseISO(dataInicioElaboracao);
                return differenceInCalendarDays(new Date(), data)
            }
            i--
        }
        return 0
    }

    analistas() {
        return _.uniq(this.processo.historico.map(h => (h as HistoricoAnaliseAtribuidaParaUsuario).analista).filter(usr => usr !== undefined));
    }

    textoSituacao() {
        for (let h of this.processo.historico.slice().reverse()) {
            if (textoSituacaoHistorico(h)) {
                return textoSituacaoHistorico(h);
            }
        }
        return '';
    }

    estaEmEdicao(): boolean {
        const h = this.ultimoHistorico();
        switch (h.tipo) {
            case 'inicio-processo': /*     */ return false;
            case 'elaboracao': /*          */ return true;
            case 'enviado-para-analise': /**/ return false;
            case 'analise-atribuida': /*   */ return false;
            case 'projeto-notificado': /*  */ return true;
            case 'projeto-aprovado': /*    */ return false;
        }
    }
    estaAguardandoAnalise(): boolean {
        const h = this.ultimoHistorico();
        switch (h.tipo) {
            case 'inicio-processo': /*     */ return false;
            case 'elaboracao': /*          */ return false;
            case 'enviado-para-analise': /**/ return true;
            case 'analise-atribuida': /*   */ return true;
            case 'projeto-notificado': /*  */ return false;
            case 'projeto-aprovado': /*    */ return false;
        }
    }
    estaEmAnalise(): boolean {
        const h = this.ultimoHistorico();
        switch (h.tipo) {
            case 'inicio-processo': /*     */ return false;
            case 'elaboracao': /*          */ return false;
            case 'enviado-para-analise': /**/ return false;
            case 'analise-atribuida': /*   */ return true;
            case 'projeto-notificado': /*  */ return false;
            case 'projeto-aprovado': /*    */ return false;
        }
    }
    estaAprovado(): boolean {
        const h = this.ultimoHistorico();
        return h.tipo === 'projeto-aprovado'
    }

    /**
     * 
     * @returns Lista de ids dos usuários que estão como analistas no histórico do processo
     */
    analistas_atuais(): number[] {
        // Filtra apenas os históricos de análise atribuída
        const hs = this
            .processo
            .historico
            .filter((h): h is HistoricoAnaliseAtribuidaParaUsuario => h.tipo === 'analise-atribuida');
        // Retorna a lista de ids dos usuários
        return hs.map(h => h.analista);
    }

    projetos() {
        return this.processo.historico
            .filter((h): h is HistoricoEmElaboracao | HistoricoProjetoNotificado =>
                h.tipo === 'elaboracao' || h.tipo === 'projeto-notificado')
            .map(h => h.projeto)
    }

    num_apresentacoes() {
        return this.processo.historico
            .filter((h): h is HistoricoEnviadoParaAnalise => h.tipo === 'enviado-para-analise')
            .length
    }

    ultimoProjeto() {
        return this.projetos().pop() as Projeto;
    }

    tempoProcesso(): { analise: number, elaboracao: number } {
        const hist = this.processo.historico

        let elaboracao = 0
        let analise = 0

        if (hist && hist.length > 0) {
            let data_elaboracao = new Date(hist[0].data)
            let data_analise = new Date(hist[0].data)

            for (const h of hist) {
                const d = new Date(h.data)
                if (h.tipo === 'enviado-para-analise') {
                    const dist = d.getTime() - data_elaboracao.getTime()
                    elaboracao += dist
                    data_analise = d
                } else if (h.tipo === 'projeto-notificado') {
                    const dist = d.getTime() - data_analise.getTime()
                    analise += dist
                    data_elaboracao = d
                }
            }
            const ultimo_andamento = this.ultimoHistorico()
            const hoje = new Date()

            if (ultimo_andamento.tipo === 'elaboracao' || ultimo_andamento.tipo === 'projeto-notificado') {
                const dist = hoje.getTime() - data_elaboracao.getTime()
                elaboracao += dist
            } else if (ultimo_andamento.tipo === 'analise-atribuida' || ultimo_andamento.tipo === 'enviado-para-analise') {
                const dist = hoje.getTime() - data_analise.getTime()
                analise += dist
            }
        }

        elaboracao = Math.ceil(elaboracao / (1000 * 60 * 60 * 24))
        analise = Math.ceil(analise / (1000 * 60 * 60 * 24))

        return { analise, elaboracao }
    }
}
