import { ExpandLess, ExpandMore, QuestionMark } from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';
import { Badge, Box, Card, CardContent, CardHeader, Collapse, Container, Dialog, DialogTitle, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { Jodit } from "jodit";
import JoditEditor from "jodit-react";
import { IJodit } from "jodit/types/types";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import sanitize from "sanitize-html";
import { ItemNotificacaoAnalise } from "../../processo/analise";
import { ApiItemEtpTr, BaseItem } from "../../processo/atributo-documento";
import { Processo, Projeto } from "../../processo/processo";
import { ProcessoHelper } from "../../processo/processo.helper";
import { ApiProjeto } from "../../processo/projeto.api";
import { ExibeDocsComplementares } from "./ExibeDocumentos";

export function CaixaDeTexto(props: {
    projeto: number,
    tipo: 'etp' | 'tr',
    nome: string,
    motivoNaoPodeEditar?: string,
    textoOrientacao: string,
}) {
    const { projeto, tipo, nome, motivoNaoPodeEditar, textoOrientacao } = props
    const [conteudo, setConteudo] = ApiItemEtpTr.useItemEtpTr(projeto, tipo, nome)
    const [conteudo_anterior, set_conteudo_anterior] = useState<string>()
    const [conteudo_anterior_aberto, set_conteudo_anterior_aberto] = useState(false)

    const estaEmEdicao = !motivoNaoPodeEditar;

    const handleSave = (jodit: Jodit) => {
        if (estaEmEdicao) {
            const novo = jodit.value;
            setConteudo(novo)
        }
    }

    useEffect(() => {
        ApiProjeto
            .anterior(projeto)
            .then(p_anterior => {
                ApiItemEtpTr.get(p_anterior.id, tipo, nome).then(set_conteudo_anterior)
            })
    }, [projeto])

    const BUTTONS = [
        'bold',
        'italic',
        'eraser',
        '|',
        'table',
        'image',
        'link',
        '|',
        'undo',
        'redo',
        '|',
        {
            name: 'save',
            exec: handleSave,
            disabled: true
        }
    ] as IJodit['options']['buttons'];

    const config: IJodit['options'] = {
        height: 800,
        askBeforePasteFromWord: false,
        processPasteHTML: false,
        iframe: 'false',
        placeholder: textoOrientacao,
        askBeforePasteHTML: false,
        toolbarAdaptive: false,
        buttons: BUTTONS,
        uploader: {
            url: '/api/arquivos/upload',
            process: (resp: any) => ({
                ...resp,
                baseurl: 'http://localhost:4000/d0730/cbmms-compras/v1/swagger',
                path: '/api/arquivos/'
            }),
            defaultHandlerSuccess: function (data: any, resp: any) {
                (this as any).s.insertImage(data.baseurl + data.path + data.files[0])
            },
        },
        events: {
            processPaste: (e: any) => {
                const texto = e.clipboardData.getData('text/html')
                const allowedAttributes = sanitize.defaults.allowedAttributes
                allowedAttributes['td'] = ['colspan', 'rowspan']
                const texto_limpo = sanitize(texto, allowedAttributes)
                return texto_limpo
            }
        }
    }

    if (!estaEmEdicao) {
        return <>
            <LockIcon titleAccess={'O conteúdo travado. Motivo: ' + motivoNaoPodeEditar} />
            <Box p={1}>
                <div className="display-html" dangerouslySetInnerHTML={{ __html: conteudo || '' }} />
            </Box>
        </>
    } else if (conteudo != undefined) {
        return <>
            <Box>
                <Typography fontSize='small' color='red'><b>Atenção!</b> Após editar o texto aperte o botão de salvar para não perder o seu trabalho.</Typography>
                <JoditEditor
                    value={conteudo}
                    config={config}
                    //onBlur={handle_blur}
                />
            </Box>

            {conteudo_anterior && <>
                <Card sx={{ marginTop: 1 }} variant="outlined">
                    <CardHeader title="Conteúdo na última apresentação"
                        sx={{ backgroundColor: 'gainsboro' }}
                        action={<IconButton onClick={() => set_conteudo_anterior_aberto(!conteudo_anterior_aberto)}>
                            {!conteudo_anterior_aberto ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>} />
                    <Collapse in={conteudo_anterior_aberto} unmountOnExit>
                        <CardContent dangerouslySetInnerHTML={{ __html: conteudo_anterior }} />
                    </Collapse>
                </Card>
            </>
            }
        </>
    } else {
        return <Box>Carregando...</Box>
    }
}

export function BotaoOrientacoes(props: { orientacoes?: string, titulo: string }) {
    const { orientacoes, titulo } = props
    const [aberto, set_aberto] = useState(false)

    if (!orientacoes) return <></>

    return <>
        <IconButton size="small" color="primary" onClick={() => set_aberto(true)}>
            <QuestionMark />
        </IconButton>
        <Dialog onClose={() => set_aberto(false)} open={aberto}>
            <DialogTitle>{titulo}</DialogTitle>
            <Container dangerouslySetInnerHTML={{ __html: orientacoes }}>
            </Container>
        </Dialog>
    </>
}

export function ExibeEdicaoETP(props: {
    processo: Processo,
    projeto: Projeto,
    motivoNaoPodeEditar?: string,
    especificacao_local: string,
    set_especificacao_local: (l: string) => void,
    items_analise: ItemNotificacaoAnalise[]
}) {
    const { projeto, motivoNaoPodeEditar, especificacao_local, set_especificacao_local, items_analise } = props
    const [bases_etp, set_bases_etp] = useState<Record<string, BaseItem>>({})
    const helper = new ProcessoHelper(props.processo)
    const num_projeto_do_processo: number = helper.projetos().findIndex(p => p.id === projeto.id) || 0

    const abas = Object.keys(projeto.etp).sort((a, b) => {
        const base_a = bases_etp[a]?.ordem || ''
        const base_b = bases_etp[b]?.ordem || 'z'
        return base_a.localeCompare(base_b)
    })

    useEffect(() => {
        if (!especificacao_local || !abas.includes(especificacao_local)) {
            set_especificacao_local(abas[0])
        }
    }, [])

    const busca_bases = () => {
        // Existe um teste idêntico no backend no arquivo projeto.api.ts
        const documento = projeto.cadastro.baseAnalise === 'Lei 14.133/2021' ? 'ETP LEI 14133' : 'ETP'
        ApiItemEtpTr
            .items_base()
            .then(bases => {
                const mapa_bases = {} as Record<string, BaseItem>
                for (let base of bases.filter(b => b.documento === documento)) {
                    mapa_bases[base.titulo] = base
                }
                set_bases_etp(mapa_bases)
            })
    }
    useEffect(busca_bases, [])

    const items_agrupados = groupBy(items_analise, 'especificacao_local')
    function nao_respondido(item: ItemNotificacaoAnalise): boolean {
        return !item.resposta
    }

    function items_notificacao(atributo: string) {
        return items_agrupados?.[atributo] || []
    }

    // O item deve ser editável se estiver em edição e se houver items referentes a ele na análise
    function motivoNaoEditavel(atributo: string): string | undefined {
        if (!!motivoNaoPodeEditar) return motivoNaoPodeEditar;
        // Na elaboração inicial o texto deve sempre ser editável
        console.log('num_projeto_do_processo', num_projeto_do_processo)
        if (num_projeto_do_processo === 0) return undefined;
        
        // // O texto só pode ser editado se houver items referentes a ele na análise
        // if (items_notificacao(atributo).length === 0) return 'O texto não pode ser editado porque não há itens referentes a ele na análise';

        return undefined
    }

    return (
        <Grid container>
            <Grid item xs={3}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={(abas.includes(especificacao_local) && especificacao_local) || abas[0]}
                    onChange={(_event: React.SyntheticEvent, newValue) => set_especificacao_local(newValue)}
                    sx={{ borderRight: 1, borderColor: 'divider', flexGrow: 1 }}
                >
                    {abas.map(atributo => <Tab className="abaEtpTr" key={atributo} value={atributo} label={
                        <Badge style={{ width: "100%" }} badgeContent={items_notificacao(atributo).filter(nao_respondido).length} color="warning">{bases_etp[atributo]?.ordem}. {atributo}</Badge>} />)}
                    <Tab className="abaEtpTr" key="docs-compl" value="docs-compl" label={
                        <Badge style={{ width: "100%" }} badgeContent={items_agrupados?.["docs-compl"]?.filter(nao_respondido).length} color="warning">{abas.length + 1}. Documentos Complementares</Badge>}
                    />
                </Tabs>
            </Grid>
            <Grid item padding={1} xs={9}>
                {abas.map(atributo =>
                    <div key={atributo} hidden={atributo !== especificacao_local}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                {atributo}
                            </Typography>
                            <BotaoOrientacoes orientacoes={bases_etp[atributo]?.ajuda} titulo={atributo} />
                        </Box>
                        <CaixaDeTexto
                            projeto={projeto.id}
                            tipo="etp"
                            nome={atributo}
                            motivoNaoPodeEditar={motivoNaoEditavel(atributo)}
                            textoOrientacao={bases_etp[atributo]?.ajuda || atributo} />

                    </div>)}
                <div key="docs-compl" hidden={"docs-compl" !== especificacao_local}>
                    <Typography variant="h6">Documentos Complementares</Typography>
                    <ExibeDocsComplementares
                        local="etp"
                        projeto={projeto}
                        items_analise={[] /* TODO */}
                    />
                </div>
            </Grid>
        </Grid>
    )
}
