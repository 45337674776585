import { Grid, Paper } from "@mui/material";
import { keys } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Titulo } from "../components/componentes";
import { ApiItemEtpTr } from "../processo/atributo-documento";
import { Projeto } from "../processo/processo";
import { ApiProjeto } from "../processo/projeto.api";

export function TelaAnalise() {
    const { idProjeto } = useParams<{ idProjeto?: string }>();
    const [projetoAtual, setProjetoAtual] = useState<Projeto>();
    const [projetoAnterior, setProjetoAnterior] = useState<Projeto>();

    useEffect(() => {
        if (!idProjeto) return
        ApiProjeto.get(+idProjeto).then(p => setProjetoAtual(p))
        ApiProjeto.anterior(+idProjeto).then(p => {
            if (p) {
                ApiProjeto.get(p.id).then(setProjetoAnterior)
            }
        })
    }, [idProjeto])

    if (!projetoAtual) return <>Carregando...</>

    const itemsETP = keys(projetoAtual.etp)

    return <>
        <Titulo titulo={'Análise ' + idProjeto} />
        <Grid container p={1} spacing={1}>
            <Grid item xs={4}>
                1ª Apresentação
                <Paper>
                    <ul>
                        {itemsETP.map(item => <li>{item}</li>)}
                    </ul>
                </Paper>
                <Paper>
                </Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper>Notificação</Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper>2ª Apresentação</Paper>
            </Grid>
            <Grid item xs={2}>
                <Paper>Notificação</Paper>
            </Grid>
        </Grid>
    </>
}