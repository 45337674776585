import { Person } from "@mui/icons-material";
import { Avatar, AvatarGroup, Badge } from "@mui/material";
import { useEffect, useState } from "react";
import { ApiUsuario, Usuario } from "../entidades/usuario";
import { useUsuarioLogado } from "../usuario-logado";

const URL_FOTOS = "https://sistemas.bombeiros.ms.gov.br/ws-alfresco/arquivo/";

export function AvatarUsr(ps: { usr: Usuario }) {
    const usrLogado = useUsuarioLogado()
    const usrTemFoto = !!ps.usr.foto && ps.usr.foto !== ''

    const avatarProps = {
        title: ps.usr.nome,
        alt: ps.usr.nome,
        sx: {
            borderColor: 'rgb(25, 118, 210)',
            border: ps.usr.id === usrLogado?.id ? '3px solid rgb(25, 118, 210) !important' : '2px solid white',
            '&:hover': {
                borderColor: 'rgb(25, 118, 210)'
            }
        }
    }

    if (usrTemFoto) {
        (avatarProps as any).src = URL_FOTOS + ps.usr.foto
    }

    const badgeContent = ps.usr.id === usrLogado?.id ? <Person sx={{ fontSize: 12 }} /> : undefined;

    const nomes = ps.usr.nome.split(' ')
    const primeiroNome = nomes[0];
    const ultimoNome = nomes[nomes.length-1];
    const iniciais = [primeiroNome[0], ultimoNome[0]].join('');

    return <Badge
        badgeContent={badgeContent}
        color="primary"
        overlap="circular"
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}>
        <Avatar {...avatarProps}>{iniciais}</Avatar>
    </Badge>
}

export function avatarUsr(usr: Usuario) {
    return <AvatarUsr key={usr.id} usr={usr} />
}

export function AvatarUsrId(props: { usr: number }) {
    const [usr, setUsr] = useState<Usuario>();
    useEffect(() => { ApiUsuario.get(props.usr).then(setUsr) }, [props.usr]);
    if (usr) {
        return <AvatarUsr usr={usr} />
    } else {
        return <Avatar />
    }
}

export function AvatarUsrsIds(props: {ids: number[]}) {
    const [usrs, set_usrs] = useState<Usuario[]>([]);
    useEffect(() => { ApiUsuario.getList(props.ids).then(set_usrs) }, [props.ids]);
    return <AvatarGroup title={usrs.map(usr => usr.nome).join('\u000A')} max={3}>
    {usrs.map(avatarUsr)}
</AvatarGroup>
}
