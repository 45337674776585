import axios from "axios";
import { useEffect, useState } from "react";
import { alertaCallback } from "../components/Alerta";
import { CadastroGeral } from "./processo";

export interface BaseItem {
    documento: string
    ordem: string
    titulo: string
    ajuda: string
}

export module ApiItemEtpTr {

    export async function items_base_tr_do_cadastro(cad: CadastroGeral): Promise<BaseItem[]> {
        const items = (await axios.get<BaseItem[]>(`/api/projeto/${cad.projeto}/items-base-tr`)).data

        return items
    }

    export interface ItemEtpTrDTO {
        projeto: number
        tipo: "etp" | "tr"
        nome: string
        conteudo: string
    }

    export async function get(
        projeto: number,
        tipo: "etp" | "tr",
        nome: string): Promise<string> {
        if (!projeto || !tipo || !nome) return ''
        return axios
            .get<string>(`/api/items-do-projeto/${projeto}/${tipo}/${encodeURIComponent(nome)}`)
            .then(r => r.data)
            .catch(err => "")
    }

    export async function salvar(
        projeto: number,
        tipo: "etp" | "tr",
        nome: string,
        conteudo: string,
    ) {
        return axios.post("/api/items-do-projeto/", {
            projeto,
            tipo,
            nome,
            conteudo,
        })
    }

    export function useItemEtpTr(
        projeto: number,
        tipo: "etp" | "tr",
        nome: string,
    ): [string | undefined, (novo: string) => void] {
        const [conteudo, setConteudo] = useState<string>("");
        useEffect(() => {
            get(projeto, tipo, nome)
                .then(setConteudo)
        }, [projeto, tipo, nome]);

        return [conteudo, (novo: string) => {
            salvar(projeto, tipo, nome, novo)
                .then(alertaCallback(`Texto ${tipo}/${nome} salvo com sucesso`))
                .then(() => setConteudo(novo))
                .catch(alertaCallback("Falha ao salvar o texto. Tente novamente mais tarde.", { severity: "error" }))
        }];
    }

    export async function items_base(): Promise<BaseItem[]> {
        return axios.get(`/api/items-do-projeto/items-base-tr/`).then(r => r.data)
    }

    export async function salvar_base_item(item: BaseItem) {
        return axios.post(`/api/items-do-projeto/salvar-item-base-tr/`, item).then(r => r.data)
    }
}
